import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
// import { useDispatch } from 'react-redux';
import callApi from "Utils/callApi";
import wait from "Utils/./wait";
import { useSnackbar } from "notistack";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import console from "react-console"
const useStyles = makeStyles(() => ({
  root: {},
}));

const AddNewDoctor = (className, user, ...rest) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [valid, setValid] = useState(true);
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <Container maxWidth = {false} style ={{padding:"20px" ,background:'white',margin:'0 auto', width:'100%', borderRadius:'10px'}}>
      <h1>Add a new Doctor</h1>
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          address: "",
          roleType: "doctor",
          isAdmin: false,
          balance:0,
          phone:"",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string("Enter Doctor Name").required(
            "Doctor Name is required"
          ),
          // phone: Yup.string("Enter Doctor Phone Number")
          //   .required("Phone Number is required")
          //   .test("test-name", "Enter Valid Phone Number", function (value) {
          //     const phoneRegex =
          //       /^([0-9]{11,11}(-[0-9]*){0,1}|([\w-]+(?:\.[\w-]+)*)+@\w+\.\w{2,3})$/;
          //     let isValidPhone = phoneRegex.test(value);
          //     if (!isValidPhone) {
          //       return false;
          //     }
          //     return true;
          //   }),
          email: Yup.string("Enter Doctor Email")
            
            ,
          address: Yup.string("Enter address").required("Address is required"),
          // password: Yup.string().max(255).required("Password is required"),
         
          password: Yup.string()
            .required("Doctor Password is required"),
          confirmPassword: Yup.string()
            .required("Confirm Doctor Password is required")
            .oneOf(
              [Yup.ref("password"), null],
              "Confirm Doctor Password must match with password"
            )
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            resetForm();
            await callApi
              .post("/registration", values)
              .then((response) => {
                console.log(response);
              }); 
            await wait(500);
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar("Doctor Added Successfully", {
              variant: "success",
              // action: <Button>See all</Button>
            });
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}

       
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Box xs={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    autoFocus
                    margin="normal"
                    label="Doctor Name"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                    size="small"
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
               
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  {/*<TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    autoFocus
                    helperText={touched.phone && errors.phone}
                    label=" Phone Number"
                    margin="normal"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="text"
                    value={values.phone}
                    variant="outlined"
                    size="small"

                  />*/}
                              <label>
          <PhoneInput
            country={"in"}
            // value={phoneNumber}
              onChange={(value) => {
                values.phone = value;
                console.log("phone",values);
            }}
            name="phone"
            value={values.phone}
            fullWidth
                enableSearch={true}
                inputStyle={{ width: '100%' }}
            inputProps={{
              required: true,
            }}
          />
        </label>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    autoFocus
                    helperText={touched.email && errors.email}
                    label=" Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                    size="small"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    label="Confirm Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.confirmPassword}
                    variant="outlined"
                    size="small"
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    autoFocus
                    margin="normal"
                    label="Address"
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    variant="outlined"
                    size="small"
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
              </Grid>
            </Box>
            <Box flex={1} justifyContent="center" alignItems="center">
              <Grid xs={5}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create Doctor
                </Button>
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Container>
    </DashboardLayout>
  );
};
AddNewDoctor.propTypes = {
  className: PropTypes.string,
  // user: PropTypes.object.isRequired
};
export default AddNewDoctor;
