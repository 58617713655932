
/* eslint-disable max-len */
import React, { useState } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Box,
  TextField,
  makeStyles,
  Grid,
} from "@material-ui/core";
// import getInitials from 'src/utils/getInitials';
// import getFullName from 'src/utils/getFullName';
import { Autocomplete } from "@material-ui/lab";
// import callApi from 'src/utils/callApi';
// import { useSelector } from 'react-redux';
// import useIsMountedRef from 'src/hooks/useIsMountedRef';
const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}));

const ApprovedXrayReportList = () => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [clinic, setClinic] = useState(null)
    const classes = useStyles();
    const startingDateToTimeStamp = (queryDate) => {
      var tempDate = queryDate.split("/");
      var convertedDate = new Date(tempDate).getTime();
      setStartDate(convertedDate);
      
  };
  const endingDateToTimeStamp = (queryDate) => {
    var tempDate = queryDate.split("/");
    var convertedDate = new Date(tempDate).getTime();
    setEndDate(convertedDate);
  };
    const clinics = [
      {
        accountExpiresOnDate: 1668307091074,
        balance: 0,
        name: "Sheba",
        organizationId: "616d4d688245f246cc7ff3f4",
      },
  ];
  const searchQuery = {
    clinic: clinic,
    startingDate: startDate,
    endDate
  };
  console.log(searchQuery)
 
    return (
      <DashboardLayout>
      <DashboardNavbar />
        <Box p={2} minHeight={56} display="flex" alignItems="center">
        <Autocomplete
          id="tags-standard"
          options={clinics}
          getOptionLabel={(option) => option.name}
          sx={{ width: 900 }}
          onChange={(_event, value) => {
            setClinic(value);

          }}
          // onInputChange={(e)=>console.log(e.target.value)}
          renderInput={(params) => (
            <TextField
              className={classes.queryField}
              {...params}
              label="Search Clinic"
              placeholder="Search Clinic"
              variant="outlined"
            />
          )}
        />
        <Grid container spacing={2}></Grid>
        <Box display="flex" alignItems="center">
          <TextField
            id="date"
            margin="normal"
            label="Start Date"
            type="date"
            defaultValue="2021-01-01"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e) => startingDateToTimeStamp(e.target.value)}
          />
          <Box sx={{ mx: 4 }}>
            <Grid margin="normal"></Grid>
          </Box>
          <TextField
            id="date"
            margin="normal"
            label="End Date"
            type="date"
            defaultValue="2021-01-01"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e) => endingDateToTimeStamp(e.target.value)}
          />
        </Box>
      </Box>
      </DashboardLayout>
    );
};

ApprovedXrayReportList.propTypes = {
    className: PropTypes.string,
  
  };
export default ApprovedXrayReportList;