/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Container,
  Divider,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
// react-router-dom components
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { useSnackbar } from "notistack";
import { useCookies } from "react-cookie";
import Autocomplete from "@mui/material/Autocomplete";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/xraybg.jpg";
import * as Yup from "yup";
import callApi from "Utils/callApi";
import React, { useEffect } from "react";

function Organization({ className, onSubmitSuccess, ...rest }) {
  const [rememberMe, setRememberMe] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [cookies, setCookie] = useCookies({});
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [assistantDoctors, setDoctorAssistant] = useState([]);
  useEffect(() => {
    callApi.get("/doctor/getData").then((response) => {
      const patientsData = response.data.users;
      const doctorsData = response.data.doctors;
      const assistantDoctorsData = response.data.doctorAssistant;
      console.log(patientsData);
      setPatients(patientsData);
      setDoctors(doctorsData);
      setDoctorAssistant(assistantDoctorsData);
    });
  }, []);
  const joinorganization = () =>{

  }
  const createorganization = () =>{
    history.push('/authentication/create-organization')
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{
              organization: "",
            }}
            validationSchema={Yup.object().shape({
              organization: Yup.string().required("Organization is required"),
            })}
            onSubmit={async (
              values,
              setFieldValue,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if (!values.emailOrPhone || !values.password) {
                  return enqueueSnackbar("Invalid email or password", {
                    variant: "error",
                  });
                }
                callApi
                  .post("/login", values)
                  .then((res) => {
                    enqueueSnackbar("Login Success", {
                      variant: "success",
                    });
                    delete res.data.user.password;
                    console.log(res.data);
                    if (res.data.success) {
                      console.log("user", res.data);
                      localStorage.setItem(
                        "loggedUser",
                        JSON.stringify(res.data.user)
                      );
                      setCookie("loggedUser", res.data.user, {});
                      history.push("/");
                    }
                  })
                  .catch((error) => {
                    return enqueueSnackbar("Invalid Credential", {
                      variant: "error",
                    });
                  });
              } catch (error) {
                localStorage.clear();
                const message = error.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              initialValues,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...rest}>
                <Autocomplete
                  size="small"
                  style={{ color: "white" }}
                  disablePortal
                  id="doctor"
                  color="white"
                  name="repDoctor.name"
                  options={doctors}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  // sx={{ width: 300 }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option._id}>
                        {option.name}
                      </li>
                    );
                  }}
                  onChange={(e, value) => {
                    setFieldValue(
                      "organization",
                      value !== null ? value : initialValues.organization
                    );
                    console.log(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ color: "white" }}
                      label="Please select your organization from the list below."
                      variant="outlined"
                      placeholder="Search A Organization"
                      value={values?.organization.name}
                      onChange={handleChange}
                      error={Boolean(
                        touched.organization?.name && errors.organization?.name
                      )}
                      helperText={
                        touched.organization?.name && errors.organization?.name
                      }
                    />
                  )}
                />

                <MDBox mt={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                  >
                    Select
                  </MDButton>
                  {errors.submit && (
                    <MDBox mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </MDBox>
                  )}
                </MDBox>
              </form>
            )}
          </Formik>
          <MDBox mt={5}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              size="large"
              type="submit"
              onClick={joinorganization}
            >
              Join an organization
            </MDButton>

          </MDBox>
          <MDBox mt={2}>
          <MDButton 
              variant="gradient"
              color="info"
              fullWidth
              size="large"
              type="submit"
              onClick={createorganization}

            >
              Create an organization
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Organization;
