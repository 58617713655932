import { Container } from '@material-ui/core';
import React from 'react';
import ClinicListTable from 'layouts/Clinic/ManageClinic/ClinicListTable';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
const ManageClinic = () => {
    return (
        <DashboardLayout>
        <DashboardNavbar />
        <Container maxWidth = {false}>
            <ClinicListTable></ClinicListTable>
        </Container>
        </DashboardLayout>
    );
};

export default ManageClinic;