export const ReportTemplates = [
    {
        id:'rt1',
        label:'ABDOMEN ERECT',
        value:`<p style="text-align: center;"><br></p>
        <p style="text-align: center;">
            <font face="Times New Roman"><strong><u><span style="font-size: 14pt;">ABDOMEN ERECT</span></u></strong></font>
        </p>
        <p><br></p>
        <div class="simditor-table">
            <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                        <font size="2">Clinical History&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :<o:p></o:p>
                        </font>
                    </span></strong></p>
            <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                        <font size="2">Comparisons&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <o:p></o:p>
                        </font>
                    </span></strong></p>
            <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                        <font size="2">Observation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:<o:p></o:p>
                        </font>
                    </span></strong></p>
            <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in; margin-left:.25in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph; line-height:normal;mso-pagination:none;mso-layout-grid-align:none;text-autospace: none"><span style="font-family: Arial, sans-serif; color: black;">
                    <font size="2">No significant air-fluid levels are seen.<o:p></o:p>
                    </font>
                </span></p>
            <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in; margin-left:.25in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><span style="font-family: Arial, sans-serif; color: black;">
                    <font size="2">No free gas seen under both domes of diaphragm.<o:p></o:p>
                    </font>
                </span></p>
            <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in; margin-left:.25in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><span style="font-family: Arial, sans-serif; color: black;">
                    <font size="2">No radio-opaque calculus is seen.<o:p></o:p>
                    </font>
                </span></p>
            <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in; margin-left:.25in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><span style="font-family: Arial, sans-serif; color: black;">
                    <o:p>
                        <font size="2">&nbsp;</font>
                    </o:p>
                </span><br></p>
            <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in; margin-left:.25in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><span style="color: black; font-family: Arial, sans-serif; background-color: transparent;">
                    <font size="2">IMPRESSION:</font>
                </span></p>
            <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in; margin-left:.25in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none">
                <font size="2"><span style="color: black; font-family: Arial, sans-serif; background-color: transparent;">No abnormality detected.</span><span style="font-family: Arial, sans-serif; color: black;"><br></span></font>
            </p>
        </div>
        <p style="text-align: centre;"><br></p>`
    },
    {
        id:'rt2',
        label:'ANKLE AP & LATERAL',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">ANKLE AP & LATERAL</span></u></b></font></p><meta http-equiv="Content-Type" content="text/html; charset=utf8"><p></p><div class="pasteContent" style="display:inline;" _ngcontent-pvl-c32="">


        <meta http-equiv="content-type" content="text/html; charset=utf-8">
        <title></title>
        <meta name="generator" content="LibreOffice 6.4.2.2 (Linux)">
        
    
    <p lang="en-IN">
    </p></div><div class="pasteContent" style="display:inline;" _ngcontent-ios-c32="">
    
    <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:12.0pt;">FINDINGS :</span></u></b></p>
    
    <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
    color:black;">No demonstrable bony injury or dislocation.</span></p>
    
    <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
    color:black;">Joint space and articular margins are normal.</span></p>
    
    <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
    color:black;">No evidence of focal lesion.</span></p>
    
    <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
    color:black;">Distal end of tibia and fibula appear normal.</span></p>
    
    <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
    color:black;">Tarsal bones appear normal</span></p>
    
    <br>
    
    <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:12.0pt;color:black;">IMPRESSION<i>:</i></span></u></b></p>
    
    <p style="margin:0in;font-size:11.0pt;"><i><span style="font-size:12.0pt;
    color:black;"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></i></p>
    
    <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
    color:black;">NORMAL STUDY.</span></p>
    
    
    
    
    
    </div><p><br></p><p></p>`
    },
    {
        id:'rt3',
        label:'BARIUM ENEMA',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">BARIUM ENEMA</span></u></b></font></p><p></p><div class="pasteContent" style="display:inline;" _ngcontent-xko-c32="">


        <p style="margin:0in;font-size:10.0pt;"><b><u><span style="font-size:14.0pt;color:black;">TECHNIQUE:</span></u></b><span style="font-size:14.0pt;color:black;">Serial radiographs of the abdomen and pelvis obtained
        in multipleprojections after instillation of dilute barium followed by
        instillation of air.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin:0in;font-size:10.0pt;"><b><u><span style="font-size:14.0pt;
        color:black;">FINDINGS:</span></u></b></p>
        
        <p style="margin:0in;font-size:10.0pt;"><span style="font-size:14.0pt;">Rectum is normally
        outlined, normal in caliber and contour.</span></p>
        
        <p style="margin:0in;font-size:10.0pt;"><span style="font-size:14.0pt;">Descending colon,
        transverse colon and ascending colon are normally outlined, normal in caliber.</span></p>
        
        <p style="margin:0in;font-size:10.0pt;"><span style="font-size:14.0pt;">No abnormal intrinsic
        filling defect or extrinsic compression effect noted.</span></p>
        
        <p style="margin:0in;font-size:10.0pt;"><span style="font-size:14.0pt;">No abnormal stricture
        or dilatation noted.</span></p>
        
        <p style="margin:0in;font-size:10.0pt;"><span style="font-size:14.0pt;">Pre sacral space is normal.</span></p>
        
        <p style="margin:0in;font-size:10.0pt;"><span style="font-size:14.0pt;">Vermiform appendix is
        not visualised.</span></p>
        
        <br>
        
        <p style="margin:0in;font-size:10.0pt;"><b><u><span style="font-size:14.0pt;color:black;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        <ul level="1"><li><p><span style="font-size:14.0pt;
        color:black;">No significant abnormality detected in the barium enema study. </span></p></li></ul>
        
        <br>
        
        <p style="margin:0in;font-size:10.0pt;"><span style="font-size:14.0pt;">Please
        correlate clinically.</span></p>
        
        
        
        
        
        </div><p><br></p><p></p>`
    },
    {
        id:'rt4',
        label:'BOTH MASTOIDS',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">BOTH MASTOIDS</span></u></b></font></p><p><b></b></p><div class="pasteContent" style="display:inline;" _ngcontent-tie-c22="">

        </div><div class="pasteContent" style="display:inline;" _ngcontent-tie-c22="">
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">The
        mastoid air cells on either side are well pneumatized.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">The
        focal bone destruction is seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">The
        marginal cells and mastoid tip cells are also well pneumatized and they are
        normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Sinus
        and dural plates are well defined and appears normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">The
        periantral triangle is also normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">There
        is no evidence of cholesteatoma.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Visualized
        TM joints are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Pineal gland
        calcification is noted.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION<i>:</i></span></u></b></p>
        
        <br>
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        abnormality detected in both Mastoid study.</span></p></li></ul>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-tie-c22=""></div><div class="pasteContent" style="display:inline;" _ngcontent-tie-c22="">
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-tie-c22=""></div><p><br></p><p></p>`
    },
    {
        id:'rt5',
        label:'CERVICAL SPINE AP & LATERAL',
        value:`<p style="text-align: center;">
        <font face="Times New Roman"><strong><u><span style="font-size: 14pt;">CERVICAL SPINE AP & LATERAL</span></u></strong></font>
    </p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Clinical History&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Comparisons&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;: <o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Observation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;:<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;text-align:justify;text-justify: inter-ideograph;mso-layout-grid-align:none;text-autospace:none">
        <font size="2">Normal spinal curvature is maintained. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">Normal vertebral height is maintained. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">The inter-vertebral disc spaces appear maintained. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">Facetal and uncovertebral joints are normal.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">Visualized pedicles appear normal.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">No evidence of lytic/sclerotic lesion seen.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">No evidence of lysis/listhesis is seen.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">Pre or para-spinal soft tissue planes are maintained. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none"><strong><u>
                <font size="2">IMPRESSION:-<o:p></o:p>
                </font>
            </u></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0in;margin-bottom:12.0pt; margin-left:0in;text-align:justify;text-justify:inter-ideograph;line-height: normal;mso-pagination:none;mso-layout-grid-align:none;text-autospace:none"><strong><em><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                    <font size="2">Please correlate clinically.</font>
                    <o:p></o:p>
                </span></em></strong></p>`
    },
    {
        id:'rt6',
        label:'CHEST AP',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">CHEST AP</span></u></b></font></p><p></p><div class="pasteContent" style="display:inline;" _ngcontent-cuu-c32="">

        <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:14.0pt;">FINDINGS :</span></u></b></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:14.0pt;">Cardiac
        silhouette is normal in size.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:14.0pt;">Bilateral
        lung fields are grossly unremarkable.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:14.0pt;">Bilateral
        costophrenic angles and bilateral domes of the diaphragm are normal.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:14.0pt;">Bony
        cage &amp; soft tissues are grossly normal. </span></p>
        
        <br>
        
        <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:14.0pt;">IMPRESSION</span></u></b><span style="font-size:14.0pt;">:</span></p>
        
        <br>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:14.0pt;"><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span style="font-size:14.0pt;">NO
        PARENCHYMAL/PLEURAL PATHOLOGY SEEN.</span></p>
        
        
        
        
        
        </div><p><br></p><p></p>`
    },
    {
        id:'rt9',
        label:'DORSO LUMBER SPINE AP/LAT',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">DORSO LUMBER SPINE AP/LAT</span></u></b></font></p><p></p><div class="pasteContent" style="display:inline;" _ngcontent-uuv-c32="">

        <p style="margin:0in;font-size:12.0pt;"></p><div class="pasteContent" style="display:inline;" _ngcontent-xko-c32="">
        
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;"><span style="font-family: Arial, Helvetica, sans-serif;">Osteopenia of bones underview is seen. </span></span></p>
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;"><span style="font-family: Arial, Helvetica, sans-serif;">Spondylotic changes are seen in vertebral bodies of dorso-lumbar spine.</span></span></p>
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;"><span style="font-family: Arial, Helvetica, sans-serif;">Intervertebral disc spaces appear unremarkable.</span></span></p>
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;"><span style="font-family: Arial, Helvetica, sans-serif;">No evidence of erosion, wedging or abnormal sclerosis is seen in lumbar spine.</span></span></p>
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;"><span style="font-family: Arial, Helvetica, sans-serif;">No evidence of spondylolysis or spondylolisthesis is seen in lumbar spine. </span></span></p>
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;"><span style="font-family: Arial, Helvetica, sans-serif;">Inter-peduncular distance and AP diameter of spinal canal appear normal in lateral view.</span></span></p>
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;"><span style="font-family: Arial, Helvetica, sans-serif;">Vertebral appendage, psoas shadows appear normal.</span></span></p>
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;"><span style="font-family: Arial, Helvetica, sans-serif;">O.A. changes are seen in apophyseal joints of lower lumbar spine.</span></span></p>
        
        <br>
        
        <br>
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;"><span style="font-family: Arial, Helvetica, sans-serif;">Please correlate clinically.</span></span></p>
        
        <br>
        
        <br>
        
        <p style="margin:0in;font-size:12.0pt;"><span style="font-size:14.0pt;
        color:black;"><span style="font-family: Arial, Helvetica, sans-serif;">Thanks for your referral. </span></span></p>
        
        
        
        
        
        </div><br><p></p></div><p></p>`
    },
    {
        id:'rt10',
        label:'ELBOW AP/LAT',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">ELBOW AP/LAT</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-vem-c22="">


        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Bones under review are
        intact.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Normal mineralization is
        noted with smooth cortical outline.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">No acute fracture or
        dislocation is noted.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Joint spaces are
        maintained.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Articular margins are
        smooth. </span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">No soft tissue
        abnormality noted.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION<i>:</i></span></u></b></p>
        
        <br>
        
        
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No evidence of fracture.</span></p></li><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No significant bony abnormality is
        noted in Bilateral Elbow study.</span></p></li></ul>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-oru-c22=""><p style="margin: 0px 0in 12pt; color: rgb(51, 51, 51); font-style: normal; font-weight: 400; text-indent: 0px; white-space: normal; background-color: rgb(255, 255, 255); font-size: 11pt; font-family: Calibri, sans-serif; text-align: justify; line-height: normal;"><b style="font-weight: 700;"><span style="font-family: &quot;Times New Roman&quot;, serif;"></span></b></p><!--EndFragment-->
        
        </div><p></p>`
    },
    {
        id:'rt11',
        label:'FOOT AP & LATERAL',
        value:`<p style="text-align: center;">
        <font face="Times New Roman"><strong><u><span style="font-size: 14pt;">FOOT AP & LATERAL</span></u></strong></font>
    </p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Clinical History&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Comparisons&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;: <o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Observation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;:<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:200%;mso-layout-grid-align:none;text-autospace: none">
        <font size="2">Visualized bones of bilateral feet are normal in density. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:200%;mso-layout-grid-align:none;text-autospace: none">
        <font size="2">No lytic / sclerotic lesion seen. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:200%;mso-layout-grid-align:none;text-autospace: none">
        <font size="2">Joint spaces appear normal.</font>
    </p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:200%;mso-layout-grid-align:none;text-autospace: none"><span style="background-color: transparent;">
            <font size="2">No gross fracture is seen in the visualized bones.</font>
        </span></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:200%;mso-layout-grid-align:none;text-autospace: none">
        <o:p></o:p>
        <br>
    </p>`
    },
    {
        id:'rt12',
        label:'FOREARM AP/LA',
        value:`<p style="text-align: right;">﻿﻿<br></p><p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">FOREARM AP/LA</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-vem-c22="">


        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bony
        surfaces and articular margins of bones of hand are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        evidence of fracture, bone destruction or cystic areas is seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Visualized
        wrist joint and carpometacarpal joint space is normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bones
        shows normal architecture.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No soft
        tissue calcification is seen.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No evidence of fracture.</span></p></li><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No significant bony abnormality is
        noted in Both Hand Study.</span></p></li></ul>
        
        <br>
        
        <span style="font-size:12.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please
        correlate clinically.</span>
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-oru-c22=""><p style="margin: 0px 0in 12pt; color: rgb(51, 51, 51); font-style: normal; font-weight: 400; text-indent: 0px; white-space: normal; background-color: rgb(255, 255, 255); font-size: 11pt; font-family: Calibri, sans-serif; text-align: justify; line-height: normal;"><b style="font-weight: 700;"><span style="font-family: &quot;Times New Roman&quot;, serif;"></span></b></p><!--EndFragment-->
        
        </div><p></p>`
    },
    {
        id:'rt13',
        label:'HAND AP & OBLIQUE',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">HAND AP & OBLIQUE</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-vem-c22="">


        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FOREARM
        FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bony
        surfaces and articular margins of bones of hand are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        evidence of fracture, bone destruction or cystic areas is seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Visualized
        wrist joint and carpometacarpal joint space is normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bones
        shows normal architecture.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No soft
        tissue calcification is seen.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">ELBOW
        FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Bones under review are
        intact. Normal mineralization is noted with smooth cortical outline.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">No acute fracture or
        dislocation is noted.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Joint spaces are
        maintained.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Articular margins are
        smooth. </span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">No soft tissue
        abnormality noted.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">WRIST
        FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bony
        surfaces and articular margins of bones of hand are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        evidence of fracture, bone destruction or cystic areas is seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Visualized
        wrist joint and carpometacarpal joint space is normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bones
        shows normal architecture.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No soft
        tissue calcification is seen.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINGER
        FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No obvious bony
        lesion is noted.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No obvious
        fracture/dislocation is noted.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Joint spaces appears
        normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Alignment appears
        normal.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No evidence of fracture.</span></p></li><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No significant bony abnormality is
        noted in Both Hand Study.</span></p></li></ul>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-oru-c22=""><p style="margin: 0px 0in 12pt; color: rgb(51, 51, 51); font-style: normal; font-weight: 400; text-indent: 0px; white-space: normal; background-color: rgb(255, 255, 255); font-size: 11pt; font-family: Calibri, sans-serif; text-align: justify; line-height: normal;"><b style="font-weight: 700;"><span style="font-family: &quot;Times New Roman&quot;, serif;"></span></b></p><!--EndFragment-->
        
        </div><p></p>`
    },
    {
        id:'rt14',
        label:'HIP JOINT',
        value:`<p style="text-align: center;"><br></p><p></p><p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">HIP JOINT</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-vem-c22="">


        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINDINGS:</span></u></b></p>
        
        <p style="margin-right:0in;margin-left:0in;font-size:12.0pt;font-family:&quot;Times New Roman&quot;,&quot;serif&quot;;margin:0in;margin-bottom:.0001pt;"><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No evidence of any significant bony
        abnormality is detected in the visualised bones.</span></p>
        
        <p style="margin-right:0in;margin-left:0in;font-size:12.0pt;font-family:&quot;Times New Roman&quot;,&quot;serif&quot;;margin:0in;margin-bottom:.0001pt;"><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Visualised epiphyses appear normal.</span></p>
        
        <p style="margin-right:0in;margin-left:0in;font-size:12.0pt;font-family:&quot;Times New Roman&quot;,&quot;serif&quot;;margin:0in;margin-bottom:.0001pt;"><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Visualised joint spaces are within
        normal limits.</span></p>
        
        <p style="margin-right:0in;margin-left:0in;font-size:12.0pt;font-family:&quot;Times New Roman&quot;,&quot;serif&quot;;margin:0in;margin-bottom:.0001pt;"><span style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bone density
        is normal.</span></p>
        
        <p style="margin-right:0in;margin-left:0in;font-size:12.0pt;font-family:&quot;Times New Roman&quot;,&quot;serif&quot;;margin:0in;margin-bottom:.0001pt;"><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No evidence of any significant</span><span style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">&nbsp;focal
        lesion (lytic/sclerotic)&nbsp;&nbsp;is seen</span><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;"> in </span><span style="font-family:
        &quot;Arial&quot;,&quot;sans-serif&quot;;">the </span><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">visualised bones.</span></p>
        
        <p style="margin-right:0in;margin-left:0in;font-size:12.0pt;font-family:&quot;Times New Roman&quot;,&quot;serif&quot;;margin:0in;margin-bottom:.0001pt;"><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No evidence of abnormal soft tissue
        swelling or calcification seen.</span></p>
        
        <p style="margin-right:0in;margin-left:0in;font-size:12.0pt;font-family:&quot;Times New Roman&quot;,&quot;serif&quot;;margin:0in;margin-bottom:.0001pt;"><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">There is no evidence of&nbsp;</span><span style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">significant
        displaced&nbsp;</span><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">fracture
        or destruction.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><b><u><span lang="EN-IN" style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b><b><span lang="EN-IN" style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;"> </span></b></p>
        
        <br>
        
        <p style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,&quot;serif&quot;;margin-top:0in;margin-right:0in;margin-bottom:0in;
        margin-left:.5in;margin-bottom:.0001pt;text-indent:-.25in;"><span lang="EN-IN" style="font-family:Symbol;"><span>·<span style="font:7.0pt &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span lang="EN-IN" style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        significant abnormality is noted in both hip joints.</span></p>
        
        <br>
        
        <span style="font-size:12.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please
        correlate clinically.</span>
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-oru-c22=""><p style="margin: 0px 0in 12pt; color: rgb(51, 51, 51); font-style: normal; font-weight: 400; text-indent: 0px; white-space: normal; background-color: rgb(255, 255, 255); font-size: 11pt; font-family: Calibri, sans-serif; text-align: justify; line-height: normal;"><b style="font-weight: 700;"><span style="font-family: &quot;Times New Roman&quot;, serif;"></span></b></p><!--EndFragment-->
        
        </div><p></p>`
    },
    {
        id:'rt15',
        label:'HSG',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">HSG</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-vem-c22="">


        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINDINGS:</span></u></b></p>
        
        <br>
        
        <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%;">
         <tbody><tr>
         <td style="padding:2.25pt 2.25pt 2.25pt 2.25pt;">
         <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
         normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">UTERUS:</span></u></b></p>
         <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
         normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">It is
         normal in size and shape and shows smooth margin.</span></p>
         <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
         normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
         filling defects are noted in uterine cavity.</span></p>
         
         <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
         normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FALLOPIAN TUBES<i>:</i></span></u></b></p>
         <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
         normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">They
         are well seen on either side.</span></p>
         <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
         normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">They
         are normal in caliber, smooth in outline and do not show any irregularity.</span></p>
         <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
         normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">There
         is free peritoneal spill on either side.</span></p>
         </td>
         </tr>
        </tbody></table>
        
        <br>
        
        <br>
        
        <table border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100.0%;">
         <tbody><tr>
         <td style="padding:2.25pt 2.25pt 2.25pt 2.25pt;">
         <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
         justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b></p>
         
         
         <ul level="1" style="margin-bottom:0in;"><li><p><b><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Uterus and both fallopian tubes
         are normal.</span></b></p></li><li><p><b><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">There is bilateral free
         peritoneal spill.</span></b></p></li></ul>
         </td>
         </tr>
        </tbody></table>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-oru-c22=""><p style="margin: 0px 0in 12pt; color: rgb(51, 51, 51); font-style: normal; font-weight: 400; text-indent: 0px; white-space: normal; background-color: rgb(255, 255, 255); font-size: 11pt; font-family: Calibri, sans-serif; text-align: justify; line-height: normal;"><b style="font-weight: 700;"><span style="font-family: &quot;Times New Roman&quot;, serif;"></span></b></p><!--EndFragment-->
        
        </div><p></p>`
    },
    {
        id:'rt16',
        label:'HUMERUS AP/LAT',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">HUMERUS AP/LAT</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-vem-c22="">

        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FOREARM
        FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bony
        surfaces and articular margins of bones of hand are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        evidence of fracture, bone destruction or cystic areas is seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Visualized
        wrist joint and carpometacarpal joint space is normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bones
        shows normal architecture.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No soft
        tissue calcification is seen.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">ELBOW
        FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Bones under review are
        intact. Normal mineralization is noted with smooth cortical outline.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">No acute fracture or
        dislocation is noted.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Joint spaces are
        maintained.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Articular margins are
        smooth. </span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;background:white;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">No soft tissue
        abnormality noted.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">WRIST
        FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bony
        surfaces and articular margins of bones of hand are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        evidence of fracture, bone destruction or cystic areas is seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Visualized
        wrist joint and carpometacarpal joint space is normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bones
        shows normal architecture.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No soft
        tissue calcification is seen.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINGER
        FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No obvious bony
        lesion is noted.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No obvious
        fracture/dislocation is noted.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Joint spaces appears
        normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Alignment appears
        normal.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No evidence of fracture.</span></p></li><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No significant bony abnormality is
        noted in Both Hand Study.</span></p></li></ul>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-oru-c22=""><p style="margin: 0px 0in 12pt; color: rgb(51, 51, 51); font-style: normal; font-weight: 400; text-indent: 0px; white-space: normal; background-color: rgb(255, 255, 255); font-size: 11pt; font-family: Calibri, sans-serif; text-align: justify; line-height: normal;"><b style="font-weight: 700;"><span style="font-family: &quot;Times New Roman&quot;, serif;"></span></b></p><!--EndFragment-->
        
        </div><p></p>`
    },
    {
        id:'rt18',
        label:'KNEE AP & LATERAL',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">KNEE AP & LATERAL</span></u></b></font></p><p></p><div class="pasteContent" style="display:inline;" _ngcontent-ios-c32="">

        <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:12.0pt;">FINDINGS</span></u></b><span style="font-size:12.0pt;"> :</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;">Visualized
        part of femur and tibia show normal density.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;">Joint
        spaces between medial and lateral tibio-femoral compartments and
        patella-femoral compartment are maintained. </span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;">Overlying
        soft tissues are unremarkable.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;">No
        lytic/sclerotic lesion is seen.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;">No
        obvious fracture seen in the visualized bones.</span></p>
        
        <br>
        
        <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:12.0pt;">IMPRESSION</span></u></b><span style="font-size:12.0pt;"> :</span></p>
        
        <br>
        
        <span style="font-size:12.0pt;">NO
        STRUCTURAL ABNORMALITY SEEN.</span>
        
        
        
        </div><p><br></p><p></p>`
    },
    {
        id:'rt19',
        label:'KUB',
        value:`<p style="text-align: center;">
        <font face="Times New Roman"><strong><u><span style="font-size: 14pt;">KUB</span></u></strong></font>
    </p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Clinical History&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Comparisons&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;: <o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Observation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;:<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;line-height:200%;mso-layout-grid-align: none;text-autospace:none">
        <font size="2">No obvious radiopaque shadows are seen in KUB region. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;line-height:200%;mso-layout-grid-align: none;text-autospace:none">
        <font size="2">Bilateral Psoas shadows are unremarkable.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;line-height:200%;mso-layout-grid-align: none;text-autospace:none">
        <font size="2">Bowel gas pattern is unremarkable.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;line-height:200%;mso-layout-grid-align: none;text-autospace:none">
        <font size="2">Lumbar vertebrae are unremarkable.&nbsp; <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;line-height:200%;mso-layout-grid-align: none;text-autospace:none">
        <font size="2"><strong><span lang="IT">IMPRESSION</span></strong><strong>:-</strong></font>
    </p>
    <p class="MsoNormal" style="margin-top:12.0pt;line-height:200%;mso-layout-grid-align: none;text-autospace:none">
        <font size="2"><span style="text-indent: -0.25in; background-color: transparent; line-height: 200%; font-family: Symbol;">·<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><strong style="text-indent: -0.25in; background-color: transparent;"><span style="line-height: 200%; font-family: &quot;Times New Roman&quot;, serif;">NO OBVIOUS RADIOPAQUE SHADOWS ARE SEEN IN KUB REGION.&nbsp;</span></strong></font>
    </p>`
    },
    {
        id:'rt20',
        label:'L-S SPINE AP & LATERAL',
        value:`<p style="text-align: center;">
        <font face="Times New Roman"><strong><u><span style="font-size: 14pt;">L-S SPINE AP & LATERAL</span></u></strong></font>
    </p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Clinical History&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Comparisons&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;: <o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Observation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;:<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">Normal spinal curvature is maintained. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">Normal vertebral height is maintained. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">The inter-vertebral disc spaces appear maintained. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">Facetal joints are normal.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">Visualized pedicles appear normal.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">No evidence of lytic/sclerotic lesion seen.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">No evidence of lysis/listhesis is seen.<o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none">
        <font size="2">Pre or para-spinal soft tissue planes are maintained. <o:p></o:p>
        </font>
    </p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-bottom:12.0pt;mso-layout-grid-align:none; text-autospace:none"><strong>
            <font size="2">IMPRESSION:-</font>
            <o:p></o:p>
        </strong></p>`
    },
    {
        id:'rt21',
        label:'LEG AP/LAT',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">LEG AP/LAT</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-vem-c22="">

        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">BOTH
        FEMUR FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Both femur
        shows normal bony architecture and trabecular pattern.<span>&nbsp; </span>Particularly, there is no radiographic
        evidence of osteoporosis or osteomalacia.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Soft
        tissue planes around the bone are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">The hip
        joint space is normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        evidence of fracture.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        evidence of loose bodies or joint effusion seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No soft
        tissue mass or calcification seen.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">BOTH
        KNEE FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bony
        surfaces and articular margins of bones of both knees joint are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Both knee
        joint spaces are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">There
        is no evidence of fracture or loose bodies.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        abnormal soft tissue calcification seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bones show normal
        architecture.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">BOTH
        TIBIA FIBULA FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bones in view appear
        intact.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Distal end of both tibia
        and fibula appears normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No e/o fracture/bony
        lesions noted.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Joint spaces are
        normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        abnormal soft tissue shadow is seen.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">BOTH
        ANKLE FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bony surfaces and articular marg</span><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">ins of bones of both
        ankles are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">There is no evidence of</span><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;"> fracture or focal
        bone lesion.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Both ankle
        joint spaces are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No abnormal sof</span><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">t tissue
        calcification is seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bone architecture is normal.</span></p>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">BOTH
        FOOT FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bony
        surfaces and articular margins of bones of both foot are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">There
        is no evidence of fracture or bone destruction.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Tarsometatarsal
        and subtalar joint space is normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No soft
        tissue calcification is seen.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bone
        architecture is normal.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No evidence of fracture.</span></p></li><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No significant bony abnormality is
        noted in both leg.</span></p></li></ul>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-oru-c22=""><p style="margin: 0px 0in 12pt; color: rgb(51, 51, 51); font-style: normal; font-weight: 400; text-indent: 0px; white-space: normal; background-color: rgb(255, 255, 255); font-size: 11pt; font-family: Calibri, sans-serif; text-align: justify; line-height: normal;"><b style="font-weight: 700;"><span style="font-family: &quot;Times New Roman&quot;, serif;"></span></b></p><!--EndFragment-->
        
        </div><p></p>`
    },
    {
        id:'rt22',
        label:'LOWER EXTREMITY',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">LOWER EXTREMITY</span></u></b></font></p><p>Use own templates</p>`
    },
    {
        id:'rt23',
        label:'MANDIBLE',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">MANDIBLE</span></u></b></font></p><p><b></b></p><div class="pasteContent" style="display:inline;" _ngcontent-tie-c22="">


        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bony
        surfaces and articular margins of mandible are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">There
        is no evidence of fracture or bone destruction.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Visualized
        TM joint is normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bone
        architecture is normal.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        abnormality detected.</span></p></li></ul>
        
        <br>
        
        <span style="font-size:12.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please
        correlate clinically.</span>
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-tie-c22=""></div><p><br></p><p></p>`
    },
    {
        id:'rt24',
        label:'NASOPHARYNX-LAT',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">NASOPHARYNX-LAT</span></u></b></font></p><p><b></b></p><div class="simditor-table"><b style="font-family: Calibri, sans-serif; font-size: 11pt; text-align: justify; background-color: unset;"><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINDINGS:</span></u></b><br></div><div class="pasteContent" style="display:inline;" _ngcontent-tie-c22="">

        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;line-height:
        normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        signIficant soft tissue noted in the nasopharynx region.&nbsp;<br>
        Cervical vertebrae, intervertebral disc spaces &amp; appendages are
        normal.&nbsp;<br>
        Vertebral alignment &amp; spinal canal width are normal.&nbsp;<br>
        Normal cervical lordosis is maintained.&nbsp;<br>
        No evidence of radio-opaque foreign body in the part visualized.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No evidence of significant adenoid hypertrophy or
        asopharyngeal airway narrowing.</span></p></li></ul>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><p><br></p><p></p>`
    },
    {
        id:'rt25',
        label:'NECK SOFT TISSUE AP/LAT',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">NECK SOFT TISSUE AP/LAT</span></u></b></font></p><p></p><div class="pasteContent" style="display:inline;" _ngcontent-ccl-c32="">



        <p style="font-size:11.0pt;"><b><u><span style="font-size:12.0pt;">FINDINGS:</span></u></b></p>
        
        <p style="font-size:12.0pt;margin:0in;"><span style="color:black;">Curvature
        and alignment of the cervical spine is normal.</span></p>
        
        <p style="font-size:12.0pt;margin:0in;"><span style="color:black;">Rest
        of the visualised soft tissues appear normal.</span></p>
        
        <p style="font-size:12.0pt;margin:0in;"><span style="color:black;">The
        vertebrae are of normal height and density.</span></p>
        
        <p style="font-size:12.0pt;margin:0in;"><span style="color:black;">The
        disc spaces are normal.</span></p>
        
        <p style="font-size:12.0pt;margin:0in;"><span style="color:black;">Posterior
        elements are normal.</span></p>
        
        <p style="font-size:12.0pt;margin:0in;"><span style="color:black;">No
        abnormal pre or paravertebral soft tissue noted. </span></p>
        
        <p style="font-size:12.0pt;margin:0in;"><span style="color:black;">Craniovertebral
        junction is normal.&nbsp;</span></p>
        
        <p style="font-size:12.0pt;margin:0in;"><span style="color:black;">Bone
        density is normal.</span></p>
        
        <br>
        
        <br>
        
        <p style="font-size:11.0pt;"><b><u><span style="font-size:12.0pt;">IMPRESSION<i>:</i></span></u></b></p>
        
        <br>
        
        <ul level="1"><li><p><span style="font-size:12.0pt;
        color:black;">Normal study of Neck.</span></p></li></ul>
        
        <br>
        
        <p style="font-size:11.0pt;"><span style="font-size:12.0pt;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><p><br></p><p></p>`
    },
    {
        id:'rt26',
        label:'PELVIS AP',
        value:`<p style="text-align: center;">
        <font face="Times New Roman"><strong><u><span style="font-size: 14pt;">PELVIS AP</span></u></strong></font>
    </p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Clinical History&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Comparisons&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;: <o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;text-justify: inter-ideograph;line-height:normal;mso-pagination:none;mso-layout-grid-align: none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="2">Observation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;:<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal"><span style="line-height: 115%; font-family: &quot;Century Schoolbook&quot;, serif; color: black;">
            <font size="2">-Both sacro-iliac joints appear normal.<o:p></o:p>
            </font>
        </span></p>
    <p> <br></p>
    <p class="MsoNormal"><span style="line-height: 115%; font-family: &quot;Century Schoolbook&quot;, serif; color: black;">
            <font size="2">-Both lilac and pubic bones appear normal.<o:p></o:p>
            </font>
        </span></p>
    <p> <br></p>
    <p class="MsoNormal"><span style="line-height: 115%; font-family: &quot;Century Schoolbook&quot;, serif; color: black;">
            <font size="2">-Both heads of femur appears normal.<o:p></o:p>
            </font>
        </span></p>
    <p> <br></p>
    <p class="MsoNormal"><span style="line-height: 115%; font-family: &quot;Century Schoolbook&quot;, serif; color: black;">
            <font size="2">- Joint space appears normal.<o:p></o:p>
            </font>
        </span></p>
    <p> <br></p>
    <p class="MsoNormal"><span style="line-height: 115%; font-family: &quot;Century Schoolbook&quot;, serif; color: black;">
            <font size="2">- Soft tissue appears normal.</font>
        </span></p>
    <p> <br></p>
    <p class="MsoNormal"><strong><span style="font-family:&quot;Century Schoolbook&quot;,&quot;serif&quot;;mso-bidi-font-family:&quot;Century Schoolbook&quot;; color:black">
                <font size="2">IMPRESSION:<o:p></o:p>
                </font>
            </span></strong></p>
    <p> <br></p>
    <p class="MsoNormal">
        <font size="2"><strong><span style="font-family:&quot;Century Schoolbook&quot;,&quot;serif&quot;;mso-bidi-font-family:&quot;Century Schoolbook&quot;; color:black">
                    <o:p>&nbsp;</o:p>
                </span></strong><span style="text-indent: -0.25in; background-color: transparent; line-height: 150%; font-family: Symbol; color: black;">·<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><strong style="text-indent: -0.25in; background-color: transparent;"><span style="line-height: 150%; font-family: &quot;Century Schoolbook&quot;, serif; color: black;">NORMAL STUDY</span></strong></font>
    </p>`
    },
    {
        id:'rt27',
        label:'PNS',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">PNS</span></u></b></font></p><p><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 14pt;">Sinuses appear normal.</span></span><br></p><div class="pasteContent" style="display:inline;" _ngcontent-pqh-c20=""><p style="margin-right:0in;margin-bottom:10.0pt;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 14pt;">There is no evidence of nasal septum deviation.</span></span></p>

        <p style="margin-right:0in;margin-bottom:10.0pt;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 14pt;">No obvious bony erosion.</span></span></p>
        
        <p style="margin-right:0in;margin-bottom:10.0pt;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 14pt;">Nasal cavity appears normal.</span></span></p>
        
        <br><p style="margin-right:0in;margin-bottom:10.0pt;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;"><b><u><span style="font-family: Arial, Helvetica, sans-serif;"><span style="font-size: 14pt;">IMPRESSION</span></span></u></b></p><p style="margin-right:0in;margin-bottom:10.0pt;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;"><span style="font-size: 14pt;"><span style="font-family: Arial, Helvetica, sans-serif;">NO SIGNIFICANT ABNORMALITY.</span></span></p>
        
        
        
        
        
        </div><p><br></p><p></p>`
    },
    {
        id:'rt28',
        label:'SACRUM AND COCCYX AP/LAT',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">SACRUM AND COCCYX AP/LAT</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-vem-c22="">



        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span lang="EN-IN" style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">Curvature of saccrum and coccyx is maintained.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span lang="EN-IN" style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">The vertebral bodies and posterior elements are normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span lang="EN-IN" style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">There is no fracture or destructive lesion.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span lang="EN-IN" style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">The alignment appears normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span lang="EN-IN" style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">There is no obvious radiographic evidence of instability.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span lang="EN-IN" style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">No soft-tissue abnormality noted.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span lang="EN-IN" style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;color:black;">The sacrum and both sacro-iliac joints are normal.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;
        color:black;">No significant abnormality.</span></p></li></ul>
        
        <br>
        
        <span style="font-size:12.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please
        correlate clinically.</span>
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-oru-c22=""><p style="margin: 0px 0in 12pt; color: rgb(51, 51, 51); font-style: normal; font-weight: 400; text-indent: 0px; white-space: normal; background-color: rgb(255, 255, 255); font-size: 11pt; font-family: Calibri, sans-serif; text-align: justify; line-height: normal;"><b style="font-weight: 700;"><span style="font-family: &quot;Times New Roman&quot;, serif;"></span></b></p><!--EndFragment-->
        
        </div><p></p>`
    },
    {
        id:'rt29',
        label:'SHOULDER AP & AXILARY VIEW',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">SHOULDER AP & AXILARY VIEW</span></u></b></font></p><p></p><div class="pasteContent" style="display:inline;" _ngcontent-ios-c32="">


        <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:12.0pt;">FINDINGS</span></u></b><span style="font-size:12.0pt;"> :</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;">Bones
        under view appear normal in density. </span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;">Normal
        alignment is maintained.<span>&nbsp; </span></span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;">No
        evidence of lytic/sclerotic lesion seen.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;">No
        evidence of any obvious fracture / dislocation visualized.<span>&nbsp;&nbsp; </span></span></p>
        
        <br>
        
        <p style="margin:0in;font-size:11.0pt;"><b><span style="font-size:12.0pt;">I<u>MPRESSION</u></span></b><span style="font-size:12.0pt;"> :</span></p>
        
        <ul level="1"><li><p><span style="font-size:12.0pt;">NORMAL STUDY</span></p></li></ul>
        
        <br>
        
        <br>
        
        <br>
        
        <br>
        
        
        </div><p><br></p><p></p>`
    },
    {
        id:'rt30',
        label:'SI JOINT',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">SI JOINT</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-vem-c22="">

        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">FINDINGS:</span></u></b></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Bilateral sacroiliac
        joints show normal marrow signal intensity.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Joint spaces &amp;
        alignment is preserved.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Periarticular soft
        tissue appear normal.</span></p>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Sacroiliac ligaments
        appear normal.</span></p>
        
        <br>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><b><u><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        <ul level="1" style="margin-bottom:0in;"><li><p><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">No
        abnormality detected.</span></p></li></ul>
        
        <br>
        
        <p style="margin-top:0in;margin-right:0in;margin-left:0in;font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;margin-bottom:0in;margin-bottom:.0001pt;text-align:
        justify;line-height:normal;"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-oru-c22=""><p style="margin: 0px 0in 12pt; color: rgb(51, 51, 51); font-style: normal; font-weight: 400; text-indent: 0px; white-space: normal; background-color: rgb(255, 255, 255); font-size: 11pt; font-family: Calibri, sans-serif; text-align: justify; line-height: normal;"><b style="font-weight: 700;"><span style="font-family: &quot;Times New Roman&quot;, serif;"></span></b></p><!--EndFragment-->
        
        </div><p></p>`
    },
    {
        id:'rt31',
        label:'SKULL AP & LATERAL',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">SKULL AP & LATERAL</span></u></b></font></p><p></p><div class="pasteContent" style="display:inline;" _ngcontent-ios-c32="">



        <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:12.0pt;">FINDINGS</span></u></b><span style="font-size:12.0pt;"> :</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
        color:black;">No evidence of increased intracranial tension or significant dense
        shadow</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
        color:black;">Sella appears normal.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
        color:black;">Sutures appear normal.</span></p>
        
        <br>
        
        <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:12.0pt;color:black;">IMPRESSION:</span></u></b></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
        color:black;"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;color:black;"><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span style="font-size:12.0pt;
        color:black;">NORMAL STUDY.</span></p>
        
        
        
        
        
        </div><p><br></p><p></p>`
    },
    {
        id:'rt32',
        label:'THORACIC SPINE AP & LATERAL',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">THORACIC SPINE AP & LATERAL</span></u></b></font></p><p></p><div class="pasteContent" style="display:inline;" _ngcontent-xko-c32="">



        <br>
        
        <p style="font-size:11.0pt;"><b><u><span style="font-size:14.0pt;">FINDINGS:</span></u></b></p>
        
        <p style="font-size:12.0pt;"><span style="font-size:14.0pt;
        color:black;">Curvature and alignment of the dorsal spine is normal.</span></p>
        
        <p style="font-size:12.0pt;"><span style="font-size:14.0pt;
        color:black;">The vertebrae are of normal height and density.</span></p>
        
        <p style="font-size:12.0pt;"><span style="font-size:14.0pt;
        color:black;">The disc spaces are normal.</span></p>
        
        <p style="font-size:12.0pt;"><span style="font-size:14.0pt;
        color:black;">Posterior elements are normal.</span></p>
        
        <p style="font-size:12.0pt;"><span style="font-size:14.0pt;
        color:black;">No abnormal pre or paravertebral soft tissue noted. </span></p>
        
        <p style="font-size:12.0pt;"><span style="font-size:14.0pt;
        color:black;">Bone density is normal. </span></p>
        
        <p style="font-size:12.0pt;"><span style="font-size:14.0pt;
        color:black;">Visualized ribs and lungs appear normal. </span></p>
        
        
        
        <br>
        
        <p style="font-size:11.0pt;"><b><u><span style="font-size:14.0pt;">IMPRESSION<i>:</i></span></u></b></p>
        
        <br>
        
        <ul level="1"><li><p><span style="font-size:14.0pt;">No
        abnormality detected.</span></p></li></ul>
        
        <br>
        
        <p style="font-size:11.0pt;"><span style="font-size:14.0pt;">Please correlate
        clinically.</span></p>
        
        
        
        
        
        </div><p><br></p><p></p>`
    },
    {
        id:'rt33',
        label:'WRIST AP & LATERAL',
        value:`<p style="text-align: center;"><font face="Times New Roman"><b><u><span style="font-size: 14pt;">WRIST AP & LATERAL</span></u></b></font></p><div class="pasteContent" style="display:inline;" _ngcontent-ios-c32="">


        <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:12.0pt;">FINDINGS :</span></u></b></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
        color:black;">Distal radius and ulna appears normal.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
        color:black;">Visualized carpal bones appear normal.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
        color:black;">Metacarpals appear normal.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
        color:black;">Phalanges appear normal.</span></p>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;
        color:black;">Soft tissue appears normal.</span></p>
        
        <br>
        
        <p style="margin:0in;font-size:11.0pt;"><b><u><span style="font-size:12.0pt;color:black;">IMPRESSION:</span></u></b></p>
        
        <br>
        
        <p style="margin:0in;font-size:11.0pt;"><span style="font-size:12.0pt;color:black;"><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span style="font-size:12.0pt;
        color:black;">NORMAL STUDY.</span></p>
        
        
        
        
        
        </div><div class="pasteContent" style="display:inline;" _ngcontent-ios-c32=""></div><p><br></p><p></p>`
    }
    
];
