import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
// import { useDispatch } from 'react-redux';
import axios from "Utils/./callApi";
import wait from "Utils/./wait";
import { useSnackbar } from "notistack";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import callApi from "Utils/callApi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import console from "react-console";

const useStyles = makeStyles(() => ({
  root: {},
}));

const AddNewClinic = (className, user, ...rest) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const [valid, setValid] = useState(true);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container
        maxWidth={false}
        style={{
          padding: "20px",
          background: "white",
          margin: "0 auto",
          width: "60%",
          borderRadius: "10px",
        }}
      >
        <h1>Add a new Clinic</h1>
        <Formik
          initialValues={{
            name: "",
            phone: "",
            email: "",
            address: "",
            password: "",
            roleType: "clinic",
            isAdmin: false,
            balance: 0,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string("Enter Clinic Name").required(
              "Clinic Name is required"
            ),
            // phone: Yup.string("Enter Clinic Phone Number")
            //   .required("Phone Number is required")
            //   .test("test-name", "Enter Valid Phone Number", function (value) {
            //     const phoneRegex =
            //       /^([0-9]{11,11}(-[0-9]*){0,1}|([\w-]+(?:\.[\w-]+)*)+@\w+\.\w{2,3})$/;
            //     let isValidPhone = phoneRegex.test(value);
            //     if (!isValidPhone) {
            //       return false;
            //     }
            //     return true;
            //   }),
            email: Yup.string("Enter CLinic Email"),
            address: Yup.string("Enter address").required(
              "Address is required"
            ),
            password: Yup.string().max(255).required("Password is required"),
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              console.log(values);
              const { name, phone, email, password, address } = values;
              values.createdByUserId = loggedUser.userId;
              console.log(name, phone, email, password, address);
              resetForm();
              await callApi.post("/registration", values).then((response) => {
                console.log(response);
              });
              await wait(500);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              enqueueSnackbar("Clinic Added Successfully", {
                variant: "success",
                // action: <Button>See all</Button>
              });
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Box xs={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      autoFocus
                      margin="normal"
                      label="Clinic Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={5} style={{paddingTop:"10px"}}>
                    {/*<TextField
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    fullWidth
                    autoFocus
                    label=" Phone Number"
                    margin="normal"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="number"
                    value={values.phone}
                    variant="outlined"
                  />*/}
                    <label>
                      <PhoneInput
                        country={"in"}
                        // value={phoneNumber}
                        onChange={(value) => {
                          values.phone = value;
                          console.log("phone", values);
                        }}
                        name="phone"
                        value={values.phone}
                        fullWidth
                        enableSearch={true}
                        inputStyle={{ width: "100%"}}
                        inputProps={{
                          required: true,
                        }}
                        size="large"
                        sx={{
                          marginTop:"10px"
                        }}
                      />
                    </label>
                    {!valid && <p>Please enter a valid phone number.</p>}
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      autoFocus
                      helperText={touched.email && errors.email}
                      label=" Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.email}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      autoFocus
                      margin="normal"
                      label="Address"
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      variant="outlined"
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}></Grid>
                </Grid>
              </Box>
              <Box flex={1} justifyContent="center" alignItems="center">
                <Grid xs={5}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Add Clinic
                  </Button>
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
    </DashboardLayout>
  );
};
AddNewClinic.propTypes = {
  className: PropTypes.string,
  // user: PropTypes.object.isRequired
};

export default AddNewClinic;
