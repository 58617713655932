/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Container,
  Divider,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
// react-router-dom components
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import console from "react-console"
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { useSnackbar } from "notistack";
import { useCookies } from "react-cookie";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/xraybg.jpg";
import * as Yup from "yup";
import callApi from "Utils/callApi";
import React, { useEffect } from "react";

function Basic({ className, onSubmitSuccess, ...rest }) {
  const [rememberMe, setRememberMe] = useState(false);
  const [loginWithEmail, setLoginWithEmail] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [cookies, setCookie] = useCookies({});
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSetLoginWithEmail = () => setLoginWithEmail(!loginWithEmail);
  useEffect(() => {
    if (localStorage.getItem("loggedUser")) {
      history.push("/");
    }
  }, []);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);
  const [password, setPassword] = useState("");

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const handleChangePhone = (value) => {
    setPhoneNumber(value);
    setValid(validatePhoneNumber(value));
    console.log("helllllllllllo",value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    console.log(e.target.value)
  };
  const handleLogin = () => {
    console.log({password,phoneNumber})
    console.log("hello")
    try {
      if (!password || !phoneNumber) {
        return enqueueSnackbar("Invalid email or password", {
          variant: "error",
        });
      }
      let data = {
        emailOrPhone: phoneNumber,
        password: password
      }
      callApi
        .post("/login", data)
        .then((res) => {
          enqueueSnackbar("Login Success", {
            variant: "success",
          });
          delete res.data.user.password;
          console.log(res.data);
          if (res.data.success) {
            console.log("user", res.data);
            localStorage.setItem(
              "loggedUser",
              JSON.stringify(res.data.user)
            );
            setCookie("loggedUser", res.data.user, {});
            history.push("/approved-and-pending-xray");
          }
        })
        .catch((error) => {
          return enqueueSnackbar("Invalid Credential", {
            variant: "error",
          });
        });
    } catch (error) {
      localStorage.clear();
      enqueueSnackbar("Invalid Credential", {
        variant: "error",
      });
    }
  };


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={{
              emailOrPhone: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              emailOrPhone: Yup.string("Enter your Email/Phone Number")
                // .email("Enter a valid email")
                .required("Email/Phone Number is required")
                .test("test-name", "Enter Valid Phone/Email", function (value) {
                  // eslint-disable-next-line no-useless-escape
                  const emailRegex =
                    /^([a-zA-Z0-9_\.-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                  // const phoneRegex = /^([0-9]{11,11}(-[0-9]*){0,1}|([\w-]+(?:\.[\w-]+)*)+@\w+\.\w{2,3})$/;
                  const phoneRegex =
                    /^(?:\+?\d{1,3}[\s-]?)?(?:\d{3,4}[\s-]?)?\d{3}[\s-]?\d{4}$/;
                  let isValidEmail = emailRegex.test(value);
                  let isValidPhone = phoneRegex.test(value);
                  if (!isValidEmail && !isValidPhone) {
                    return false;
                  }
                  return true;
                }),
              password: Yup.string()
                .min(6)
                .max(255)
                .required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                console.log({ values });
                if (!values.emailOrPhone || !values.password) {
                  return enqueueSnackbar("Invalid email or password", {
                    variant: "error",
                  });
                }
                callApi
                  .post("/login", values)
                  .then((res) => {
                    enqueueSnackbar("Login Success", {
                      variant: "success",
                    });
                    delete res.data.user.password;
                    console.log(res.data);
                    if (res.data.success) {
                      console.log("user", res.data);
                      localStorage.setItem(
                        "loggedUser",
                        JSON.stringify(res.data.user)
                      );
                      setCookie("loggedUser", res.data.user, {});
                      history.push("/approved-and-pending-xray");
                    }
                  })
                  .catch((error) => {
                    return enqueueSnackbar("Invalid Credential", {
                      variant: "error",
                    });
                  });
              } catch (error) {
                localStorage.clear();
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...rest}>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Switch
                    checked={loginWithEmail}
                    onChange={handleSetLoginWithEmail}
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetLoginWithEmail}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Login With Email
                  </MDTypography>
                </MDBox>
                {loginWithEmail && (
                  <>
                    <MDInput
                      error={Boolean(
                        touched.emailOrPhone && errors.emailOrPhone
                      )}
                      fullWidth
                      autoFocus
                      helperText={touched.emailOrPhone && errors.emailOrPhone}
                      label="Email Address"
                      margin="normal"
                      name="emailOrPhone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.emailOrPhone}
                      variant="outlined"
                    />
                    <MDInput
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <MDBox display="flex" alignItems="center" ml={-1}>
                      <Switch
                        checked={rememberMe}
                        onChange={handleSetRememberMe}
                      />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={handleSetRememberMe}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Remember me
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                      >
                        Sign In
                      </MDButton>
                      {errors.submit && (
                        <MDBox mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </MDBox>
                      )}
                    </MDBox>
                  </>
                )}
              </form>
            )}
          </Formik>
          {!loginWithEmail && (
            <>
              <MDBox mt={3} mb={1} >
                <label>
                  <PhoneInput
                    country={"in"}
                    value={phoneNumber}
                    onChange={handleChangePhone}
                    name="phone"
                    // value={values.emailOrPhone}
                    fullWidth
                    enableSearch={true}
                    inputProps={{
                      required: true,
                    }}
                  />
                </label>
                {!valid && <p>Please enter a valid phone number.</p>}
              </MDBox>

              <MDInput
                fullWidth
                label="Password"
                margin="normal"
                name="password"
                // onBlur={handleBlur}
                onChange={handleChangePassword}
                type="password"
                // value={values.password}
                variant="outlined"
              />
              <MDButton
                variant="gradient"
                color="info"
                // disabled={isSubmitting}
                onClick={handleLogin}
                fullWidth
                size="large"
                // type="submit"
              >
                Sign In
              </MDButton>
            </>
          )}
          <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
