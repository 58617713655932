import React, { useEffect, useState, useCallback } from "react";
import { forwardRef } from "react";
// import Avatar from 'react-avatar';
import Grid from "@material-ui/core/Grid";

import MaterialTable from "material-table";

import useIsMountedRef from "hooks/useIsMountedRef";
import { useSnackbar } from "notistack";
import Card from "@mui/material/Card";
import callApi from "Utils/callApi";
import console from "react-console";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { authUser } from "Utils/authUser";
import { Typography, Box } from "@mui/material";
import { getFormattedDate } from "Utils/common-computes";
import TextField from "@mui/material/TextField";
import { Menu, MenuItem, Button, makeStyles } from "@material-ui/core";

function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

const UserRates = () => {
  const isMountedRef = useIsMountedRef();

  const [wallet, setWallet] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [totalBillingAmount, setTotalBillingAmount] = useState(0);
  var columns = [
    {
      title: "Date",
      field: "createdDatetimeStamp",
      render: (rowData) => {
        return (
          <MDTypography>
            {getFormattedDate(rowData?.createdDatetimeStamp)}
          </MDTypography>
        );
      },
    },
    {
      title: "Patient Name",
      field: "patient",
    },
    {
      title: "Patient Phone",
      field: "phone",
    },
    {
      title: "Gender",
      field: "gender",
      // render: (rowData) => {
      //   return (
      //     <Typography component="h4" variant="div">
      //       <span style={{ fontSize: 15, color: "green" }}>    {rowData?.xray_rate.general}</span>
      //       <sup style={{ fontSize: 10 }}> (General)</sup> <br></br>
      //       <span style={{ fontSize: 15, color: "skyblue" }}> {rowData?.xray_rate.emergency}</span>
      //       <sup style={{ fontSize: 10 }}> (Emergency)</sup>
      //     </Typography>
      //   )
      // }
    },
    {
      title: "Modality",
      field: "filmType",
    },
    {
      title: "Study",
      field: "study",
    },
    {
      title: "Priority",
      field: "priority",
    },
    {
      title: "Charge",
      field: "charge",
      // render: (rowData) => {
      //   // return (
      //   //   <Typography component="h4" variant="div">
      //   //     <span style={{ fontSize: 15, color: "green" }}> {rowData?.mri_rate.general}</span>
      //   //     <sup style={{ fontSize: 10 }}> (General)</sup> <br></br>
      //   //     <span style={{ fontSize: 15, color: "skyblue" }}> {rowData?.mri_rate.emergency}</span>
      //   //     <sup style={{ fontSize: 10 }}> (Emergency)</sup>
      //   //   </Typography>
      //   // )
      //   if(rowData.filmType === "Xray") {
      //     if (rowData.priority === "General") {
      //       return 15
      //     };
      //     if (rowData.priority === "Emergency") {
      //       return 20
      //     }
      //   }
      //   if(rowData.filmType === "MRI") {
      //     if (rowData.priority === "General") {
      //       return 20
      //     };
      //     if (rowData.priority === "Emergency") {
      //       return 30
      //     }
      //   }
      //   if(rowData.filmType === "CT-Scan") {
      //     if (rowData.priority === "General") {
      //       return 25
      //     };
      //     if (rowData.priority === "Emergency") {
      //       return 30
      //     }
      //   }
      //   if(rowData.filmType === "Hematology") {
      //     if (rowData.priority === "General") {
      //       return 35
      //     };
      //     if (rowData.priority === "Emergency") {
      //       return 40
      //     }
      //   }

      // }
    },
    // {
    //   title: "Body Part", field: "ct_rate", size: "200px",
    //   render: (rowData) => {
    //     return (
    //       <Typography component="h4" variant="div">
    //         <span style={{ fontSize: 15, color: "green" }}> {rowData?.ct_rate.general}</span>
    //         <sup style={{ fontSize: 10 }}> (General)</sup> <br></br>
    //         <span style={{ fontSize: 15, color: "skyblue" }}> {rowData?.ct_rate.emergency}</span>
    //         <sup style={{ fontSize: 10 }}> (Emergency)</sup>
    //       </Typography>
    //     )
    //   }
    // },
    // {
    //   title: "Charge", field: "hematology_rate",
    //   render: (rowData) => {
    //     return (
    //       <Typography component="h4" variant="div">
    //         <span style={{ fontSize: 15, color: "green" }}> {rowData?.hematology_rate.general}</span>
    //         <sup style={{ fontSize: 10 }}> (General)</sup> <br></br>
    //         <span style={{ fontSize: 15, color: "skyblue" }}> {rowData?.hematology_rate.emergency}</span>
    //         <sup style={{ fontSize: 10 }}> (Emergency)</sup>
    //       </Typography>
    //     )
    //   }
    // },
  ];

  const [data, setData] = useState([]);
  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  let startOfTheDay = new Date();
  let endOfTheDay = new Date();
  startOfTheDay.setHours(0, 0, 0, 0);
  endOfTheDay.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(startOfTheDay.getTime());
  const [endDate, setEndDate] = useState(endOfTheDay.getTime());

  let searchParameters = {};

  let user = authUser();

  if (
    user?.roleType !== "admin" &&
    user?.roleType !== "superAdmin" &&
    user?.roleType !== "doctor"
  ) {
    searchParameters = {
      roleType: "clinic",
      userId: user?.userId,
    };
  } else if (user?.roleType !== "admin" && user?.roleType !== "superAdmin") {
    searchParameters = {
      roleType: "doctor",
      userId: user?.userId,
    };
  } else {
    searchParameters = {};
  }

  const getDraftedXrayList = useCallback(
    (status, cbfn) => {
      searchParameters.status = status;
      setLoading(true);
      console.log({ searchParameters });
      callApi
        .post("/xray/filter-by-status", searchParameters)
        .then((res) => {
          setLoading(false);
          let xrayRep = [];
          let xrayFiles = res.data.data.report;
          xrayFiles.forEach((report) => {
            if (report.filmType === "Xray") {
              if (report.priority === "General") {
                report.charge = wallet?.xray_rate?.general;
                xrayRep.push(report);
              }
              if (report.priority === "Emergency") {
                report.charge = wallet?.xray_rate?.emergency;
                xrayRep.push(report);
              }
            }
            if (report.filmType === "MRI") {
              if (report.priority === "General") {
                report.charge = wallet?.mri_rate?.general;
                xrayRep.push(report);
              }
              if (report.priority === "Emergency") {
                report.charge = wallet?.mri_rate?.emergency;
                xrayRep.push(report);
              }
            }
            if (report.filmType === "CT-Scan") {
              if (report.priority === "General") {
                report.charge = wallet?.ct_rate?.general;
                xrayRep.push(report);
              }
              if (report.priority === "Emergency") {
                report.charge = wallet?.ct_rate?.emergency;
                xrayRep.push(report);
              }
            }
            if (report.filmType === "Hematology") {
              if (report.priority === "General") {
                report.charge = wallet?.hematology_rate?.general;
                xrayRep.push(report);
              }
              if (report.priority === "Emergency") {
                report.charge = wallet?.hematology_rate?.emergency;
                xrayRep.push(report);
              }
            }
          });
          console.log({ xrayRep });
          setData(xrayRep);
          const billingAmount = xrayRep?.reduce(
            (total, currentItem) =>
              (total = total + parseInt(currentItem?.charge || 0)),
            0
          );
          console.log({ billingAmount });
          setTotalBillingAmount(billingAmount);
          cbfn(xrayFiles);
        })
        .catch((error) => {
          enqueueSnackbar(error?.message);
        });
    },
    [isMountedRef]
  );
  const [finalReportCounter, setFinalReportCounter] = useState(0);

  useEffect(() => {
    async function fetchData() {
      let user = await authUser();
      getDraftedXrayList("Final", (finalReports) => {
        console.log({ finalReports });
        // setFinalReportCounter(finalReports.length);
      });
    }
    fetchData();
  }, []);

  const startingDateToTimeStamp = (queryDate) => {
    const tempDate = queryDate.split("/");
    const convertedDate = new Date(tempDate).getTime();
    console.log("convertedDate", convertedDate);
    setStartDate(convertedDate ? convertedDate : null);
  };
  const endingDateToTimeStamp = (queryDate) => {
    const tempDate = queryDate.split("/");
    const convertedDate = new Date(tempDate).getTime() + 60 * 60 * 24 * 1000;
    console.log("convertedDate", convertedDate);
    setEndDate(convertedDate ? convertedDate : null);
  };

  const getListByDate = () => {
    searchParameters.startDate = startDate;
    searchParameters.endDate = endDate;
    getDraftedXrayList("Final", (finalReports) => {
      console.log({ finalReports });
    });
  };
  const getWalletDetailsUsingUserId = useCallback(() => {
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    callApi
      .post("/find-wallet-by-id/" + loggedUser.userId, {
        name: loggedUser.name,
      })
      .then((response) => {
        const wallet = response.data.wallet;
        console.log("wallet........................", wallet);
        setWallet(wallet);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);
  useEffect(() => {
    getWalletDetailsUsingUserId();
  }, [getWalletDetailsUsingUserId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="App" style={{ marginTop: "60px", color: "white" }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <TextField
              id="date"
              margin="normal"
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={(e) => startingDateToTimeStamp(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="date"
              margin="normal"
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={(e) => endingDateToTimeStamp(e.target.value)}
            />
          </Grid>
          <Button variant="contained" onClick={() => getListByDate()}>
            Search
          </Button>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card>
              <MaterialTable
                mt={10}
                title="Billing Report"
                columns={columns}
                data={data}
                options={{
                  headerStyle: {
                    size: "80px",
                    backgroundColor: "#1f283e",
                    color: "white",
                  },
                }}
                table={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      // handleRowUpdate(newData, oldData, resolve);
                      console.log("hello");
                    }),
                }}
              />
            </Card>
            <Box
              style={{
                textAlign: "right",
              }}
            ></Box>
            <Typography variant="h5">
              Total : {totalBillingAmount} BDT
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

export default UserRates;
