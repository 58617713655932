import React, { useEffect, useState,useCallback } from "react";
import { forwardRef } from "react";
// import Avatar from 'react-avatar';
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
// import axios from "axios";
import Alert from "@material-ui/lab/Alert";

import useIsMountedRef from 'hooks/useIsMountedRef';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import callApi from "Utils/callApi";
 
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import console from 'react-console'
import { useSnackbar } from "notistack";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = callApi;



const ApprovedWalletWithdrawRequest = () => {
    const { enqueueSnackbar } = useSnackbar();
    const isMountedRef = useIsMountedRef();
    const [data, setData] = useState([]); //table data

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
  
    var columns = [
      { title: "id", field: "doctorId", hidden: true },
        { title: "Doctor's Name", field: "doctorName" },
        { title: "Doctor's Email", field: "doctorEmail" },
        { title: "Doctor's Number", field: "doctorPhone" },
        { title: "Admin name", field: "adminName" },
        { title: "Admin's Email", field: "adminEmail" },
        { title: "Admin Number", field: "adminPhone" },
        { title: "Approved Date", field: "approvedDate" },
        { title: "Status", field: "withdrawStatus" },
        { title: "Recahrged Amount", field: "rechargedAmount" },
      
      
    ];
  
    const getApprovedWithdrawList = useCallback(() => {
        api.get("/withdraw/approved")
        .then((response) =>
        {
          const withdrawList = response.data.withdraw;
          console.log(withdrawList)
          let filteredWithdrawList =[]
          for(let i =0 ;i<withdrawList.length;i++){
            let pendingData = {
              doctorId :withdrawList[i].requestBy.userId,
              doctorEmail:withdrawList[i].requestBy.email,
              doctorName:withdrawList[i].requestBy.name,
              doctorPhone:withdrawList[i].requestBy.phone,
              adminEmail:withdrawList[i].approvedBy.adminEmail,
              adminPhone:withdrawList[i].approvedBy.adminEmail,
              remarks:withdrawList[i].remarks,
              rechargeAmount:withdrawList[i].amount,
              approvedDate:withdrawList[i].approvedDate,
              withdrawStatus:withdrawList[i].withdrawStatus,

            }
            filteredWithdrawList.push(pendingData);

          }        
          // setData(withdrawList)
          setData(filteredWithdrawList);
        }
        )
           .catch(error=>{
               console.log("Error", error)
           })
      }, [isMountedRef])
    
    
      useEffect(() => {
        getApprovedWithdrawList();
      }, [getApprovedWithdrawList]);
    




    return (
      <DashboardLayout>
      <DashboardNavbar/>
          <div className="App" style={{ marginTop: "60px" }}>
          <Grid container spacing={1}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
            <Card>
              <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Approved Withdraw Requests
                  </MDTypography>
                </MDBox>

            <div>
              {iserror && (
                <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <MaterialTable
              mt={90}
              title="Pending Requests"
              columns={columns}
              data={data}
              icons={tableIcons}
              options={{
                headerStyle: { size: "80px" },
              }}
            //   editable={{
            //     onRowUpdate: (newData, oldData) =>
            //       new Promise((resolve) => {
            //         handleRowUpdate(newData, oldData, resolve);
            //       }),

            //     onRowDelete: (oldData) =>
            //       new Promise((resolve) => {
            //         handleRowDelete(oldData, resolve);
            //       }),
            //   }}
            //   actions={
            //     [
            //       {
            //         icon: () => <button>Approve</button>,
            //         tooltip: "Approve",
            //         onClick: (e, data) => {
            //           console.log(data)
            //         }
            //       }
            
            //   ]}
            />
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      </DashboardLayout>

    );
};

export default ApprovedWalletWithdrawRequest;