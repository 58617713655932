import React, { useEffect, useState, useCallback } from 'react';
import { forwardRef } from 'react';
// import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid'
 
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
// import axios from 'axios'
import Alert from '@material-ui/lab/Alert';
import callApi from "Utils/./callApi";
import useIsMountedRef from 'hooks/useIsMountedRef';
import { useSnackbar } from "notistack";
import Card from "@mui/material/Card";
 
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
 
 
 
function validateEmail(email){
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

const AdminListTable = () => {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  var columns = [
    {title: "id", field: "id", hidden: true},
    {title: "Name", field: "name"},
    {title: "email", field: "email"},
    {title: "phone", field: "phone"}
]
  const [data, setData] = useState([
    // {
    // name: "Rashed",
    // email: "Rashed@1gmail.com",
    // phone:"01234567891",
    // id:1
    // },
    // {
    //   name: "Emon",
    //   email: "Emon@1gmail.com",
    //   phone:"01234567891",
    //   id:2
    // },
    // {
    //   name: "Rakib",
    //   email: "Rakib@1gmail.com",
    //   phone: "01234567891",
    //   id:3
    // },
    
    
  ]); //table data
     
      //for error handling
      const [iserror, setIserror] = useState(false)
      const [errorMessages, setErrorMessages] = useState([])
     
      // useEffect(() => { 
      //   fetch("http://localhost:5500/api/v1/admin/all",{headers})
      //       .then(res => { res.json()     
      //         console.log(res)         
      //           // setData(res.data)
      //        }).then((data) => console.log(data))
      //        .catch(error=>{
      //            console.log("Error", error)
      //        })
      // }, [])
      
      // useEffect(() => { 
      //   callApi.get("/admin/all")
      //     .then((response) =>
      //      { const adminList = response.data.admin;
      //       console.log(adminList)
      //       setData(adminList);
      //     }
      //     )
      //        .catch(error=>{
      //            console.log("Error", error)
      //        })
      // }, [])

      const getAdminList = useCallback(() => {
        callApi.get("/admin/all")
        .then((response) =>
         { const adminList = response.data.admin;
          console.log(adminList)
          setData(adminList);
        }
        )
           .catch(error=>{
               console.log("Error", error)
           })
      }, [isMountedRef])
    
      useEffect(() => {
        getAdminList();
      }, [getAdminList]);
      
      // useEffect(() => { 
      //   axios.post("/admin/all")
      //     .then((data) => console.log(data))
      //        .catch(error=>{
      //            console.log("Error", error)
      //        })
      // }, [])
     
  const handleRowUpdate = (newData, oldData, resolve) => {
        console.log(newData, oldData)
        console.log("/admin/update/"+newData._id)
        //validation
        let errorList = []
        if(newData.name === ""){
          errorList.push("Please enter name")
        }
        if(newData.phone === ""){
          errorList.push("Please enter phone")
        }
        if(newData.email === "" || validateEmail(newData.email) === false){
          errorList.push("Please enter a valid email")
        }
        if(errorList.length < 1){
          callApi.put("/admin/update/"+newData._id, newData)
          .then(() => {
            // const dataUpdate = [...data];
            // const index = oldData.tableData.id;
            // dataUpdate[index] = newData;
            // setData([...dataUpdate]);
            getAdminList()
            resolve()
            enqueueSnackbar("Admin Updated Successfully", {
              variant: "success",
              // action: <Button>See all</Button>
            });
            setIserror(false)
            setErrorMessages([])
          })
          .catch(() => {
            setErrorMessages(["Update failed! Server error"])
            setIserror(true)
            resolve()
             
          })
        }else{
          setErrorMessages(errorList)
          setIserror(true)
          resolve()
     
        }
         
      }
     
     
      const handleRowDelete = (oldData, resolve) => {
      //  return console.log(oldData)
        callApi.delete("/admin/delete/"+oldData._id)
          .then(() => {

            resolve()
            enqueueSnackbar("Admin Deleted Successfully", {
              variant: "success",
              // action: <Button>See all</Button>
            });
            getAdminList()
            setIserror(false)
            setErrorMessages([])
          })
          .catch(() => {
            setErrorMessages(["Delete failed! Server error"])
            setIserror(true)
            resolve()
          })
      }
    return (
        <div className="App" style={{marginTop:"60px"}}>
       <Grid container spacing={1}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Admin Table
                </MDTypography>
              </MDBox>
 
          <MDBox>
          <div>
            {iserror && 
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            }       
          </div>
            <MaterialTable mt={90}
              title="Admin List"
              columns={columns}
              data={data}
              icons={tableIcons}
              options={{
                headerStyle:{size:'80px'}
                }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                      handleRowUpdate(newData, oldData, resolve);
                       
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    handleRowDelete(oldData, resolve)
                  }),
              }}
            />
          </MDBox>
          </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
    </div>
    );
};

export default AdminListTable;