import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Container,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
// import { useDispatch } from 'react-redux';
import axios from "Utils/./callApi";
import wait from "Utils/./wait";
import { useSnackbar } from "notistack";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import callApi from "Utils/callApi";
const useStyles = makeStyles(() => ({
  root: {},
}));

const AddNewAdmin = (className, user, ...rest) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container
        maxWidth={false}
        style={{
          padding: "20px",
          background: "white",
          margin: "0 auto",
          width: "60%",
          borderRadius: "10px",
        }}
      >
        <h1>Add a new admin</h1>
        <hr />
        <Formik
          initialValues={{
            name: "",
            phone: "",
            email: "",
            password: "",
            roleType: "admin",
            isAdmin: true,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string("Enter your name").required("Name is required"),
            password: Yup.string().max(255).required("Password is required"),
            phone: Yup.string("Enter your Phone Number")
              .required("Phone Number is required")
              .test("test-name", "Enter Valid Phone", function (value) {
                const phoneRegex =
                  /^([0-9]{11,11}(-[0-9]*){0,1}|([\w-]+(?:\.[\w-]+)*)+@\w+\.\w{2,3})$/;
                let isValidPhone = phoneRegex.test(value);
                if (!isValidPhone) {
                  return false;
                }
                return true;
              }),
            email: Yup.string("Enter your Email"),
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              // console.log(values);
              const { name, phone, email, password } = values;
              console.log(name, phone, email, password);
              resetForm();
              // return console.log(values);

              await callApi.post("/registration", values).then((response) => {
                console.log(response);
              });
              await wait(500);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              enqueueSnackbar("Admin Added", {
                variant: "success",
                // action: <Button>See all</Button>
              });
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Box xs={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      autoFocus
                      margin="normal"
                      label="Full Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      autoFocus
                      helperText={touched.phone && errors.phone}
                      label=" Phone Number"
                      margin="normal"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.phone}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      autoFocus
                      helperText={touched.email && errors.email}
                      label=" Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}></Grid>
                </Grid>
              </Box>
              <Box flex={1} justifyContent="center" alignItems="center">
                <Grid xs={5}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Create Admin
                  </Button>
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
    </DashboardLayout>
  );
};
AddNewAdmin.propTypes = {
  className: PropTypes.string,
  // user: PropTypes.object.isRequired
};
export default AddNewAdmin;
