import { Container } from '@material-ui/core';
import React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ApprovedAndPendingXrayList from './ApprovedAndPendingXrayList';

const ApprovedAndPendingXray = () => {
    return (
        <DashboardLayout>
        <DashboardNavbar />
            <ApprovedAndPendingXrayList/>
        </DashboardLayout>
    );
};

export default ApprovedAndPendingXray;