import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "../../../Utils/./callApi";
import wait from "../../../Utils/./wait";
import { useSnackbar } from "notistack";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Autocomplete from "@mui/material/Autocomplete";
import callApi from "Utils/callApi";
import console from "react-console";
import useIsMountedRef from "hooks/useIsMountedRef";
import { useGridApiMethod } from "@mui/x-data-grid";

const useStyles = makeStyles(() => ({
  root: {},
}));

const clinics = [
  {
    clinicName: "Sheba Clinic",
    isActivated: true,
    requiredData: [],
  },
  {
    clinicName: "Popular Diagnostic",
    isActivated: true,
    requiredData: [],
  },
  {
    clinicName: "Lab Aid Diagnostic",
    isActivated: true,
  },
];

const AddWallet = ({ className, ...rest }) => {
  console.log("sdsddsad");
  const isMountedRef = useIsMountedRef();
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [wallet, setWallet] = useState([]);
  const [data, setData] = useState([]);
  const getWalletList = useCallback(() => {
    callApi
      .post("/find-wallet-by-id/" + loggedUser.userId, {
        name: loggedUser.name,
      })
      .then((response) => {
        const wallet = response.data.wallet;
        console.log(wallet);
        setWallet(wallet);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);

  const getClinicList = useCallback(() => {
    let list =[];
    callApi
      .post("/get-all-users", { roleType: loggedUser.roleType })
      .then((response) => {
        const clinicList = response.data.clinic;
        if (loggedUser.roleType == "admin") {
          Promise.all(
            clinicList.map((item) => {
              if (item.roleType !== "admin") {
                list.push(item);
              }
            })
          ).then(() => {
            setData(list);
          });
        } else {
          setData(clinicList);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);
  useEffect(() => {
    getClinicList();
    getWalletList()
  }, [getClinicList]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container
        maxWidth={false}
        style={{
          padding: "20px",
          background: "white",
          margin: "0 auto",
          width: "100%",
          borderRadius: "10px",
        }}
      >
        <div className={clsx(classes.root, className)} {...rest}>
          <Formik
            initialValues={{
              name: "",
              amount: "",
              confirmAmount: "",
              remarks: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string("Enter Clinic Name").required("Select clinic"),
              userId: Yup.string().required(),

              amount: Yup.number("Enter amount to recharge").required(
                "Enter amount"
              ),
              confirmAmount: Yup.number()
                .required(
                  "Confirm recharge amount must match with recharge amount"
                )
                .positive("number can't start with a minus")
                .oneOf(
                  [Yup.ref("amount"), null],
                  "Confirm recharge amount must match with  recharge amount"
                ),
              remarks: Yup.string().required("Add e remarks"),
            })}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
              try {
                console.log(values);
                console.log(values.clinicInfo);
                const { userId, name, amount, confirmAmount, remarks } = values;
                // return console.log(name, amount, confirmAmount, remarks);
                await axios
                  .post("/wallet/update/" + userId, {
                    name,
                    amount,
                    confirmAmount,
                    remarks,
                    createdByUserId: loggedUser.userId,
                    roleType: loggedUser.roleType,
                  })
                  .then((response) => {
                    console.log(response);
                  });
                await wait(500);
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar("Balance Added Successfully", {
                  variant: "success",
                  // action: <Button>See all</Button>
                });
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              initialValues,
              setFieldValue,
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                {" "}
                <div>
                  <h1>Add wallet</h1>
                </div>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="doctor"
                        name="clinicInfo"
                        options={data}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        // sx={{ width: 300 }}
                        onChange={(e, value) => {
                          setFieldValue(
                            "userId",
                            value !== null ? value.userId : initialValues.userId
                          );
                          setFieldValue(
                            "name",
                            value !== null ? value.name : initialValues.name
                          );
                          console.log("name", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select User"
                            variant="outlined"
                            placeholder="Search User"
                            value={values?.name}
                            onChange={handleChange}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box xs={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.amount && errors.amount)}
                        helperText={touched.amount && errors.amount}
                        margin="normal"
                        label="Amount"
                        name="amount"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);

                          if (
                            parseFloat(e.target.value) > wallet.main_balance && loggedUser.roleType !=="superAdmin"
                          ) {
                            enqueueSnackbar(
                              "Cannot be greater than main balance",
                              {
                                variant: "error",
                                // action: <Button>See all</Button>
                              }
                            );
                            setFieldValue("amount", wallet.main_balance);
                          } else {
                            setFieldValue("amount", e.target.value);
                          }
                        }}
                        value={values.amount}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box xs={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(
                          touched.confirmAmount && errors.confirmAmount
                        )}
                        helperText={
                          touched.confirmAmount && errors.confirmAmount
                        }
                        margin="normal"
                        label="Confirm amount"
                        name="confirmAmount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmAmount}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box xs={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <TextField
                        error={Boolean(touched.remarks && errors.remarks)}
                        helperText={touched.remarks && errors.remarks}
                        fullWidth
                        autoFocus
                        label="Remarks"
                        margin="normal"
                        name="remarks"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // type="number"
                        value={values.remarks}
                        variant="outlined"
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box flex={1} justifyContent="center" alignItems="center">
                  <Grid xs={5}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Add Balance
                    </Button>

                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        </div>
      </Container>
    </DashboardLayout>
  );
};
AddWallet.propTypes = {
  className: PropTypes.string,
};
export default AddWallet;
