import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,

  FormHelperText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

import wait from "Utils/./wait";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import callApi from "Utils/callApi";
import { useCookies } from "react-cookie";
 
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

const useStyles = makeStyles(() => ({
  root: {},
}));

const WithdrawRequestDoctor = (className, ...rest ) => {
    const classes = useStyles( );
    const [cookies] = useCookies({});
  let loggedUser = JSON.parse(localStorage.getItem('loggedUser'))

  const { name ,userId,email,phone } = loggedUser;
  return (
    <DashboardLayout>
      <DashboardNavbar/>


        <MDBox>
        <div style ={{padding:"20px" ,background:'white',margin:'0 auto', width:'70%', borderRadius:'10px'}} className={clsx(classes.root, className)} {...rest}>
            
        <Formik
          initialValues={{
            amount: "",
            confirmAmount: "",
            remarks: '',
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.number("Enter Amount").required("Enter Amount"),
            confirmAmount:Yup.number("Enter amount to withdraw").required("Confirm amount must match with amount"),
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              console.log(values);
              const { amount,
                confirmAmount,
                remarks, } = values;
              console.log(amount,
                confirmAmount,
                remarks,);
              await callApi
                .post("/withdraw/new", {
                  // apiKey: account.user.apiKey,
                  // serial: serial,
                  amount,
                  remarks,
                  requestBy:{
                    name,
                    userId,phone,email
                  }
                })
                .then((response) => {
                  console.log(response);
                }); // Make API request
              await wait(500);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              //   enqueueSnackbar('User updated', {
              //     variant: 'success',
              //     action: <Button>See all</Button>
              //   });
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              
              <Box xs={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoFocus
                      error={Boolean(touched.amount && errors.amount)}
                      margin="normal"
                      label="Amount"
                      name="amount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.amount}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoFocus
                      error={Boolean(
                        touched.confirmAmount && errors.confirmAmount
                      )}
                      margin="normal"
                      label="Confirm amount"
                      name="confirmAmount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmAmount}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box xs={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      autoFocus
                      error={Boolean(
                        touched.remarks && errors.remarks
                      )}
                      margin="normal"
                      label="Remarks"
                      name="remarks"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.remarks}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box flex={1} justifyContent="center" alignItems="center">
                <Grid xs={5}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Make Request
                  </Button>
  
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </div></MDBox>

    </DashboardLayout>

    );
};
WithdrawRequestDoctor.propTypes = {
    className: PropTypes.string,
  };
export default WithdrawRequestDoctor;