import React, { useEffect, useState,useCallback } from "react";
import { forwardRef } from "react";
// import Avatar from 'react-avatar';
import Grid from "@material-ui/core/Grid";
import { useCookies } from 'react-cookie';
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
// import axios from "axios";
import Alert from "@material-ui/lab/Alert";

import useIsMountedRef from 'hooks/useIsMountedRef';
import Card from "@mui/material/Card";
import callApi from "Utils/callApi";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useSnackbar } from "notistack";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = callApi
  
function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

const WithdrawRequestDoctorTableList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  var columns = [
    { title: "id", field: "doctorId", hidden: true },
    { title: "Apply Date", field: "applyDate" },
    { title: "Doctor", field: "doctorName" },
    { title: "Status", field: "withdrawStatus" },
    { title: "Amount", field: "amount" },
    { title: "Remarks", field: "remarks" },
    { title: "userId", field: "userId"  , hidden:true},
  ];
  const [data, setData] = useState([]); //table data
  const [cookies] = useCookies({});
  let loggedUser = JSON.parse(localStorage.getItem('loggedUser'))

  const {  name, email,roleType } = loggedUser;
  let doctorId;
  if(roleType === 'doctor'){
    doctorId = loggedUser.userId
  }
  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const getPendingWithdrawList = useCallback(() => {
    api.post("/withdraw/pending" , {
      roleType:roleType
    })
    .then((response) =>
     
    {
      let list = []
      const withdrawList = response.data.withdraw;
      withdrawList.map((item)=>{
        let object = {
          id:item._id,
          applyDate:item.appliedDate,
          doctorName:item.requestBy.name,
          withdrawStatus:item.withdrawStatus,
          amount:item.amount,
          remarks:item.remarks,
          userId:item.requestBy.userId,
        }
        list.push(object);
      })
      console.log(withdrawList)
      setData(list);
    }
    )
       .catch(error=>{
           console.log("Error", error)
       })
  }, [isMountedRef])


  useEffect(() => {
    getPendingWithdrawList();
  }, [getPendingWithdrawList]);


  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = [];
    if (newData.first_name === "") {
      errorList.push("Please enter first name");
    }
    if (errorList.length < 1) {
      api
        .put("/withdraw/update/" + newData._id, newData)
        .then((res) => {
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch((error) => {
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    api
      .delete("/withdraw/delete/"+oldData._id)
      .then((res) => {

        resolve();
        enqueueSnackbar("Admin Deleted Successfully", {
          variant: "success",
          // action: <Button>See all</Button>
        });
        getPendingWithdrawList()
        setIserror(false)
        setErrorMessages([])
      })
      .catch((error) => {
        setErrorMessages(["Delete failed! Server error"]);
        setIserror(true);
        resolve();
      });
  };
  return (
    <DashboardLayout>
    <DashboardNavbar/>
        <div className="App" style={{ marginTop: "60px" }}>
        <Grid container spacing={1}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
          <Card>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                 Pending Withdraw Table
                </MDTypography>
              </MDBox>
            <div>
              {iserror && (
                <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <MaterialTable
              mt={90}
              title="Pending Requests"
              columns={columns}
              data={data}
              icons={tableIcons}
              options={{
                headerStyle: { size: "80px" },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    handleRowUpdate(newData, oldData, resolve);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    handleRowDelete(oldData, resolve);
                  }),
              }}
              actions={
                [
                  {
                    icon: () => <button>Approve</button>,
                    tooltip: "Approve",
                    onClick: (e, data) => {

                      const updateData = {
                        adminName: name,
                        adminId: loggedUser.userId,
                        withdrawStatus: "Approved",
                        doctorId: data.userId,

                      }
                      callApi.put(`/withdraw/update/${data.id}`, {updateData})
                          .then((response => {
                            enqueueSnackbar("Withdraw Approved! Reload Please!", {
                              variant: "success", 
                            });
                            getPendingWithdrawList()
                          }))
                          .catch((err) => {
                            enqueueSnackbar("Some thing is wrong!", {
                              variant: "error", 
                            });
                          })
                    }
                  }
            
              ]}
            />
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      </DashboardLayout>
    );
};

export default WithdrawRequestDoctorTableList;
