/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import Icon from "@mui/material/Icon";

import {
  Box,
  Container,
  FormHelperText,
  InputLabel,
  makeStyles,
  NativeSelect,
  TextField,
} from "@material-ui/core";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import wait from "Utils/wait";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import React, { useEffect, useState, useCallback } from "react";
import callApi from "Utils/callApi";
import console from "react-console";
import useIsMountedRef from "hooks/useIsMountedRef";
import { Divider, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
const useStyles = makeStyles(() => ({
  root: {},
}));
const Billing = ({ className, ...rest }) => {
  const [data, setData] = useState({
    main_balance: 0,
    xray_balance: 0,
    mri_balance: 0,
    ct_balance: 0,
  });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const isMountedRef = useIsMountedRef();
  const [isOpen, openModal] = React.useState(false);
  const closeModal = (event) => openModal(false);
  const [isOpenRequest, openRequestModal] = React.useState(false);
  const closeRequestModal = (event) => openRequestModal(false);
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const { name, userId, email, phone } = loggedUser;
  const exchangeType = [
    {
      name: "Xray",
      value: "xray",
    },
    {
      name: "MRI",
      value: "mri",
    },
    {
      name: "CT Scan",
      value: "ct",
    },
  ];
  const getClinicList = useCallback(() => {
    callApi
      .post("/find-wallet-by-id/" + loggedUser.userId, {
        name: loggedUser.name,
      })
      .then((response) => {
        const wallet = response.data.wallet;
        console.log(wallet);
        setData(wallet);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);
  useEffect(() => {
    getClinicList();
  }, [getClinicList]);
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>

        <MDBox mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12} xl={12}>
                  <Grid container sx={{ background: "white", p: 5 }}>
                    <Grid item xs={12}>
                      <Grid container xs={12}>
                        <Grid item xs={8}>
                          <Typography component="h4" variant="body">Main Balance : <span style={{ fontSize: 30, color: "green" }}> {data?.main_balance || 0}</span> BDT</Typography>
                          <Divider />

                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            color="primary"
                            fullWidth
                            size="large"
                            type="submit"
                            style={{
                              borderRadius: 10,
                              backgroundColor: "white",
                              color: "black",
                              padding: "18px",
                              fontSize: "15px",
                              marginTop: "10px"
                            }}
                            variant="contained"
                            onClick={() => {
                              openModal(true);
                            }}
                          >
                            <Icon fontSize="small">euro</Icon>Exchange Balance
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            style={{
                              borderRadius: 10,
                              backgroundColor: "black",
                              padding: "18px",
                              fontSize: "15px",
                              marginTop: "10px"
                            }}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={() => {
                              openRequestModal(true);
                            }}
                          >
                            <Icon fontSize="small">euro</Icon> Withdraw Request
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography component="h5" variant="div">Xray Rate :
                        <span style={{ fontSize: 20, color: "green" }}> {data?.xray_rate?.general}</span>
                        <sup style={{ fontSize: 10 }}> (General)</sup> ,
                        <span style={{ fontSize: 20, color: "skyblue" }}> {data?.xray_rate?.emergency}</span>
                        <sup style={{ fontSize: 10 }}> (Emergency)</sup>
                      </Typography>
                      <Typography component="h5" variant="div">MRI Rate :
                        <span style={{ fontSize: 20, color: "green" }}> {data?.mri_rate?.general}</span>
                        <sup style={{ fontSize: 10 }}> (General)</sup> ,
                        <span style={{ fontSize: 20, color: "skyblue" }}> {data?.mri_rate?.emergency}</span>
                        <sup style={{ fontSize: 10 }}> (Emergency)</sup>
                      </Typography>
                      <Typography component="h5" variant="div">CT Rate :
                        <span style={{ fontSize: 20, color: "green" }}> {data?.ct_rate?.general}</span>
                        <sup style={{ fontSize: 10 }}> (General)</sup> ,
                        <span style={{ fontSize: 20, color: "skyblue" }}> {data?.ct_rate?.emergency}</span>
                        <sup style={{ fontSize: 10 }}> (Emergency)</sup>
                      </Typography>
                      <Typography component="h5" variant="div">Hematology Rate :
                        <span style={{ fontSize: 20, color: "green" }}> {data?.hematology_rate?.general}</span>
                        <sup style={{ fontSize: 10 }}> (General)</sup> ,
                        <span style={{ fontSize: 20, color: "skyblue" }}> {data?.hematology_rate?.emergency}</span>
                        <sup style={{ fontSize: 10 }}> (Emergency)</sup>
                      </Typography>
                    </Grid>

                  </Grid>

                </Grid>

              </Grid>
            </Grid>
          </Grid>

        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Transactions />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >
        <Container
          style={{
            padding: "20px",
            background: "white",
            margin: "0 auto",
            width: "100px",
            borderRadius: "10px",
          }}
        >
          <div className={clsx(classes.root, className)} {...rest}>
            <Formik
              initialValues={{
                exchangeType: "",
                amount: 0,
                confirmAmount: "",
                remarks: "",
              }}
              validationSchema={Yup.object().shape({
                exchangeType: Yup.string("Select Exchange Type").required(
                  "Select Type"
                ),

                amount: Yup.number("Enter amount to recharge").required(
                  "Enter amount"
                ),
                confirmAmount: Yup.number()
                  .required(
                    "Confirm recharge amount must match with recharge amount"
                  )
                  .positive("number can't start with a minus")
                  .oneOf(
                    [Yup.ref("amount"), null],
                    "Confirm recharge amount must match with  recharge amount"
                  ),
              })}
              onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  console.log(values);
                  console.log(values.clinicInfo);
                  const { amount, exchangeType, remarks } = values;
                  // return console.log(name, amount, confirmAmount, remarks);
                  await callApi
                    .post("/wallet-update-by-userId", {
                      userId: loggedUser.userId,
                      amount,
                      exchangeType,
                      remarks,
                    })
                    .then((response) => {
                      console.log(response);
                    });
                  await wait(500);
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                  enqueueSnackbar("Balance Added Successfully", {
                    variant: "success",
                    // action: <Button>See all</Button>
                  });
                } catch (error) {
                  setStatus({ success: false });
                  setErrors({ submit: error.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                initialValues,
                setFieldValue,
              }) => (
                <Form
                  className={clsx(classes.root, className)}
                  onSubmit={handleSubmit}
                  {...rest}
                >
                  {" "}
                  <div>
                    <h1>Exchange Balance</h1>
                  </div>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="doctor"
                          name="clinicInfo"
                          options={exchangeType}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          // sx={{ width: 300 }}
                          onChange={(e, value) => {
                            setFieldValue(
                              "exchangeType",
                              value !== null
                                ? value.value
                                : initialValues.exchangeType
                            );
                            console.log("name", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="For"
                              variant="outlined"
                              placeholder="Select type"
                              value={values?.name}
                              onChange={handleChange}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box xs={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          autoFocus
                          error={Boolean(touched.amount && errors.amount)}
                          helperText={touched.amount && errors.amount}
                          margin="normal"
                          label="Amount"
                          name="amount"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.amount}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box xs={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          autoFocus
                          error={Boolean(
                            touched.confirmAmount && errors.confirmAmount
                          )}
                          helperText={
                            touched.confirmAmount && errors.confirmAmount
                          }
                          margin="normal"
                          label="Confirm amount"
                          name="confirmAmount"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirmAmount}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box xs={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.remarks && errors.remarks)}
                          helperText={touched.remarks && errors.remarks}
                          fullWidth
                          autoFocus
                          label="Remarks"
                          margin="normal"
                          name="remarks"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // type="number"
                          value={values.remarks}
                          variant="outlined"
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box flex={1} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Add Balance
                      </Button>

                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Modal>
      <Modal
        open={isOpenRequest}
        onClose={closeRequestModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered

      >
        <MDBox>
          <div
            style={{
              padding: "20px",
              background: "white",
              margin: "0 auto",
              width: "70%",
              borderRadius: "10px",
            }}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Formik
              initialValues={{
                amount: 0,
                confirmAmount: "",
                remarks: "",
              }}
              validationSchema={Yup.object().shape({
                amount: Yup.number("Enter Amount").required("Enter Amount"),
                confirmAmount: Yup.number("Enter amount to withdraw").required(
                  "Confirm amount must match with amount"
                ),
              })}
              onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  console.log(values);
                  const { amount, confirmAmount, remarks } = values;
                  console.log(amount, confirmAmount, remarks);
                  await callApi
                    .post("/withdraw/new", {
                      // apiKey: account.user.apiKey,
                      // serial: serial,
                      amount,
                      remarks,
                      requestBy: {
                        name,
                        userId,
                        phone,
                        email,
                      },
                    })
                    .then((response) => {
                      console.log(response);
                    }); // Make API request
                  await wait(500);
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                  //   enqueueSnackbar('User updated', {
                  //     variant: 'success',
                  //     action: <Button>See all</Button>
                  //   });
                } catch (error) {
                  setStatus({ success: false });
                  setErrors({ submit: error.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form
                  className={clsx(classes.root, className)}
                  onSubmit={handleSubmit}
                  {...rest}
                >
                  <Box xs={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          autoFocus
                          error={Boolean(touched.amount && errors.amount)}
                          margin="normal"
                          label="Amount"
                          name="amount"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            console.log(e.target.value);
                            if (
                              parseFloat(e.target.value) > data.main_balance
                            ) {
                              enqueueSnackbar(
                                "Cannot be greater than main balance",
                                {
                                  variant: "error",
                                  // action: <Button>See all</Button>
                                }
                              );
                              setFieldValue("amount", data.main_balance);
                            } else {
                              setFieldValue("amount", e.target.value);
                            }
                          }}
                          value={values.amount}
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          autoFocus
                          error={Boolean(
                            touched.confirmAmount && errors.confirmAmount
                          )}
                          margin="normal"
                          label="Confirm amount"
                          name="confirmAmount"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirmAmount}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box xs={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          autoFocus
                          error={Boolean(touched.remarks && errors.remarks)}
                          margin="normal"
                          label="Remarks"
                          name="remarks"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.remarks}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box flex={1} justifyContent="center" alignItems="center">
                    <Grid xs={5}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Make Request
                      </Button>

                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </MDBox>
      </Modal>
    </DashboardLayout>
  );
};

export default Billing;
