/* eslint-disable max-len */
import React, {  useCallback, useEffect,useState } from "react";
import { Button, TextField, makeStyles, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { forwardRef } from "react";
// import Avatar from 'react-avatar';
import { useCookies } from "react-cookie";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useSnackbar } from "notistack";
import Alert from "@material-ui/lab/Alert";
import callApi from "Utils/callApi";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import useIsMountedRef from "hooks/useIsMountedRef";
import console from "react-console"
import { getFormattedDate } from "Utils/common-computes";
import MDTypography from "components/MDTypography";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
// const api = axios.create({
//   baseURL: `http://localhost:5500/api/v1`,
// });
const useStyles = makeStyles((theme) => ({
  root: {},
  // queryField: {
  //   width: 500
  // },
  bulkOperations: {
    position: "relative",
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: "absolute",
    width: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
}));
const ClinicWallettRechargeList = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [users, setUsers] = useState(null);
  const[user ,  setUser] = useState(null);
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [totalDeductionForViewingXray ,setTotalDeductionForViewingXray]=useState('')
  const [totalDeductionForUpladingXray ,settotalDeductionForUpladingXray]=useState('')
  const [totalDeductionForUpladingXrayCounter ,settotalDeductionForUpladingXrayCounter]=useState('')
  const [totalBalanceAddition ,settotalBalanceAddition]=useState('')
  const [totalBalanceAdditionCounter ,settotalBalanceAdditionCounter]=useState('')
  const [totalDeductionForViewingXrayCounter ,settotalDeductionForViewingXrayCounter]=useState('')
  const [totalBalanceForWithdraw ,settotalBalanceForWithdraw]=useState('')
  const [totalBalanceForWithdrawCounter ,settotalBalanceForWithdrawCounter]=useState('')
  useEffect(() => {
    getAllTransactions();
    getClinicList();
  }, []);
  const getClinicList = useCallback(() => {
    let list =[];
    callApi
      .post("/get-all-users", { roleType: loggedUser.roleType })
      .then((response) => {
        const clinicList = response.data.clinic;
        setUsers(clinicList);
        
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);
  const getAllTransactions = useCallback((searchQuery ={}) => {
    callApi
      .post("/get-transactions", {searchQuery})
      .then((response) => {
        let reportList =response.data.transactions.list;
        setTotalDeductionForViewingXray(response.data.transactions.totalDeductionForViewingXray)
        settotalDeductionForUpladingXray(response.data.transactions.totalDeductionForUpladingXray)
        settotalDeductionForUpladingXrayCounter(response.data.transactions.totalDeductionForUpladingXrayCounter)
        settotalBalanceAddition(response.data.transactions.totalBalanceAddition)
        settotalBalanceAdditionCounter(response.data.transactions.totalBalanceAdditionCounter)
        settotalDeductionForViewingXrayCounter(response.data.transactions.totalDeductionForViewingXrayCounter)
        settotalBalanceForWithdraw(response.data.transactions.totalBalanceForWithdraw)
        settotalBalanceForWithdrawCounter(response.data.transactions.totalBalanceForWithdrawCounter)
        console.log(reportList);
        reportList = reportList.sort((a, b) => {
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        });
        setData(reportList);
        if (reportList.length === 0) {
          enqueueSnackbar("0 Reports found", {
            variant: "success",
            // action: <Button>See all</Button>
          });
        } else {
          enqueueSnackbar(`${reportList.length} Reports found`, {
            variant: "success",
            // action: <Button>See all</Button>
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);
  var columns = [
    { title: "id", field: "doctorId", hidden: true },
    { title: "Clinic Name", field: "name" },
    {
      title: "Amount", field: "amount",
      render: (rowData) => {
        if (rowData.type == 'add') {
          return (<MDTypography variant="button" color="success" fontWeight="medium" textGradient>
            +{rowData.amount}
          </MDTypography>)
        }
        else {
          return (<MDTypography variant="button" color="error" fontWeight="medium" textGradient>
            -{rowData.amount}
          </MDTypography>)
        }
      }
    },
    {
      title: "Date Time", field: "createdAt", render: (rowData) => {
        return (<MDTypography>
          {getFormattedDate(rowData.createdAt)}
        </MDTypography>)
      }
    },
    { title: "Remarks", field: "notes" },
  ];
  let searchQuery = {
    startDate,
    endDate,
  };
  const startingDateToTimeStamp = (queryDate) => {
    var tempDate = queryDate.split("/");
    console.log(queryDate,'test')
    var convertedDate = new Date(tempDate).getTime();
    searchQuery.startDate = convertedDate;
    setStartDate(convertedDate ? convertedDate : null);
    console.log({ startDate });
  };

  const endingDateToTimeStamp = (queryDate) => {
    const tempDate = queryDate.split("/");
    const convertedDate = new Date(tempDate).getTime() + 60 * 60 * 24 * 1000;
    console.log("convertedDate", convertedDate);
    searchQuery.endDate = convertedDate;
    console.log("convertedDatess", convertedDate);

    setEndDate(convertedDate ? convertedDate : null);
  };


 
  const clinics = [
    {
      accountExpiresOnDate: 1668307091074,
      balance: 0,
      name: "Sheba",
      _id: "616d4d688245f246cc7ff3f4",
    },
  ];
  const boxStyle ={
    display :"flex",flexDirection:"column" , justifyContent:"start" , background:"black",color:"white" , padding:20 , borderRadius:"10px"
  }
  // console.log(searchQuery)
  const [cookies] = useCookies({});
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

  const { name, email } = loggedUser;
  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  // const handleChange = (e , value) => {
  //   console.log(value)
  //   setClinic(value);
  //   console.log(clinic)
  // };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox xs={{ flexGrow: 1 }}>
        <div className="App" style={{ marginTop: "60px" }}>
        <MDBox mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={3} xl={3}>
                  <MDBox style={boxStyle}>
                    <text>Total Uploaded Xray : {totalDeductionForUpladingXrayCounter}</text>
                    <text>Total Transacted : {totalDeductionForUpladingXray}</text>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                <MDBox style={boxStyle}>
                    <text>Total Viewed Xray : {totalDeductionForViewingXrayCounter}</text>
                    <text>Total Transacted : {totalDeductionForViewingXray}</text>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                <MDBox style={boxStyle}>
                    <text>Total Withdraw : {totalBalanceForWithdrawCounter}</text>
                    <text>Total Transacted : {totalBalanceForWithdraw}</text>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                <MDBox style={boxStyle}>
                    <text>Total Transfer Balance : {totalBalanceAdditionCounter}</text>
                    <text>Total Transacted : {totalBalanceAddition}</text>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
          <Grid
            container
            spacing={1}
            style={{
              padding: "20px",
              background: "white",
              margin: "0 auto",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <Grid item xs={3}>
              <Autocomplete
                id="tags-standard"
                options={users}
                getOptionLabel={(option) => option.name}
                onChange={(_event, value) => {
                  setUser(value.userId);
                }}
                // onInputChange={(e)=>console.log(e.target.value)}
                renderInput={(params) => (
                  <TextField
                    className={classes.queryField}
                    {...params}
                    label="Search Clinic"
                    placeholder="Search Clinic"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="date"
                margin="normal"
                label="Start Date"
                type="date"
                
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onChange={(e) => startingDateToTimeStamp(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="date"
                margin="normal"
                label="End Date"
                type="date"
                defaultValue={new Date()}

                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onChange={(e) => endingDateToTimeStamp(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                onClick={() => {
                  const query = {
                    user:user,
                    startDate: startDate,
                    endDate: endDate,
                  };
                  console.log({ query });
                  getAllTransactions(query)

                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          <MDBox top={0} display="flex">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div>
                  {iserror && (
                    <Alert severity="error">
                      {errorMessages.map((msg, i) => {
                        return <div key={i}>{msg}</div>;
                      })}
                    </Alert>
                  )}
                </div>
                <MaterialTable
                  mt={90}
                  title="Logs Table"
                  columns={columns}
                  data={data}
                  icons={tableIcons}
                  options={{
                    headerStyle: { size: "80px" },
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
        </div>
      </MDBox>
    </DashboardLayout>
  );
};

export default ClinicWallettRechargeList;
