import React, { useState ,useEffect,useCallback } from "react";
import { styled } from "@mui/material/styles";


import Button from "@mui/material/Button";
import clsx from "clsx";
import {
  Box,
 
  FormHelperText,
  Grid,
  
  makeStyles,

  TextField,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import callApi from "Utils/callApi";
import MDInput from "components/MDInput";
import console from "react-console"
// import axios from "../../../Utils/./callApi";


 
const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  root: {},
}));
const initialValues = {
  repDoctor: { name: "", id: null, phone: null, designation: "" },
  refDoctor: { name: "", designation: "" },
  assistantDoctor: {
    name: "",
    id: null,
    phone: null,
  },
  remarks: "",
  file: "",
  patientInfo: {
    name: "",
    phone: "",
    id: "",
    age: "",
    address: "",
    gender: "",
  },
  status:"Pending"
};


const ReportData = ({ className, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const[patients,setPatients] = useState([]);
  const[doctors,setDoctors] = useState([]);
  const[assistantDoctors,setDoctorAssistant] = useState([]);
  const [data, setData] = useState([])
  const getClinicList = useCallback(() => {
    callApi.get("/clinic/all")
    .then((response) =>
     { const clinicList = response.data.clinic;
      setData(clinicList);
    }
    )
       .catch(error=>{
           console.log("Error", error)
       })
  }, [])
  useEffect(()=>{
    getClinicList()
  },[getClinicList])
  useEffect(()=>{
    callApi.get("/doctor/getData")
    .then((response) =>
     { 
      const patientsData = response.data.users;
      const doctorsData = response.data.doctors;
      const assistantDoctorsData = response.data.doctorAssistant;
      console.log(patientsData)
      setPatients(patientsData);
      setDoctors(doctorsData);
      setDoctorAssistant(assistantDoctorsData);
    }
    )
  },[])


  const FILE_SIZE = 1024 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const handleReportUpload = async (file) => {
    setSelectedFile(file)
      // callApi.post('/report/new', file)
      //   .then(res => {
      //     console.log("response", res)
      //   }) 
  }
 
  return (

    <div style ={{padding:"20px" ,background:'white'}}className={clsx(classes.root, className)} {...rest}>
      
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          clinicInfo:Yup.object().shape({
            name: Yup.string().required("Patient name is required"),
            phone: Yup.string().required("patient phone number is required"),
            dateOfbirth: Yup.string().required("patient age is required"),
            gender: Yup.string().required("patient gender is required"),
            address: Yup.string().required("patient adressis required"),
          }),
          patientInfo:Yup.object().shape({
            name: Yup.string().required("Patient name is required"),
            phone: Yup.string().required("patient phone number is required"),
            dateOfbirth: Yup.string().required("patient age is required"),
            gender: Yup.string().required("patient gender is required"),
            address: Yup.string().required("patient adressis required"),
          }),
          remarks: Yup.string("Add Remarks").required("Add Remark"),
          description: Yup.string("Add Description")
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting, setFieldValue }
        ) => {
          try {
            console.log({ values });
            // console.log(values.reportfile);
            const data =new FormData();
            let apiName = ''
            let files = values.reportfile;
            if(values.reportfile.length > 1){
              apiName = 'compressedReport'
              data.append('reportfile',values.reportfile)

            }
            else{
              data.append('reportfile',values.reportfile[0])
              apiName = 'report'
            }
            data.append('data',JSON.stringify(values))
            callApi.post(`/${apiName}/new`, data,{
              onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 50;
                setProgress(progress);
              },
            })
            .then(res => {
              console.log("response", res)
              enqueueSnackbar("Repport  Uploaded Successfully", {
                variant: "success",
                // action: <Button>See all</Button>
              });
              setErrors(false)

            }) 


          } catch (error) {
            console.log({ error });

            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <Form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <label htmlFor="contained-button-file">

                    <Input 
                      id="contained-button-file"
                      multiple 
                      name="reportfile"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      error={Boolean(touched.file && errors.file)}
                      helpertext={touched.file && errors.file}
                      color="success"
                      onChange={(e) => {
                        setFieldValue('reportfile',e.target.files);
                      }}
                      // onChange={(event, values) => {
                      //   setFieldValue("file", event.currentTarget.files[0]);
                      // }}
                    />
                    <div></div>
                    <Button
                      
                      component="span"
                      size="large"
                      error={Boolean(touched.file && errors.file)}
                      helperText={touched.file && errors.file}
                    >
                      Upload Report
                    </Button>

                      {values.reportfile?.filename}

                  </label>
                </Grid>
              </Grid>
            </Box>
            <Box xs={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>

                <Autocomplete
                    size="small"
                    disablePortal
                    id="doctor"
                    name="clinicInfo.name"
                    options={data}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    // sx={{ width: 300 }}
                    onChange={(e, value) => {
                      setFieldValue(
                        "clinicInfo",
                        value !== null ? value : initialValues.clinicInfo
                      );
                      console.log("clinicInfo", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Clinic"
                        variant="outlined"
                        placeholder="Search Clinic"
                        value={values?.name}

                        onChange={handleChange}
                        error={Boolean(
                          touched.name && errors.name
                        )}
                        helperText={
                          touched.name && errors.name
                        }
                      />
                    )}
                  />
                </Grid>


              </Grid>
            </Box>
            <Box xs={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
              <Grid item xs={5}>
              <Autocomplete
                fullWidth
                size="large"
                disablePortal
                name="patientInfo.name"
                id="user"
                options={patients}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                // sx={{ width: 510 }}
                onChange={(e, value) => {
                  setFieldValue(
                    "patientInfo",
                    value !== null ? value : initialValues.patientInfo
                  );
                  console.log(value);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select / Search Patient"
                    variant="outlined"
                    placeholder="Search PatientInfo"
                    value={values?.patientInfo.name}
                    
                    error={Boolean(
                      touched.patientInfo?.name && errors.patientInfo?.name
                    )}
                    helperText={
                      touched.patientInfo?.name && errors.patientInfo?.name
                    }
                    // error={Boolean( getIn (touched," assistantDoctor?.name") && getIn (errors, "assistantDoctor?.name"))}
                    // helperText={ getIn (touched," assistantDoctor?.name") && getIn (errors, "assistantDoctor?.name")}
                  />
                )}
              />
            </Grid>
              </Grid>
            </Box>
            <Box xs={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    error={Boolean(touched.remarks && errors.remarks)}
                    helperText={touched.remarks && errors.remarks}
                    fullWidth
                    autoFocus
                    label="Remarks"
                    margin="normal"
                    
                    name="remarks"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="number"
                    value={values.remarks}
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    fullWidth
                    autoFocus
                    label="Description"
                    margin="normal"
                    
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="number"
                    value={values.description}
                    variant="outlined"
                    multiline
                  />
                </Grid>
              </Grid>
            </Box>

            <Box flex={1} justifyContent="center" alignItems="center">
              <Grid xs={5}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                
                  type="submit"
                  variant="contained"
                >
                  Upload Report
                </Button>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReportData;

// error={Boolean( getIn (touched," repDoctor.name") && getIn (errors, "repDoctor.name"))}
// helperText={ getIn (touched," repDoctor.name") && getIn (errors, "repDoctor.name")}
// onChange={(event, values) => {
//   setFieldValue("file", event.currentTarget.files[0]);
// }}
