import React, { useRef, useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import console from "react-console";
import { useSnackbar } from "notistack";

import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik } from "formik";
import * as Yup from "yup";
import { ReportTemplates } from "./templates";
import callApi from "Utils/callApi";

import { render } from "nprogress";
import { authUser } from "Utils/authUser";
const config = {
  buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
  uploader: {
    insertImageAsBase64URI: true,
  },
};

const ReportsEditor = ({
  initialValue,
  getValue,
  dcmViewer,
  classes,
  color,
}) => {
  const editor = useRef(null);
  const [data, setData] = useState(ReportTemplates);
  const [currentStatus, setCurrentStatus] = useState("Final");
  const [currentTemplate, setCurrentTemplate] = useState("");
  const [initialReport, setInitialReport] = useState("");
  const [initialStudy, setInitialStudy] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  let xrayData = JSON.parse(localStorage.getItem("dicomfile"));
  console.log({ xrayData })
  const [doctor,setDoctor]= useState({})
  useEffect(() => {
    let user = authUser();
    setDoctor(user)
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (xrayData.report?.reportStudy?.id) {
      setInitialReport(xrayData.report.reportTemplate);
      setInitialStudy(xrayData.report.reportStudy);
    }
  }, []);
  const onSiteChanged = (e) => {
    console.log(currentStatus);

    setCurrentStatus(e.currentTarget.value);
    console.log(currentStatus);
  };
  const statusList = [
    {
      name: "Draft",
      value: "Draft",
    },
    {
      name: "Final",
      value: "Final",
    },
    {
      name: "Final with critical",
      value: "finalwithcritical",
    },
  ];

  return (
    <div style={{ marginTop: "60px", width: "550px", paddingLeft: "10px" }}>
      <Formik
        initialValues={{
          reportStudy: {
            id: initialStudy.id,
            value: initialStudy.value,
          },
          reportTemplate: "",
        }}
        validationSchema={Yup.object().shape({
          reportStudy: Yup.object(),
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            console.log(values);
            console.log(currentStatus);
            console.log(initialStudy);
            values.reportTemplate = currentTemplate;
            let updateData;
            console.log(currentStatus);
            if (values.reportStudy?.id) {
              updateData = {
                updatedBy: "doctor",
                updatedByDoctorId:doctor.userId,
                status: currentStatus,
                report: {
                  ...xrayData.report,
                  reportStudy: values.reportStudy,
                  reportTemplate: values.reportTemplate,
                },
              };
            } else {
              updateData = {
                updatedBy: "doctor",
                updatedByDoctorId:doctor.userId,
                status: currentStatus,
                report: {
                  ...xrayData.report,
                  reportStudy: initialStudy,
                  reportTemplate: values.reportTemplate,
                },
              };
            }

            callApi
              .put(`/xray/update/${xrayData.id}`, { updateData })
              .then((response) => {
                enqueueSnackbar("Xray Report Updated!", {
                  variant: "success",
                });
              })
              .catch((err) => {
                enqueueSnackbar("Some thing is wrong!", {
                  variant: "error",
                });
              });
            if (!updateData?.report?.isReportSaved && xrayData.repDoctor.name !== '') {
              let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
              const { name, userId } = xrayData.repDoctor;
              console.log("repdoctor", name, userId);
              callApi
                .post("/update-doctor-wallet-report-view/" + userId, {
                  name,
                  filmType: xrayData.filmType,
                  priority: xrayData.priority,
                  remarks: "Addition For Viewing Xray",
                  createdByUserId: loggedUser.userId,
                })
                .then((response) => {
                  console.log(response);
                  if (response.data.success) {
                    updateData = {
                      updatedBy: 'doctor',
                      updatedByDoctorId:loggedUser.userId,
                      report: {
                        ...updateData.report,
                        isReportSaved: true,
                      },
                    };
                    callApi
                      .put(`/xray/update/${xrayData.id}`, { updateData })
                      .then((response) => {
                        console.log("update response", response);
                        // localStorage.setItem('dicomfile' ,)
                      })
                      .catch((err) => {
                        enqueueSnackbar("Some thing is wrong!", {
                          variant: "error",
                        });
                      });
                  }
                });
            }

            // await wait(500);
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          initialValues,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Autocomplete
                  size="small"
                  disablePortal
                  id="doctor"
                  name="clinicInfo"
                  options={data}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  // sx={{ width: 300 }}
                  onChange={(e, value) => {
                    setFieldValue(
                      "reportStudy.id",
                      value !== null ? value.id : initialValues.reportStudy.id
                    );
                    setFieldValue(
                      "reportStudy.value",
                      value !== null
                        ? value.label
                        : initialValues.reportStudy.value
                    );
                    setInitialReport(value.value);
                    console.log("name", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Study"
                      variant="outlined"
                      placeholder="Search Study"
                      value={values?.reportStudy.value}
                      onChange={handleChange}
                      error={Boolean(touched.reportStudy && errors.reportStudy)}
                      helperText={touched.reportStudy && errors.reportStudy}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={5}>
                {statusList.map((result) => {
                  return (
                    <div>
                      <input
                        type="radio"
                        name="site_name"
                        value={result.value}
                        checked={currentStatus === result.value}
                        onChange={onSiteChanged}
                      />
                      {result.name}
                    </div>
                  );
                })}
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save Report
                </Button>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <div style={{ marginTop: "20px" }}>
        <JoditEditor
          ref={editor}
          value={initialReport}
          config={config}
          tabIndex={1}
          //   onBlur={(newContent) => getValue(newContent)}
          onChange={(newContent) => {
            setCurrentTemplate(newContent);
            getValue(newContent);
          }}
        />
      </div>
    </div>
  );
};

export default ReportsEditor;
