/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Formik } from "formik";
import * as Yup from "yup";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import console from "react-console";
// Overview page components
import Header from "layouts/profile/components/Header";
import { CloudUpload } from "@mui/icons-material";
// Images

import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import Card from "@mui/material/Card";
import callApi from "Utils/callApi";
import { styled } from "@mui/material/styles";
import { Button, FormControl, FormLabel } from "@mui/material";
const Input = styled("input")({
  display: "none",
});
function Overview() {
  const [cookies, setCookie, removeCookie] = useCookies({});
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState("");
  const [roleType, setGivenRole] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [_id, setId] = useState("");
  const [dateOfbirth, setDateOfbirth] = useState("");
  const [loggedUser, setLoggedUser] = useState("");
  useEffect(() => {
    if (!localStorage.getItem("loggedUser")) {
      history.push("/authentication/sign-in");
    } else {
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

      setName(loggedUser.name);
      setGivenRole(loggedUser.roleType);
      setEmail(loggedUser.email);
      setPhone(loggedUser.phone);
      setId(loggedUser.dateOfbirth);
      setAddress(loggedUser.address);
      setLoggedUser(loggedUser);
    }
  }, [history, cookies]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                info={{
                  fullName: name,
                  mobile: phone,
                  email: email,
                  location: address,
                }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={8} sx={{ display: "flex" }}>
              <Formik
                initialValues={{
                  name,
                  email,
                  phone,
                  dateOfbirth,
                  profileImage: "",
                  signatureData: {
                    signature: {},
                    signatureImageData:""
                  },
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string("Enter your Name"),
                  email: Yup.string("Enter your Email Number"),
                  phone: Yup.string("Enter your Phone Number"),
                  dateOfbirth: Yup.string("Enter your Date of Birth Number"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    if (
                      !values.name
                      // !values.email ||
                      // !values.phone ||
                      // !values.dateOfbirth
                    ) {
                      return enqueueSnackbar("Please Enter Valid Data", {
                        variant: "error",
                      });
                    }
                    console.log(values.name);
                    console.log({ values });
                    // return
                    callApi
                      .put(`/user/update/${loggedUser._id}`, values)
                      .then((res) => {
                        enqueueSnackbar("Data Updated! Please login again", {
                          variant: "success",
                        });
                        removeCookie("loggedUser", []);
                        localStorage.removeItem("loggedUser");
                        delete res.data.user.password;
                        if (res.data.success) {
                          setCookie("loggedUser", res.data.user, {
                            maxAge: Date.now() + 7 * 24 * 60 * 60 * 1000,
                          });
                          window.location.reload();
                        }
                      })
                      .catch((error) => {
                        return enqueueSnackbar(
                          "Please Enter Valid Data! I think Email already registrated",
                          {
                            variant: "error",
                          }
                        );
                      });
                  } catch (error) {
                    const message = error.message || "Something went wrong";
                    console.log("error", error.statusCode);

                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                    return enqueueSnackbar(
                      "Please Enter Valid Data! I think Email already registrated",
                      {
                        variant: "error",
                      }
                    );
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <MDBox style={{ width: "100%", color: "black" }}>
                    <Card
                      style={{ width: "100%", color: "black" }}
                      onSubmit={handleSubmit}
                      component="form"
                      sx={{
                        p: 5,
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <MDInput
                        sx={{ mb: 3 }}
                        fullWidth
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        label="Name"
                        focused
                        type="text"
                        variant="outlined"
                        defaultValue={name}
                      />
                      <MDInput
                        sx={{ mb: 3 }}
                        fullWidth
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        label="Email"
                        focused
                        type="email"
                        variant="outlined"
                        disabled
                      />
                      <MDInput
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone}
                        sx={{ mb: 3 }}
                        fullWidth
                        label="Phone"
                        focused
                        type="text"
                        variant="outlined"
                        disabled
                      />
                      <MDInput
                        name="dateOfbirth"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.dateOfbirth}
                        sx={{ mb: 3 }}
                        fullWidth
                        label="Date Of Birth"
                        type="date"
                        variant="outlined"
                        focused
                      />
                      <MDButton
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="outlined"
                        color="success"
                      >
                        Update
                      </MDButton>
                    </Card>
                  </MDBox>
                )}
              </Formik>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
