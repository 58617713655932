import React from 'react';
import Typography from '@material-ui/core/Typography'
export default function Footer(props){
    return <footer style = {{background:"#1f283e",color:"white",paddingRight:"5px"}}> 
    
    <Typography>
    © Copyright By 
                </Typography>
                <Typography fontSize="10"><strong>Dr. Ashit Bardhan Foundation</strong></Typography>
    
    </footer>
}