import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "../../../Utils/./callApi";
import wait from "../../../Utils/./wait";
import { useSnackbar } from "notistack";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Autocomplete from "@mui/material/Autocomplete";
import callApi from "Utils/callApi";
import console from "react-console";
import useIsMountedRef from "hooks/useIsMountedRef";
import { useGridApiMethod } from "@mui/x-data-grid";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {},
}));

const clinics = [
  {
    clinicName: "Sheba Clinic",
    isActivated: true,
    requiredData: [],
  },
  {
    clinicName: "Popular Diagnostic",
    isActivated: true,
    requiredData: [],
  },
  {
    clinicName: "Lab Aid Diagnostic",
    isActivated: true,
  },
];

const WalletManager = ({ className, ...rest }) => {
  console.log("sdsddsad");
  const isMountedRef = useIsMountedRef();
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [wallet, setWallet] = useState([]);
  const [data, setData] = useState([]);
  const getWalletList = useCallback(() => {
    callApi
      .post("/find-wallet-by-id/" + loggedUser.userId, {
        name: loggedUser.name,
      })
      .then((response) => {
        const wallet = response.data.wallet;
        console.log(wallet);
        setWallet(wallet);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);

  const getClinicList = useCallback(() => {
    let list = [];
    callApi
      .post("/get-all-users", { roleType: loggedUser.roleType })
      .then((response) => {
        const clinicList = response.data.clinic;
        if (loggedUser.roleType == "admin") {
          Promise.all(
            clinicList.map((item) => {
              if (item.roleType !== "admin") {
                list.push(item);
              }
            })
          ).then(() => {
            setData(list);
          });
        } else {
          setData(clinicList);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);
  useEffect(() => {
    getClinicList();
    getWalletList()
  }, [getClinicList]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container
        maxWidth={false}
        style={{
          padding: "20px",
          background: "white",
          margin: "0 auto",
          width: "100%",
          borderRadius: "10px",
        }}
      >
        <div className={clsx(classes.root, className)} {...rest}>
          <Formik
            initialValues={{
              name: "",
              phone: "",
              userId: "",
              roleType: "",
              main_balance: 0,
              xray_rate: {},
              mri_rate: {},
              ct_rate: {},
              ct_rate: {},
              hematology_rate: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string("Enter User").required("Select user"),

            })}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
              try {
                console.log(values);
                console.log(values.clinicInfo);
                const { userId, name, amount, confirmAmount, remarks } = values;
                values.createdByUserId = loggedUser.userId
                // return console.log(name, amount, confirmAmount, remarks);
                await axios
                  .post("/wallet-update-from-manager/" + userId, values)
                  .then((response) => {
                    console.log(response);
                  });
                await wait(500);
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar("Balance Added Successfully", {
                  variant: "success",
                  // action: <Button>See all</Button>
                });
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              initialValues,
              setFieldValue,
              resetForm
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                {" "}
                <div>
                  <h1>Manage Wallet</h1>
                </div>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.patientInfo?.phone &&
                          errors.patientInfo?.phone
                        )}
                        helperText={
                          touched.patientInfo?.phone &&
                          errors.patientInfo?.phone
                        }
                        fullWidth
                        autoFocus
                        label="Search by Phone Number"
                        margin="normal"
                        name="patientInfo.phone"
                        onBlur={handleBlur}
                        onChange={(e, value) => {
                          console.log(e.target.value);
                          let phone = e.target.value;
                          let validated = true;
                          if (validated) {
                            callApi
                              .post("/find-user-by-phone", {
                                phone: e.target.value,
                              })
                              .then((res) => {
                                console.log("response", res);
                                if (res.data.success) {
                                  let userData = res.data.user;
                                  callApi
                                    .post("/find-wallet-by-id/" + userData.userId, {
                                      name: userData.name,
                                    })
                                    .then((response) => {
                                      const wallet = response.data.wallet;
                                      console.log(wallet);
                                      console.log(userData);
                                      setFieldValue("name", userData?.name || "");
                                      setFieldValue("userId", userData?.userId || "");
                                      setFieldValue("roleType", userData?.roleType || "")
                                      setFieldValue("main_balance", wallet?.main_balance || 0);
                                      setFieldValue("ct_rate", wallet?.ct_rate || {});
                                      setFieldValue("xray_rate", wallet?.xray_rate || {});
                                      setFieldValue("mri_rate", wallet?.mri_rate || {});
                                      setFieldValue("hematology_rate", wallet?.hematology_rate || {});

                                      // setWallet(wallet);
                                    })
                                    .catch((error) => {
                                      console.log("Error", error);
                                    });
                                }
                              });
                          }

                          setFieldValue(
                            "phone",
                            e.target.value !== null
                              ? e.target.value
                              : initialValues.phone
                          );
                        }}
                        // type="number"
                        value={values.phone || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ background: "#eee", p: 2, color: "gray" }}>
                        <Typography variant="p" component="p">Name : {values?.name || ""}</Typography>
                        <Typography variant="p" component="p">Phone :{values?.phone || ""}</Typography>
                        <Typography variant="p" component="p">Role :{values?.roleType || ""}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box xs={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.main_balance && errors.main_balance)}
                        helperText={touched.main_balance && errors.main_balance}
                        margin="normal"
                        label="Main Balance"
                        name="main_balance"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);

                          if (
                            parseFloat(e.target.value) > wallet.main_balance && loggedUser.roleType !== "superAdmin"
                          ) {
                            enqueueSnackbar(
                              "Cannot be greater than main balance",
                              {
                                variant: "error",
                                // action: <Button>See all</Button>
                              }
                            );
                            setFieldValue("main_balance", wallet.main_balance);
                          } else {
                            setFieldValue("main_balance", e.target.value);
                          }
                        }}
                        value={values?.main_balance || 0}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.xray_rate?.general && errors.xray_rate?.general)}
                        helperText={touched.xray_rate?.general && errors.xray_rate?.general}
                        margin="normal"
                        label="Xray Rate(General)"
                        name="xray_rate.general"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue("xray_rate.general", e.target.value);
                        }}
                        value={values.xray_rate?.general || 0}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.xray_rate?.emergency && errors.xray_rate?.emergency)}
                        helperText={touched.xray_rate?.emergency && errors.xray_rate?.emergency}
                        margin="normal"
                        label="Xray Rate(Emergency)"
                        name="xray_rate.emergency"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue("xray_rate.emergency", e.target.value);
                        }}
                        value={values.xray_rate?.emergency || 0}
                        variant="outlined"
                      />
                    </Grid>

                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.mri_rate?.general && errors.mri_rate?.general)}
                        helperText={touched.mri_rate?.general && errors.mri_rate?.general}
                        margin="normal"
                        label="MRI Rate(General)"
                        name="mri_rate.general"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue("mri_rate.general", e.target.value);
                        }}
                        value={values.mri_rate?.general || 0}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.mri_rate?.emergency && errors.mri_rate?.emergency)}
                        helperText={touched.mri_rate?.emergency && errors.mri_rate?.emergency}
                        margin="normal"
                        label="MRI Rate(Emergency)"
                        name="mri_rate.emergency"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue("mri_rate.emergency", e.target.value);
                        }}
                        value={values.mri_rate?.emergency || 0}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.ct_rate?.general && errors.ct_rate?.general)}
                        helperText={touched.ct_rate?.general && errors.ct_rate?.general}
                        margin="normal"
                        label="CT Scan Rate(General)"
                        name="ct_rate.general"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue("ct_rate.general", e.target.value);
                        }}
                        value={values.ct_rate?.general || 0}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.ct_rate?.emergency && errors.ct_rate?.emergency)}
                        helperText={touched.ct_rate?.emergency && errors.ct_rate?.emergency}
                        margin="normal"
                        label="CT Scan Rate(Emergency)"
                        name="ct_rate.emergency"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue("ct_rate.emergency", e.target.value);
                        }}
                        value={values.ct_rate?.emergency || 0}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.hematology_rate?.general && errors.hematology_rate?.general)}
                        helperText={touched.hematology_rate?.general && errors.hematology_rate?.general}
                        margin="normal"
                        label="Hematology Rate(General)"
                        name="hematology_rate.general"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue("hematology_rate.general", e.target.value);
                        }}
                        value={values.hematology_rate?.general || 0}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoFocus
                        error={Boolean(touched.hematology_rate?.emergency && errors.hematology_rate?.emergency)}
                        helperText={touched.hematology_rate?.emergency && errors.hematology_rate?.emergency}
                        margin="normal"
                        label="Hematology Rate(Emergency)"
                        name="hematology_rate.emergency"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue("hematology_rate.emergency", e.target.value);
                        }}
                        value={values.hematology_rate?.emergency || 0}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Grid container flex={1} justifyContent="center" alignItems="center">
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <Grid container flex={1} xs={12} >
                      <Grid item xs={6} style={{ marginRight: "10px" }}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"

                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item xs={5}>

                        <Button
                          color="info"
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          onClick={(e) => {
                            resetForm({
                              values: {
                                name: "",
                                phone: "",
                                userId: "",
                                roleType: "",
                                main_balance: 0,
                                xray_rate: {},
                                mri_rate: {},
                                ct_rate: {},
                                ct_rate: {},
                                hematology_rate: "",
                              }
                            })
                          }}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>


                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </Container>
    </DashboardLayout>
  );
};
WalletManager.propTypes = {
  className: PropTypes.string,
};
export default WalletManager;
