import React, { useState, useEffect, useCallback } from "react";
import { forwardRef } from "react";
// import Avatar from 'react-avatar';
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Modal from "@mui/material/Modal";
import {
  Box,
  Table,
  Typography,
  Button,
  makeStyles,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Alert from "@material-ui/lab/Alert";
import callApi from "Utils/callApi";
import Card from "@mui/material/Card";
import { useSnackbar } from "notistack";
import { useCookies } from "react-cookie";
import useIsMountedRef from "hooks/useIsMountedRef";
import InvoiceModal from "layouts/XrayReports/viewReport";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@material-ui/core";
import console from "react-console";
// import { useSnackbar } from "notistack";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = callApi;

function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}
const ApprovedAndPendingReportTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  var columns = [
    { title: "id", field: "_id", hidden: true },
    { title: "Upload Date", field: "uploaddate" },
    { title: "Patient", field: "patient" },
    { title: "Phone", field: "phone" },
    { title: "Remarks", field: "remarks" },
    { title: "X-ray", field: "xray" },
  ];
  const [xrayData, setXrayData] = useState("");
  const [data, setData] = useState([]); //table data
  const [cookies] = useCookies({});
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

  const { roleType, userId, name, email } = loggedUser;
  //for error handling
  const [iserror, setIserror] = useState(false);
  const [loaderActive, setLoaderActive] = useState(false);
  const [xrayView, setXrayView] = useState(false);
  const [imageURL, setImageUrl] = useState("");
  const [pendingList, setPendingList] = useState("");
  const [isOpen, openModal] = React.useState(false);
  const closeModal = (event) => openModal(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    getApprovedAndPendingReportList()
  }, [])


  var columns = [
    { title: "id", field: "id", hidden: true },
    { title: "Date", field: "date" },
    { title: "Upload Date", field: "uploaddate" },
    { title: "Uploaded By", field: "uploadedBy" },
    { title: "Approve Date", field: "approveDate" },
    { title: "Patient", field: "patient" },
    { title: "Phone", field: "phone" },
    { title: "Remarks", field: "remarks" },
    { title: "X-ray", field: "xray" },

  ]
  let searchParameters

  if (roleType !== 'admin' || roleType !== 'superAdmin') {
    searchParameters = {
      roleType,
      userId
    }
  }
  else searchParameters = ''

  const getApprovedAndPendingReportList = useCallback(() => {
    callApi.post("/report/all", searchParameters)
      .then(res => {
        let xrayReports = res.data.report;
        let approvedAndPendingReportList = []
        for (let i = 0; i < xrayReports.length; i++) {
          let approvedAndPending = {
            id: xrayReports[i]._id,
            uploaddate: xrayReports[i].createdAt,
            time: xrayReports[i].createdAt,
            patient: xrayReports[i].patientInfo.name,
            phone: xrayReports[i].patientInfo.phone,
            remarks: xrayReports[i].remarks,
            xray: xrayReports[i].fileNameOnServer,
            description: xrayReports[i].description,
          }
          approvedAndPendingReportList.push(approvedAndPending);

        }
        setData(approvedAndPendingReportList)
        console.log("approvedAndPendingReportList", approvedAndPendingReportList)
      })
      .catch(error => {
        console.log("Error")
      })
  }, [isMountedRef])


  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = []
    if (newData.first_name === "") {
      errorList.push("Please enter first name")
    }
    if (newData.last_name === "") {
      errorList.push("Please enter last name")
    }
    // if(newData.email === "" || validateEmail(newData.email) === false){
    //   errorList.push("Please enter a valid email")
    // }

    if (errorList.length < 1) {
      callApi.put("/xray/update/" + newData.id, newData)
        .then(res => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          setData([...dataUpdate]);
          resolve()
          setIserror(false)
          setErrorMessages([])
        })
        .catch(error => {
          setErrorMessages(["Update failed! Server error"])
          setIserror(true)
          resolve()

        })
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      resolve()

    }

  }

  const handleRowAdd = (newData, resolve) => {
    //validation
    let errorList = []
    if (newData.first_name === undefined) {
      errorList.push("Please enter first name")
    }
    if (newData.last_name === undefined) {
      errorList.push("Please enter last name")
    }
    if (newData.email === undefined || validateEmail(newData.email) === false) {
      errorList.push("Please enter a valid email")
    }

    if (errorList.length < 1) { //no error
      callApi.post("/xray/new", newData)
        .then(res => {
          let dataToAdd = [...data];
          dataToAdd.push(newData);
          setData(dataToAdd);
          resolve()
          setErrorMessages([])
          setIserror(false)
        })
        .catch(error => {
          setErrorMessages(["Cannot add data. Server error!"])
          setIserror(true)
          resolve()
        })
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      resolve()
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    callApi.delete("/xray/delete/" + oldData.id)
      .then(res => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve()
      })
      .catch(error => {
        setErrorMessages(["Delete failed! Server error"])
        setIserror(true)
        resolve()
      })
  }
  const [row, setRows] = useState(null)
  const [open, setOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, data) => {
    setAnchorEl([event.currentTarget, data]);
    setOpen(true)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  }

  const approveXray = (e, selectedRow) => {
    const selectedXrayRow = row;

    setAnchorEl(null);
    setOpen(false);
    if (selectedXrayRow.status === "Approved") {
      enqueueSnackbar("Report is already  Approved! You can change status to Pending by selecting Add to Pending  ", {
        variant: "Error",
      });
      return;
    } else {

      const updateData = {
        approvedBy: {
          adminName: name,
          adminEmail: email,
        },
        status: "Approved",
        approvedDate: Date.now(),

      }
       callApi.put(`/xray/update/${row.id}`, { updateData })
        .then((response => {
          enqueueSnackbar("Report  Approved! Reload Please!", {
            variant: "success",
          });
          getApprovedAndPendingReportList()
        }))
        .catch((err) => {
          enqueueSnackbar("Some thing is wrong!", {
            variant: "error",
          });
        })

    }
  };

  const addToPending = () => {
    const selectedXrayRow = row;

    setAnchorEl(null);
    setOpen(false);
    if (selectedXrayRow.status === "Pending") {
      enqueueSnackbar("Xray is already  Pending! You can change status to Approved by selecting Approved  ", {
        variant: "Error",
      });
      return;
    } else {

      const updateData = {
        approvedBy: {
          adminName: name,
          adminEmail: email,
        },
        status: "Pending",
        approvedDate: Date.now(),

      }
       callApi.put(`/xray/update/${row.id}`, { updateData })
        .then((response => {
          enqueueSnackbar("Report Status Changed To Pending! Reload Please!", {
            variant: "success",
          });
          getApprovedAndPendingReportList()
        }))
        .catch((err) => {
          enqueueSnackbar("Some thing is wrong!", {
            variant: "error",
          });
        })

    }

  };


  return (
    <LoadingOverlay active={loaderActive} spinner={<BounceLoader />}>
      <div className="App" style={{ marginTop: "60px" }}>
        <Grid container spacing={1}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Xray Reports
                </MDTypography>
              </MDBox>

              <div>
                {iserror && (
                  <Alert severity="error">
                    {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>;
                    })}
                  </Alert>
                )}
              </div>
              <MaterialTable
                mt={90}
                title=""
                columns={columns}
                data={data}
                icons={tableIcons}
                options={{
                  headerStyle: { size: "80px" },
                }}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      handleRowUpdate(newData, oldData, resolve);
                    }),
                  onRowAdd: (newData) =>
                    new Promise((resolve) => {
                      handleRowAdd(newData, resolve);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      handleRowDelete(oldData, resolve);
                    }),
                }}
                actions={[
                  {
                    icon: () => (
                      <button
                        style={{
                          background: "#2a324a",
                          color: "white",
                          fontSize: "20px",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        View
                      </button>
                    ),
                    tooltip: "View",
                    onClick: (e, data) => {
                      const config = {
                        responseType: "blob",
                      };
                      setLoaderActive(true);

                      callApi
                        .post(
                          "/report/view",
                          { dicomFilename: data.xray },
                          config
                        )
                        .then((response) => {
                          setLoaderActive(false);
                          // openModal(true);
                          setXrayData(data);
                          const url = window.URL.createObjectURL(response.data)
                          console.log(url)
                          setImageUrl(url);
                          // var printContents = document.getElementById("printPreview").innerHTML;
                      
                          // document.body.innerHTML = originalContents;
                          var printContent = document.getElementById("printPreview");
                          var WinPrint = window.open('', '', 'width=900,height=650');
                          WinPrint.document.write(printContent.innerHTML);
                          WinPrint.document.close();
                          WinPrint.focus();
                          WinPrint.print();
                          // WinPrint.close();
                          // const link = document.createElement("a");
                          // link.href = url;
                          // link.setAttribute("download", `${data.xray}`);
                          // document.body.appendChild(link);
                          // link.click();
                          // document.body.removeChild(link);
                        });
                    },
                  },
                  {
                    icon: MoreVertIcon,
                    tooltip: "More",
                    onClick: (event, rowData) => {
                      handleClick(event, rowData)
                      // console.log("rowData", rowData)
                      setRows(rowData)
                      // console.log("row",row)


                    },
                  }
                ]}
              />
              <Menu
                id="more-menu" anchorEl={anchorEl} keepMounted={true} open={open} onClose={handleClose}
              >
                <MenuItem onClick={approveXray}>Approve Xray</MenuItem>
                <MenuItem onClick={addToPending}>Approve Xray</MenuItem>
              </Menu>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >

      </Modal>
      <Box
        id="printPreview"
        style={{
          background: "white",
          margin: "0px auto",
          position: "relative",
          top: "45%",
          width: "50%",
          padding: 20,
          display: "none"
        }}
      >
        <Typography
          id="modal-modal-title"
          style={{ display: "flex", alignItem: "center", margin: "0 auto" }}
          variant="h6"
          component="h2"
        >
          Xray Report
        </Typography>

        <hr />

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Patient Name : {xrayData.patient}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Phone : {xrayData.phone}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Doctor Name : {xrayData.doctorName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Uploaded By : {xrayData.patient}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Uploaded Date : {xrayData.uploaddate}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          id="modal-modal-title"
          style={{ display: "flex", alignItem: "center", margin: "0 auto" }}
          variant="h6"
          component="h2"
        >
          Test Result : {xrayData.remarks}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={1.5}>
            <Typography
              id="modal-modal-title"
              style={{
                display: "flex",
                alignItem: "center",
                margin: "0 auto",
              }}
              variant="h6"
              component="h2"
            >
              Description:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography id="modal-modal-description">
              {xrayData.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <img alt="" style={{ width: "80%", height: '80%' }} src={imageURL} />
          </Grid>
        </Grid>
      </Box>
    </LoadingOverlay>
  );
};

export default ApprovedAndPendingReportTable;