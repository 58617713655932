import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import clsx from "clsx";
import {
  Box,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import callApi from "Utils/callApi";
import MDInput from "components/MDInput";
import { Clear, File } from "@material-ui/icons";
import console from "react-console";
// import { loggedUser } from "Utils/authUser";
// import axios from "../../../Utils/./callApi";
import { ReportTemplates } from "../../components/templates";
import { authUser } from "Utils/authUser";
import { Icon, Typography } from "@mui/material";
import useIsMountedRef from "hooks/useIsMountedRef";
const Input = styled("input")({
  display: "none",
});
const types1 = ["Xray", "MRI", "CT-Scan", "Hematology"];
const types2 = ["MRI", "CT-Scan", "Hematology"];
const useStyles = makeStyles(() => ({
  root: {},
}));
var current_datetime = new Date();
let formatted_date =
  current_datetime.getFullYear() +
  "-" +
  (current_datetime.getMonth() + 1) +
  "-" +
  current_datetime.getDate();
const initialValues = {
  repDoctor: {
    name: "",
    id: null,
    phone: null,
    designation: "",
    passedIns: "",
    doctorType: "",
  },
  refDoctor: { name: "", designation: "" },
  assistantDoctor: {
    name: "",
    id: null,
    phone: null,
  },
  report: {
    reportStudy: {
      id: "",
      value: "",
    },
    reportTemplate: "",
  },
  remarks: "",
  file: "",
  priority: "",
  filmType: "",
  ageInYears: 0,
  ageInMonths: 0,
  patientInfo: {
    name: "",
    phone: "",
    id: "",
    age: "",
    address: "",
    gender: "Male",
    dateOfbirth: formatted_date,
  },
  charge: 0,
  // createdAt:new Date().getTime()
};

const XrayData = ({ className, ...rest }) => {
  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [patients, setPatients] = useState([]);
  const [data, setData] = useState(ReportTemplates);

  const [fileName, setFileName] = useState("");

  const [filmTypes, setFilmTypes] = useState([
    "Xray",
    "MRI",
    "CT-Scan",
    "Hematology",
  ]);

  const [filmType, setFilmType] = useState("");
  const [assistantDoctors, setDoctorAssistant] = useState([]);
  const [loggedUser, setLoggedUser] = useState({});

  useEffect(() => {
    async function fetchData() {
      let user = await authUser();
      setLoggedUser(user);
      callApi.get("/doctor/getData").then((response) => {
        const patientsData = response.data.users;
        const doctorsData = response.data.doctors;
        const assistantDoctorsData = response.data.doctorAssistant;
        console.log("patientsData", patientsData);
        setPatients(patientsData);
        // setDoctors(doctorsData);
        setDoctorAssistant(assistantDoctorsData);
      });
    }

    fetchData();
  }, []);
  const [wallet, setWallet] = useState({});
  const getWalletDetailsUsingUserId = useCallback(() => {
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    callApi
      .post("/find-wallet-by-id/" + loggedUser.userId, {
        name: loggedUser.name,
      })
      .then((response) => {
        const wallet = response.data.wallet;
        console.log("wallet........................", wallet);
        setWallet(wallet);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);
  useEffect(() => {
    getWalletDetailsUsingUserId();
  }, [getWalletDetailsUsingUserId]);
  const fileList = (files) => {
    let render = [];
    for (let index = 0; index < files.length; index++) {
      let file = files[index];
      render.push(
        <Grid
          key={`reportFile${file}`}
          xs={2}
          style={{
            border: "1px solid #eee",
            borderRadius: "10px",
            marginRight: "5px",
          }}
        >
          <Grid container xs={12} style={{ padding: 10 }}>
            <Grid item xs={1} style={{ paddingTop: 2 }}>
              <Icon>description</Icon>
            </Grid>
            <Grid item xs={10} style={{ paddingLeft: 2 }}>
              <Typography component="div" variant="body">
                {file.name}
              </Typography>
            </Grid>
            <Grid item xs={1} style={{ paddingTop: 2 }}>
              <Icon
                onClick={(e) => {
                  files.splice(index, 1);
                }}
              >
                delete
              </Icon>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return render;
  };
  const get_year = (date_of_birthday) => {
    var current_date = new Date().getFullYear();
    var tempDOB_Y = current_date - date_of_birthday.split("-")[0];
    return tempDOB_Y;
  };
  const makeDOBFromYMD = (ageInYears = 0, ageInMonths = 0, ageInDays = 0) => {
    console.log({ ageInMonths, ageInYears, ageInDays });
    ageInDays = Number.isNaN(ageInDays) ? 0 : ageInDays;
    ageInMonths = Number.isNaN(ageInMonths) ? 0 : ageInMonths;
    ageInYears = Number.isNaN(ageInYears) ? 0 : ageInYears;
    var uptoHour = 24 * 60 * 60 * 1000;
    var yearTimeInMS = Math.round(ageInYears * 365.25 * uptoHour);
    var monthTimeInMS = Math.round(ageInMonths * 30.5 * uptoHour);

    var dobTime =
      new Date().getTime() -
      yearTimeInMS -
      monthTimeInMS -
      uptoHour * ageInDays;
    var dobDate = new Date(new Date().setTime(dobTime));
    var tempDOB_Y = dobDate.getFullYear();
    var tempDOB_M = (dobDate.getMonth() + 1).toString();
    var tempDOB_D = (dobDate.getDate() + 1).toString();
    console.log("tempDOB_D", tempDOB_D);
    if (tempDOB_M.length == 1) tempDOB_M = `0${tempDOB_M}`;
    if (tempDOB_D.length == 1) tempDOB_D = `0${tempDOB_D}`;
    return { tempDOB_M, tempDOB_Y, tempDOB_D };
  };

  function validatePhoneNumber(input_str) {
    var validationStr = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return validationStr.test(input_str);
  }
  return (
    <div
      style={{ padding: "20px", background: "white" }}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          refDoctor: Yup.object().shape({
            name: Yup.string().required("Enter Referal Doctor Name"),
          }),
          patientInfo: Yup.object().shape({
            name: Yup.string().required("Patient name is required"),
            phone: Yup.string().required("patient phone number is required"),
            dateOfbirth: Yup.string().required("patient age is required"),
            // gender: Yup.string().required("patient gender is required"),
            // address: Yup.string().required("patient adressis required"),
          }),

          remarks: Yup.string("Add Clinical History").required(
            "Add Clinical History"
          ),
          filmType: Yup.string("Add Modality").required("Add Modality"),
          priority: Yup.string("Add priority").required("Add priority"),
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting, setFieldValue }
        ) => {
          try {
            console.log({ values });
            // console.log(values.reportfile.name);
            let xrayData = values;
            xrayData.createdBy = {
              name: loggedUser.name,
              userId: loggedUser.userId,
            };
            xrayData.createdAt = new Date().getTime();
            console.log({ loggedUser: loggedUser.userId });
            const data = new FormData();
            data.append("data", JSON.stringify(xrayData));
            let files = values.reportfile;
            if (files.length != 0) {
              for (const single_file of files) {
                data.append("reportfile", single_file);
              }
            }

            // data.append("reportfile", values.reportfile);
            console.log({ data });
            callApi
              .post("/xray/new", data, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                console.log("response", res);
                enqueueSnackbar("Study  Uploaded Successfully", {
                  variant: "success",
                  // action: <Button>See all</Button>
                });
                resetForm();
                setErrors(false);
              });
          } catch (error) {
            console.log({ error });

            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <Form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Box
              style={{
                border: "1px solid #cfc9c9",
                padding: "20px",
                marginBottom: "20px",
                borderradius: "10px",
              }}
              xs={{ flexGrow: 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={5}></Grid>
              </Grid>
              <Box xs={{ flexGrow: 1 }}>
                <Box xs={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.patientInfo?.phone &&
                            errors.patientInfo?.phone
                        )}
                        helperText={
                          touched.patientInfo?.phone &&
                          errors.patientInfo?.phone
                        }
                        fullWidth
                        autoFocus
                        label="Search by Patient Phone Number"
                        margin="normal"
                        name="patientInfo.phone"
                        onBlur={handleBlur}
                        onChange={(e, value) => {
                          console.log(e.target.value);
                          let phone = e.target.value;
                          let validated = true;
                          if (validated) {
                            callApi
                              .post("/find-user-by-phone", {
                                phone: e.target.value,
                              })
                              .then((res) => {
                                console.log("response", res);
                                if (res.data.success) {
                                  let userData = res.data.user;
                                  setFieldValue(
                                    "patientInfo",
                                    userData !== null
                                      ? userData
                                      : initialValues.patientInfo
                                  );
                                }
                              });
                          }

                          setFieldValue(
                            "patientInfo.phone",
                            e.target.value !== null
                              ? e.target.value
                              : initialValues.patientInfo.phone
                          );
                        }}
                        // type="number"
                        value={values.patientInfo.phone || ""}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box xs={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.patientInfo?.name && errors.patientInfo?.name
                        )}
                        helperText={
                          touched.patientInfo?.name && errors.patientInfo?.name
                        }
                        fullWidth
                        autoFocus
                        label="Patient Name"
                        margin="normal"
                        name="patientInfo.name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // type="number"
                        value={values?.patientInfo.name || ""}
                        variant="outlined"
                      />
                    </Grid>
                    {/*<Grid item xs={3}>
                      <TextField
                        error={Boolean(
                          touched.patientInfo?.age && errors.patientInfo?.age
                        )}
                        helperText={
                          touched.patientInfo?.age && errors.patientInfo?.age
                        }
                        fullWidth
                        autoFocus
                        label="Date of Birth"
                        margin="normal"
                        name="patientInfo.dateOfbirth"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="date"
                        value={values.patientInfo.dateOfbirth || ""}
                        variant="outlined"
                      />
                      </Grid>*/}
                    <Grid item xs={3}>
                      <TextField
                        error={Boolean(touched.ageInYears && errors.ageInYears)}
                        helperText={touched.ageInYears && errors.ageInYears}
                        fullWidth
                        autoFocus
                        label="Patient Age"
                        margin="normal"
                        name="ageInYears"
                        onBlur={handleBlur}
                        onChange={async (e) => {
                          let value = parseInt(e.target.value);
                          console.log(typeof value);
                          setFieldValue("ageInYears", parseInt(value));
                          value = Number.isNaN(value) ? 0 : value;
                          console.log(value);
                          let dateOfBirth = await makeDOBFromYMD(
                            value,
                            values.ageInMonths,
                            values.ageInDays
                          );
                          dateOfBirth = `${dateOfBirth.tempDOB_Y}-${dateOfBirth.tempDOB_M}-${dateOfBirth.tempDOB_D}`;
                          console.log(dateOfBirth);

                          setFieldValue(
                            "patientInfo.dateOfbirth",
                            dateOfBirth !== null
                              ? dateOfBirth
                              : initialValues.patientInfo.dateOfbirth
                          );
                          console.log(dateOfBirth, value);
                        }}
                        type="text"
                        value={get_year(values.patientInfo.dateOfbirth) || 0}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        error={Boolean(
                          touched.ageInMonths && errors.ageInMonths
                        )}
                        helperText={touched.ageInMonths && errors.ageInMonths}
                        fullWidth
                        autoFocus
                        label="Months"
                        margin="normal"
                        name="ageInMonths"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let value = parseInt(e.target.value);
                          console.log(typeof value);
                          setFieldValue("ageInMonths", parseInt(value));
                          let dateOfBirth = makeDOBFromYMD(
                            values.ageInYears,
                            value,
                            values.ageInDays
                          );
                          console.log(dateOfBirth);
                          dateOfBirth = `${dateOfBirth.tempDOB_Y}-${dateOfBirth.tempDOB_M}-${dateOfBirth.tempDOB_D}`;
                          console.log(dateOfBirth);

                          setFieldValue(
                            "patientInfo.dateOfbirth",
                            dateOfBirth !== null
                              ? dateOfBirth
                              : initialValues.patientInfo.dateOfbirth
                          );
                        }}
                        type="text"
                        value={values.ageInMonths || 0}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        error={Boolean(touched.ageInDays && errors.ageInDays)}
                        helperText={touched.ageInDays && errors.ageInDays}
                        fullWidth
                        autoFocus
                        label="Days"
                        margin="normal"
                        name="ageInDays"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let value = parseInt(e.target.value);
                          console.log(typeof value);
                          setFieldValue("ageInDays", parseInt(value));
                          // value = value < parseInt(current_datetime.getDate()) ? value : parseInt(current_datetime.getDate())
                          let dateOfBirth = makeDOBFromYMD(
                            values.ageInYears,
                            values.ageInMonths,
                            value
                          );
                          console.log(dateOfBirth);
                          dateOfBirth = `${dateOfBirth.tempDOB_Y}-${dateOfBirth.tempDOB_M}-${dateOfBirth.tempDOB_D}`;
                          console.log(dateOfBirth);

                          setFieldValue(
                            "patientInfo.dateOfbirth",
                            dateOfBirth !== null
                              ? dateOfBirth
                              : initialValues.patientInfo.dateOfbirth
                          );
                        }}
                        type="text"
                        value={values.ageInDays || 0}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box xs={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <span>Gender</span>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="patientInfo.gender"
                              value="Male"
                              checked={
                                values.patientInfo.gender === "Male" || "male"
                              }
                              onChange={handleChange}
                            />
                            <span>Male</span>
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="patientInfo.gender"
                              value="Female"
                              checked={values.patientInfo.gender === "Female"}
                              onChange={handleChange}
                            />
                            <span>Female</span>
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="patientInfo.gender"
                              value="Other"
                              checked={values.patientInfo.gender === "Other"}
                              onChange={handleChange}
                            />
                            <span>Other</span>
                          </label>
                        </div>
                      </div>
                    </Grid>
                    {/*<Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.patientInfo?.age &&
                            errors.patientInfo?.address
                        )}
                        helperText={
                          touched.patientInfo?.address &&
                          errors.patientInfo?.address
                        }
                        fullWidth
                        autoFocus
                        label="Patient Address"
                        margin="normal"
                        name="patientInfo.address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // type="number"
                        value={values.patientInfo.address || ""}
                        variant="outlined"
                        multiline
                      />
                      </Grid>*/}
                  </Grid>
                </Box>
              </Box>
              <Box xs={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      sttle={{ background: "#3ea98b" }}
                      variant="contained"
                      component="span"
                      size="large"
                      onClick={(e) => {
                        console.log("clicked");
                        let registerData = {
                          name: values.patientInfo.name,
                          email: "",
                          phone: values.patientInfo.phone,
                          password: "123456",
                          roleType: "patient",
                          nid: "",
                          gender: values.patientInfo.gender,
                          bmdcType: "",
                          dateOfbirth: values.patientInfo.dateOfbirth,
                          bmdcRegNo: "",
                          passedIns: "",
                          doctorType: "",
                          address: values.patientInfo.address,
                          policy: false,
                          createdByUserId: loggedUser.userId,
                        };
                        try {
                          callApi
                            .post("/registration", registerData)
                            .then((res) => {
                              // onSubmitSuccess();
                              console.log(res);
                              setFieldValue(
                                "patientInfo",
                                res.data.user
                                  ? res.data.user
                                  : initialValues.patientInfo
                              );
                              enqueueSnackbar("Registration Success", {
                                variant: "success",
                              });
                            })
                            .catch((error) => {
                              console.log(
                                "error 01",
                                error.message,
                                error.name,
                                error.code
                              );
                              enqueueSnackbar(
                                "User Credential already exist!",
                                {
                                  variant: "error",
                                }
                              );
                            });
                          // await dispatch(register(values));
                          //
                        } catch (error) {
                          console.log(
                            "error 02",
                            error.message,
                            error.name,
                            error.stack
                          );
                        }
                      }}
                    >
                      Register Patient
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box xs={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label htmlFor="contained-button-file">
                    <Input
                      id="contained-button-file"
                      multiple
                      name="reportfile"
                      type="file"
                      accept="*/dicom,.dcm, image/dcm, */dcm, .dicom, .png, */png, .jpg, */jpg, image/jpeg"
                      error={Boolean(touched.file && errors.file)}
                      helpertext={touched.file && errors.file}
                      color="success"
                      onChange={(e) => {
                        let list = values.reportfile || [];
                        for (let file of e.target.files) {
                          list.push(file);
                        }
                        setFieldValue("reportfile", list);
                        console.log(e.target.files);
                        if (list.length > 2) {
                          setFilmTypes(types2);
                        } else {
                          setFilmTypes(types1);
                        }
                        // setFileName(e.target.files[0].name);
                        console.log({ list });
                      }}
                    />

                    <div> </div>

                    <Button
                      variant="contained"
                      component="span"
                      size="large"
                      error={Boolean(touched.file && errors.file)}
                      helperText={touched.file && errors.file}
                    >
                      Select file
                    </Button>
                  </label>
                </Grid>

                <Grid item xs="12">
                  <Box component="section">
                    <Grid container xs="12">
                      {values?.reportfile?.length > 0
                        ? Array.from(values.reportfile)
                            .slice(0, 5)
                            .map((file, index) => {
                              return (
                                <Grid
                                  key={`reportFile${index}`}
                                  xs={2}
                                  style={{
                                    border: "1px solid #eee",
                                    borderRadius: "10px",
                                    marginRight: "5px",
                                  }}
                                >
                                  <Grid
                                    container
                                    xs={12}
                                    style={{ padding: 10 }}
                                  >
                                    <Grid
                                      item
                                      xs={1}
                                      md={2}
                                      style={{ paddingTop: 2 }}
                                    >
                                      <Icon color="inherit">description</Icon>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={10}
                                      md={8}
                                      style={{ paddingLeft: 2 }}
                                    >
                                      <Typography
                                        component="div"
                                        variant="body"
                                      >
                                        {file.name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={1} style={{ paddingTop: 2 }}>
                                      <Icon
                                        onClick={(e) => {
                                          let list = Array.from(
                                            values.reportfile
                                          );
                                          list.splice(index, 1);
                                          setFieldValue("reportfile", list);
                                          if (list.length > 2) {
                                            setFilmTypes(types2);
                                          } else {
                                            setFilmTypes(types1);
                                          }
                                          //
                                        }}
                                      >
                                        delete
                                      </Icon>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            })
                        : null}
                      {values?.reportfile?.length > 5 ? (
                        <Grid item xs={12}>
                          <Typography component="div" variant="body">
                            ... and {values.reportfile.length - 5} more
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    label="Modality"
                    size="small"
                    disablePortal
                    id="doctor"
                    name={values?.filmType}
                    options={filmTypes}
                    key="filmType"
                    getOptionLabel={(option) => option}
                    disabled={values?.reportfile?.length > 0 ? false : true}
                    // sx={{ width: 300 }}
                    onChange={(e, value) => {
                      setFieldValue("filmType", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched?.filmType && errors?.filmType)}
                        helperText={touched?.filmType && errors?.filmType}
                        style={{ padding: "2px" }}
                        label="Modality"
                        variant="outlined"
                        placeholder="Select"
                        value={values?.filmType}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="doctor"
                    name={values?.priority}
                    options={["General", "Emergency"]}
                    key="priority"
                    getOptionLabel={(option) => option}
                    // sx={{ width: 300 }}
                    onChange={(e, value) => {
                      setFieldValue("priority", value);
                      console.log({ values });
                      console.log({ wallet });
                      if (values?.filmType === "Xray") {
                        if (value === "General") {
                          let charge = wallet?.xray_rate?.general;
                          setFieldValue("charge", charge);
                        }
                        if (value === "Emergency") {
                          let charge = wallet?.xray_rate?.emergency;
                          setFieldValue("charge", charge);
                        }
                      }
                      if (values?.filmType === "MRI") {
                        if (value === "General") {
                          let charge = wallet?.mri_rate?.general;
                          setFieldValue("charge", charge);
                        }
                        if (value === "Emergency") {
                          let charge = wallet?.mri_rate?.emergency;
                          setFieldValue("charge", charge);
                        }
                      }
                      if (values?.filmType === "CT-Scan") {
                        if (value === "General") {
                          let charge = wallet?.ct_rate?.general;
                          setFieldValue("charge", charge);
                        }
                        if (value === "Emergency") {
                          let charge = wallet?.ct_rate?.emergency;
                          setFieldValue("charge", charge);
                        }
                      }
                      if (values?.filmType === "Hematology") {
                        if (value === "General") {
                          let charge = wallet?.hematology_rate?.general;
                          setFieldValue("charge", charge);
                        }
                        if (value === "Emergency") {
                          let charge = wallet?.hematology_rate?.emergency;
                          setFieldValue("charge", charge);
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched?.priority && errors?.priority)}
                        helperText={touched?.priority && errors?.priority}
                        style={{ padding: "2px" }}
                        label="Select Priority"
                        variant="outlined"
                        placeholder="Select"
                        value={values?.priority}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <br></br>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="doctor"
                    name="clinicInfo"
                    options={data}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    // sx={{ width: 300 }}
                    onChange={(e, value) => {
                      setFieldValue(
                        "report.reportStudy.id",
                        value !== null
                          ? value.id
                          : initialValues.report.reportStudy.id
                      );
                      setFieldValue(
                        "report.reportStudy.value",
                        value !== null
                          ? value.label
                          : initialValues.report.reportStudy.value
                      );
                      setFieldValue(
                        "report.reportTemplate",
                        value !== null
                          ? value.value
                          : initialValues.report.reportTemplate
                      );
                      console.log("name", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Study Name"
                        variant="outlined"
                        placeholder="Search Report"
                        value={values?.report.reportStudy.value}
                        onChange={handleChange}
                        error={Boolean(
                          touched.reportStudy && errors.reportStudy
                        )}
                        helperText={touched.reportStudy && errors.reportStudy}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.remarks && errors.remarks)}
                    helperText={touched.remarks && errors.remarks}
                    fullWidth
                    autoFocus
                    label="Clinical History"
                    margin="normal"
                    name="remarks"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="number"
                    value={values.remarks}
                    variant="outlined"
                    multiline
                  />
                </Grid>
              </Grid>
            </Box>

            <Box xs={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.refDoctor?.name && errors.refDoctor?.name
                    )}
                    helperText={
                      touched.refDoctor?.name && errors.refDoctor?.name
                    }
                    fullWidth
                    autoFocus
                    label="Referal Doctor"
                    margin="normal"
                    name="refDoctor.name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="number"
                    value={values.refDoctor.name}
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.refDoctor?.designation &&
                        errors.refDoctor?.designation
                    )}
                    helperText={
                      touched.refDoctor?.designation &&
                      errors.refDoctor?.designation
                    }
                    fullWidth
                    autoFocus
                    label="Ref Doctor Designation"
                    margin="normal"
                    name="refDoctor.designation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="number"
                    value={values.refDoctor.designation}
                    variant="outlined"
                    multiline
                  />
                </Grid>
              </Grid>
            </Box>

            <Box flex={1} justifyContent="center" alignItems="center">
              <Grid xs={5}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Upload Xray
                </Button>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default XrayData;

// error={Boolean( getIn (touched," repDoctor.name") && getIn (errors, "repDoctor.name"))}
// helperText={ getIn (touched," repDoctor.name") && getIn (errors, "repDoctor.name")}
// onChange={(event, values) => {
//   setFieldValue("file", event.currentTarget.files[0]);
// }}

// <Input
// id="contained-button-file"
// multiple
// name="reportfile"
// type="file"
// accept="*/dicom,.dcm, image/dcm, */dcm, .dicom, .png, */png, .jpg, */jpg"
// error={Boolean(touched.file && errors.file)}
// helpertext={touched.file && errors.file}
// color="success"
// onChange={(e) => {
//   let list = values.reportfile || [];
//   for (let file of e.target.files) {
//     list.push(file);
//   }
//   setFieldValue("reportfile", list);
//   console.log(e.target.files);
//   if (list.length > 2) {
//     setFilmTypes(types2);
//   } else {
//     setFilmTypes(types1);
//   }
//   // setFileName(e.target.files[0].name);
//   console.log({ list });
// }}
// // onChange={(event, values) => {
// //   setFieldValue("file", event.currentTarget.files[0]);
// // }}
// />
