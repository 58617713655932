import React, { useEffect, useState, useCallback } from "react";
import { forwardRef } from "react";
// import Avatar from 'react-avatar';
import Grid from "@material-ui/core/Grid";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import useIsMountedRef from 'hooks/useIsMountedRef';
import { useSnackbar } from "notistack";
import Card from "@mui/material/Card";
import callApi from "Utils/callApi";
import console from "react-console"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { authUser } from "Utils/authUser";
import { Typography } from "@mui/material";
import { getFormattedDate } from "Utils/common-computes";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};



function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}
const UserRates = () => {
  const isMountedRef = useIsMountedRef();
  // const [loggedUser , setLoggedUser] = useState({})
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

  const { enqueueSnackbar } = useSnackbar();
  var columns = [
    { title: "id", field: "_id", hidden: true },
    { title: "Clinic", field: "name" },
    {
      title: "Main Balance", field: "main_balance",

    },
    {
      title: "Xray Rate", field: "xray_rate",
      render: (rowData) => {
        return (
          <Typography component="h4" variant="div">
            <span style={{ fontSize: 15, color: "green" }}> {rowData?.xray_rate.general}</span>
            <sup style={{ fontSize: 10 }}> (General)</sup> <br></br>
            <span style={{ fontSize: 15, color: "skyblue" }}> {rowData?.xray_rate.emergency}</span>
            <sup style={{ fontSize: 10 }}> (Emergency)</sup>
          </Typography>
        )
      }
    },
    {
      title: "MRI Rate", field: "mri_rate",
      render: (rowData) => {
        return (
          <Typography component="h4" variant="div">
            <span style={{ fontSize: 15, color: "green" }}> {rowData?.mri_rate.general}</span>
            <sup style={{ fontSize: 10 }}> (General)</sup> <br></br>
            <span style={{ fontSize: 15, color: "skyblue" }}> {rowData?.mri_rate.emergency}</span>
            <sup style={{ fontSize: 10 }}> (Emergency)</sup>
          </Typography>
        )
      }
    },
    {
      title: "CT Scan Rate", field: "ct_rate", size: "200px",
      render: (rowData) => {
        return (
          <Typography component="h4" variant="div">
            <span style={{ fontSize: 15, color: "green" }}> {rowData?.ct_rate.general}</span>
            <sup style={{ fontSize: 10 }}> (General)</sup> <br></br>
            <span style={{ fontSize: 15, color: "skyblue" }}> {rowData?.ct_rate.emergency}</span>
            <sup style={{ fontSize: 10 }}> (Emergency)</sup>
          </Typography>
        )
      }
    },
    {
      title: "Hematology Rate", field: "hematology_rate",
      render: (rowData) => {
        return (
          <Typography component="h4" variant="div">
            <span style={{ fontSize: 15, color: "green" }}> {rowData?.hematology_rate.general}</span>
            <sup style={{ fontSize: 10 }}> (General)</sup> <br></br>
            <span style={{ fontSize: 15, color: "skyblue" }}> {rowData?.hematology_rate.emergency}</span>
            <sup style={{ fontSize: 10 }}> (Emergency)</sup>
          </Typography>
        )
      }
    },
    {
      title: "Date", field: "createdAt", render: (rowData) => {
        return (<MDTypography>
          {getFormattedDate(rowData?.createdAt)}
        </MDTypography>)
      }
    },

  ];
  const [data, setData] = useState([]);
console.log('dara',data)
  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const getWalletRechargeList = useCallback(() => {
    callApi.post("/wallet-created-by-userId/" + loggedUser.userId)
      .then((response) => {
        const rateList = response.data.walletList;
        setData(rateList);
      }
      )
      .catch(error => {
        console.log("Error", error)
      })
  }, [isMountedRef])
  async function fetchData() {
    let loggedUser = await authUser()
    console.log(loggedUser)
    // setLoggedUser(loggedUser)
  }
  useEffect(() => {
    // fetchData()
    getWalletRechargeList()

  }, [getWalletRechargeList]);
  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = [];
    // if (newData.first_name === "") {
    //   errorList.push("Please enter first name");
    // }
    // if (newData.last_name === "") {
    //   errorList.push("Please enter last name");
    // }
    // if (newData.email === "" || validateEmail(newData.email) === false) {
    //   errorList.push("Please enter a valid email");
    // }

    if (errorList.length < 1) {
      callApi
        .put("/wallet-update-from-table/" + newData._id, newData)
        .then((res) => {
          // const dataUpdate = [...data];
          // const index = oldData.tableData.id;
          // dataUpdate[index] = newData;
          // setData([...dataUpdate]);
          resolve()
          getWalletRechargeList()
          enqueueSnackbar(" Updated Successfully", {
            variant: "success",
            // action: <Button>See all</Button>
          });
          setIserror(false);
          setErrorMessages([]);
        })
        .catch((error) => {
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  // const handleRowAdd = (newData, resolve) => {
  //   //validation
  //   let errorList = [];
  //   if (newData.first_name === undefined) {
  //     errorList.push("Please enter first name");
  //   }
  //   if (newData.last_name === undefined) {
  //     errorList.push("Please enter last name");
  //   }
  //   if (newData.email === undefined || validateEmail(newData.email) === false) {
  //     errorList.push("Please enter a valid email");
  //   }

  //   if (errorList.length < 1) {
  //     //no error
  //     callApi
  //       .post("/users", newData)
  //       .then((res) => {
  //         let dataToAdd = [...data];
  //         dataToAdd.push(newData);
  //         setData(dataToAdd);
  //         resolve();
  //         setErrorMessages([]);
  //         setIserror(false);
  //       })
  //       .catch((error) => {
  //         setErrorMessages(["Cannot add data. Server error!"]);
  //         setIserror(true);
  //         resolve();
  //       });
  //   } else {
  //     setErrorMessages(errorList);
  //     setIserror(true);
  //     resolve();
  //   }
  // };

  // const handleRowDelete = (oldData, resolve) => {
  //   api
  //     .delete("/wallet/delete/" + oldData._id)
  //     .then((res) => {
  //       // const dataDelete = [...data];
  //       // const index = oldData.tableData.id;
  //       // dataDelete.splice(index, 1);
  //       // setData([...dataDelete]);
  //       resolve()
  //       getWalletRechargeList()
  //       enqueueSnackbar(" Deleted Successfully", {
  //         variant: "success",
  //         // action: <Button>See all</Button>
  //       });
  //     })
  //     .catch((error) => {
  //       setErrorMessages(["Delete failed! Server error"]);
  //       setIserror(true);
  //       resolve();
  //     });
  // };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="App" style={{ marginTop: "60px", color: 'white' }}>

        <Grid container spacing={1}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  User Rates
                </MDTypography>
              </MDBox>

              <div>
                {iserror && (
                  <Alert severity="error">
                    {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>;
                    })}
                  </Alert>
                )}
              </div>
              <MaterialTable
                mt={90}
                title="List of Users"
                columns={columns}
                data={data}
                icons={tableIcons}
                options={{
                  headerStyle: { size: "80px", backgroundColor: '#1f283e', color: "white" },
                }}
                table={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      handleRowUpdate(newData, oldData, resolve);
                    }),

              /*      onRowAdd: (newData) =>
                    new Promise((resolve) => {
                      handleRowAdd(newData, resolve);
                     }),
                 onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      handleRowDelete(oldData, resolve);
                    }), */
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

export default UserRates;
