/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useHistory } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormHelperText,
  Link,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
// @mui material components
import Card from "@mui/material/Card";
import React, { useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import CreateOrganization from "layouts/authentication/create-organization/CreateOrganization";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// Images
import bgImage from "assets/images/xraybg.jpg";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { Formik } from "formik";
import { useSnackbar } from "notistack";

import PropTypes from "prop-types";
import * as Yup from "yup";
import callApi from "Utils/callApi";
import MDInput from "components/MDInput";
import countryCityList from './countryCityList.json'
import MDButton from "components/MDButton";
import console from 'react-console'
function Cover() {
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [general, setGeneral] = React.useState(true);
  const [parentOrg, setParenOrg] = React.useState(false);
  const [print, setPrint] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data,setData]= React.useState([]);
  
  console.log(countryCityList)
    
  useEffect(() => {

    if (value === 0) {
      setGeneral(true);
      setParenOrg(false);
      setPrint(false);
    }
    if (value === 1) {
      setGeneral(false);
      setPrint(false);

      setParenOrg(true);
    }
    if (value === 2) {
      setPrint(true);
      setGeneral(false);
      setParenOrg(false);
    }
  }, [value]);
  const handleSubmitSuccess = () => {
    history.push("/login");
  };
  return (
    <CoverLayout image={bgImage}>
      <Card style={{ width: "800px", margin: "0 auto", background: "white" }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create Organization
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter Your Organization Details
          </MDTypography>
        </MDBox>
        <Paper square>
          <Tabs
            value={value}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <Tab label="General" />
            <Tab label="Parent Organization" />
            <Tab label="Print Setting" />
          </Tabs>
        </Paper>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            serial:'',
            password: "",
            country_name: "",
            city_name:'',
            organizationType: "",
            nid: "",
            gender: "",
            bmdcType: "",
            bmdcRegNo: "",
            passedIns: "",
            doctorType: "",
            address: "",
            policy: false,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .min(4)
              .max(255)
              .required("Full Name is required"),
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            phone: Yup.number().min(10).required("Number is required"),
            serial: Yup.number().max(4).required("Serial is required"),
            country_name: Yup.string().required("Serial is required"),

            password: Yup.string().min(6).required("Password is required"),
            address: Yup.string().min(4).required("Address is required"),
            dateOfbirth: Yup.string().required("Date Of Birth is required"),
            organizationType: Yup.string().required("Role Type is required"),
            nid: Yup.number().min(10),
            gender: Yup.string().required("Gender is required"),
            bmdcType: Yup.string(),
            bmdcRegNo: Yup.string(),
            passedIns: Yup.string(),
            doctorType: Yup.string(),
            policy: Yup.boolean().oneOf([true], "This field must be checked"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              callApi
                .post("/registration", values)
                .then((res) => {
                  handleSubmitSuccess();
                  history.push("/authentication/sign-in");
                  enqueueSnackbar("Registration Success", {
                    variant: "success",
                  });
                })
                .catch((error) => {
                  console.log(
                    "error 01",
                    error.message,
                    error.name,
                    error.code
                  );
                  enqueueSnackbar("User Credential already exist!", {
                    variant: "error",
                  });
                });
              // await dispatch(register(values));
              //
            } catch (error) {
              console.log("error 02", error.message, error.name, error.stack);
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            initialValues
          }) => (
            <MDBox pt={4} pb={3} px={3}>
              <form style={{ fontColor: "white" }} onSubmit={handleSubmit}>
                {general ? (
                  <>
                    <MDInput
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name of Organization"
                      margin="normal"
                      name="name"
                      style={{ color: "white" }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="name"
                      value={values.name}
                      variant="outlined"
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Select Organization Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="organizationType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organizationType}
                      >
                        <MenuItem value="hospital">Hospital</MenuItem>
                        <MenuItem value="chamber">Chamber</MenuItem>
                        {/*  ['doctor', 'clinic', 'patient', 'doctorAssistant', 'admin', 'superAdmin'] */}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Select Country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="country_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.country_name}
                      >
                        {countryCityList.map((countries) =>{
                        return <MenuItem value={countries.name}>{countries.name}</MenuItem>

                        })}
                        {/* <MenuItem value="hospital">Hospital</MenuItem>
                        <MenuItem value="chamber">Chamber</MenuItem> */}
                        {/*  ['doctor', 'clinic', 'patient', 'doctorAssistant', 'admin', 'superAdmin'] */}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Select City
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="city_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.city_name}
                      >
                        {countryCityList.map((countryData)=> {
                          console.log(initialValues.country_name)

                          if (countryData.name === initialValues.country_name){
                            let citiesData =countryData.cities.map((cities)=>{
                              console.log(cities)
                              return <MenuItem value={cities}>{cities}</MenuItem>
                            })
                            return citiesData;
                          }
                          else return ''
                          
                          })}
                        {/* <MenuItem value="hospital">Hospital</MenuItem>
                        <MenuItem value="chamber">Chamber</MenuItem> */}
                        {/*  ['doctor', 'clinic', 'patient', 'doctorAssistant', 'admin', 'superAdmin'] */}
                      </Select>
                    </FormControl>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label="Serial No"
                      margin="normal"
                      name="serial"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="phone"
                      value={values.serial}
                      variant="outlined"
                    />
                     <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label="Phone No"
                      margin="normal"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="phone"
                      value={values.phone}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      label="Address"
                      margin="normal"
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="address"
                      value={values.address}
                      variant="outlined"
                    />
                    <TextField
                      id="date"
                      label="Date of Birth"
                      type="date"
                      error={Boolean(touched.dateOfbirth && errors.dateOfbirth)}
                      fullWidth
                      helperText={touched.dateOfbirth && errors.dateOfbirth}
                      margin="normal"
                      name="dateOfbirth"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dateOfbirth}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="gender"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.gender}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                      <FormHelperText>
                        Some important helper text
                      </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Select Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="roleType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.roleType}
                      >
                        <MenuItem value="clinic">clinic</MenuItem>
                        <MenuItem value="patient">patient</MenuItem>
                        <MenuItem value="doctorAssistant">
                          doctor Assistant
                        </MenuItem>
                        <MenuItem value="doctor">doctor</MenuItem>
                        {/*  ['doctor', 'clinic', 'patient', 'doctorAssistant', 'admin', 'superAdmin'] */}
                      </Select>
                    </FormControl>

                    <Box alignItems="center" display="flex" mt={2} ml={-1}>
                      <Checkbox
                        checked={values.policy}
                        name="policy"
                        onChange={handleChange}
                      />
                      <Typography variant="body2" color="textSecondary">
                        I have read the{" "}
                        <Link component="a" href="#" color="secondary">
                          Terms and Conditions
                        </Link>
                      </Typography>
                    </Box>
                    {Boolean(touched.policy && errors.policy) && (
                      <FormHelperText error>{errors.policy}</FormHelperText>
                    )}
                    {/* <Box mt={2}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Create account
          </Button>
        </Box> */}
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                      >
                        Create Account
                      </MDButton>
                    </MDBox>
                    <MDBox mt={3} mb={1} textAlign="center">
                      <MDTypography variant="button" color="text">
                        Already have an account?{" "}
                        <MDTypography
                          component={Link}
                          to="/authentication/sign-in"
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                        >
                          Sign In
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                  </>
                ) : (
                  ""
                )}
              </form>
            </MDBox>
          )}
        </Formik>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
