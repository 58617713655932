/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import {useHistory} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import RegisterForm from 'layouts/authentication/sign-up/RegisterForm';
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/xraybg.jpg";

function Cover() {
  const history = useHistory();

  const handleSubmitSuccess = () => {
    history.push('/authentication/sign-in')
  };
  return (
    <CoverLayout image={bgImage}>
      <Card style={{width:'700px' ,margin:'0 auto' ,background:'white'}}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3} >
          
          <RegisterForm onSubmitSuccess={handleSubmitSuccess}></RegisterForm>
        </MDBox>
      </Card>
    </CoverLayout>
         
  );
}

export default Cover;
