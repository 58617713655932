import {
  Box,
  Checkbox,
  FormHelperText,
  makeStyles, TextField,
  Typography
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import { Link , useHistory} from "react-router-dom";
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useHistory  } from "react-router-dom";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import callApi from 'Utils/callApi';
import MDInput from "components/MDInput";

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import console from "react-console"

const useStyles = makeStyles(() => ({
  root: {
    color:"#fff",
    backGroundColor:"black",
    fontColor:'#fff'
  }
}));

function RegisterForm({ className, onSubmitSuccess, ...rest }) {
  const history = useHistory(); 
  const [valid, setValid] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        password: '',
        roleType: '',
        nid: '',
        gender: '',
        bmdcType: '',
        bmdcRegNo: '',
        passedIns: '',
        doctorType: '',
        designation:'',
        address: '',
        policy: false
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().min(4).max(255).required('Full Name is required'),
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        // phone: Yup.number().min(10).required('Number is required'),
        password: Yup.string().min(6).required('Password is required'),
        address: Yup.string().min(4).required('Address is required'),
        dateOfbirth: Yup.string().required('Date Of Birth is required'),
        roleType: Yup.string().required('Role Type is required'),
        nid: Yup.number().min(10),
        gender: Yup.string().required('Gender is required'),
        bmdcType: Yup.string(),
        designation: Yup.string(),
        bmdcRegNo: Yup.string(),
        passedIns: Yup.string(),
        doctorType: Yup.string(),
        policy: Yup.boolean().oneOf([true], 'This field must be checked')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          // return console.log({values})
          callApi.post('/registration', values)
            .then(res => { 
              onSubmitSuccess();
              
              enqueueSnackbar("Regisauthenticationtration Success", {
                variant: 'success',
              });
            })
            .catch(error => {
              console.log("error 01", error.message, error.name, error.code)
              enqueueSnackbar(error.message, {
                variant: 'error',
              });
            })
          // await dispatch(register(values));
          //
        } catch (error) {
          console.log("error 02", error.message, error.name, error.stack)
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          style={{fontColor:"white"}}
          onSubmit={handleSubmit}
          {...rest}
        >
          
          <MDInput
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label="Full Name"
            margin="normal"
            name="name"
            style={{color:'white'}}
            onBlur={handleBlur}
            onChange={handleChange}
            type="name"
            value={values.name}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          {/*//   error={Boolean(touched.phone && errors.phone)}
          //   fullWidth
          //   helperText={touched.phone && errors.phone}
          //   label="Phone No"
          //   margin="normal"
          //   name="phone"
          //   onBlur={handleBlur}
          //   onChange={handleChange}
          //   type="phone"
          //   value={values.phone}
          //   variant="outlined"
      // />*/}
      <MDBox mt={3} mb={1} >
          <label>
          <PhoneInput
            country={"in"}
            // value={phoneNumber}
              onChange={(value) => {
                values.phone = value;
                console.log("phone",values);
            }}
            name="phone"
            value={values.phone}
            fullWidth
                enableSearch={true}
                inputStyle={{ width: '100%' }}
            inputProps={{
              required: true,
            }}
          />
        </label>
            {!valid && <p>Please enter a valid phone number.</p>}
          </MDBox>
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
            <TextField
            error={Boolean(touched.address && errors.address)}
            fullWidth
            helperText={touched.address && errors.address}
            label="Address"
            margin="normal"
            name="address"
            onBlur={handleBlur}
            onChange={handleChange}
            type="address"
            value={values.address}
            variant="outlined"
          />
          <TextField
            id="date"
            label="Date of Birth"
            type="date"
            error={Boolean(touched.dateOfbirth && errors.dateOfbirth)}
            fullWidth
            helperText={touched.dateOfbirth && errors.dateOfbirth}
            margin="normal"
            name="dateOfbirth"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.dateOfbirth}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name="gender"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.gender}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            <FormHelperText>Some important helper text</FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Select Role</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              name="roleType"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.roleType}
            >
              <MenuItem value="clinic">clinic</MenuItem>
              <MenuItem value="patient">patient</MenuItem>
              <MenuItem value="doctorAssistant">doctor Assistant</MenuItem>
              <MenuItem value="doctor">doctor</MenuItem>
              {/*  ['doctor', 'clinic', 'patient', 'doctorAssistant', 'admin', 'superAdmin'] */}
            </Select>
          </FormControl>

          {
            values.roleType === "doctor" ?
              <Box>
                <TextField
                  error={Boolean(touched.bmdcType && errors.bmdcType)}
                  fullWidth
                  helperText={touched.bmdcType && errors.bmdcType}
                  label="BMDC Type"
                  margin="normal"
                  name="bmdcType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.bmdcType}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.bmdcRegNo && errors.bmdcRegNo)}
                  fullWidth
                  helperText={touched.bmdcRegNo && errors.bmdcRegNo}
                  label="BMDC Reg No"
                  margin="normal"
                  name="bmdcRegNo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.bmdcRegNo}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.passedIns && errors.passedIns)}
                  fullWidth
                  helperText={touched.passedIns && errors.passedIns}
                  label="MBBS/BDS Passed Institute Name"
                  margin="normal"
                  name="passedIns"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.passedIns}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.doctorType && errors.doctorType)}
                  fullWidth
                  helperText={touched.doctorType && errors.doctorType}
                  label="Doctor Type"
                  margin="normal"
                  name="doctorType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.doctorType}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.designation && errors.designation)}
                  fullWidth
                  helperText={touched.designation && errors.designation}
                  label="Doctor Designation"
                  margin="normal"
                  name="designation"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.designation}
                  variant="outlined"
                />
              </Box>
              : values.roleType === "doctorAssistant" ?
                <TextField
                  error={Boolean(touched.nid && errors.nid)}
                  fullWidth
                  helperText={touched.nid && errors.nid}
                  label="National ID"
                  margin="normal"
                  name="nid"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.nid}
                  variant="outlined"
                />
                : ''
          }

          <Box
            alignItems="center"
            display="flex"
            mt={2}
            ml={-1}
          >
            <Checkbox
              checked={values.policy}
              name="policy"
              onChange={handleChange}
            />
            <Typography
              variant="body2"
              color="textSecondary"
            >
              I have read the Terms and Conditions
            </Typography>
          </Box>
          {Boolean(touched.policy && errors.policy) && (
            <FormHelperText error>
              {errors.policy}
            </FormHelperText>
          )}
          {/* <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Create account
            </Button>
          </Box> */}
          <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Create Account
              </MDButton>
            </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
            Already have an account?
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
              </MDTypography>
              </MDTypography>
            </MDBox>
        </form>
      )}
    </Formik>
  );
}

RegisterForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

RegisterForm.default = {
  onSubmitSuccess: () => { }
};

export default RegisterForm;
