/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Switch, Route, useHistory , useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { SnackbarProvider } from "notistack";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { CookiesProvider, useCookies } from "react-cookie";
// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import PerfectScrollbar from 'react-perfect-scrollbar'
import Configurator from "examples/Configurator";
import console from "react-console"
// Material Dashboard 2 React themes

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import theme2 from "assets/theme";

// RTL plugins

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator, setDarkMode,setFixedNavbar,setSidenavColor } from "./context";
import { MaterialUIControllerProvider } from "context";
// Images
import brandWhite from "./assets/images/logo-ct.png";
import brandDark from "./assets/images/logo-ct-dark.png";
import { Path } from "three";
import callApi from "Utils/callApi";
import { authUser } from "Utils/authUser";

export default function App() {
  const [cookies] = useCookies(['user']);
  const history = useHistory(); 
  const [givenRole ,setGivenRole]= useState('');
  const [loggedUser, setLoggedUser] = useState('');
  const [user, setUser] = useState('');
  
  
  useEffect(() => {
    let user = authUser();
    setUser(user)
    console.log({user})
    if (!localStorage.getItem('loggedUser')) {
      console.log('testing')
      history.push('/authentication/sign-in')
    } else {
      const { roleType } = JSON.parse(localStorage.getItem('loggedUser'));
      console.log(localStorage.getItem('loggedUser'))
      setGivenRole(roleType)
      setLoggedUser(localStorage.getItem('loggedUser'))
      getWallet(JSON.parse(localStorage.getItem('loggedUser')))
    }
  }, [history,localStorage.getItem('loggedUser')])
  function getWallet(loggedUser) {
    callApi
    .post("/find-wallet-by-id/" + loggedUser.userId, {
      name: loggedUser.name,
    })
    .then((response) => {
      const wallet = response.data.wallet;
    })
    .catch((error) => {
      console.log("Error", error);
    });
  }
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  let filteredRoutes =[]
    for(let j=0;j<routes.length;j++){
      let config = routes[j];
      if(config.roles){

        for(let i=0;i<config.roles.length;i++){
          let roleName = config.roles[i];
          if(roleName.name === givenRole) filteredRoutes.push(config)
        }
      }
    }


  // Cache for the rtl

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  const [showNavbar,setshowNavbar] = useState(true)
  useEffect(()=>{
    if(pathname ==='/dicom-viewer' ||pathname ==='/authentication/sign-in' ||pathname ===  '/authentication/sign-up' ||pathname ===  '/authentication/create-organization' ||pathname === '/authentication/select-organization'){
      setshowNavbar(false)
    }
    else{
      if(pathname === '/'){
        localStorage.removeItem('loggedUser')
        history.push("/authentication/sign-in");
      }
      setshowNavbar(true)
    }
  },[pathname]);

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
    // setDarkMode(dispatch, true);
    setFixedNavbar(dispatch, false);
    // setSidenavColor(dispatch, 'info')
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route exact path={route.route} key={route.key}>
            {route.component}
          </Route>
        );
      }
      // console.log('For git revertion')
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
  return (
    <ThemeProvider theme={theme2}>
      <CookiesProvider>
      <CssBaseline />
     
      {showNavbar ? (
        <>
         <PerfectScrollbar>
          <Sidenav
            style={{width:"100%"}}
            color={sidenavColor}
            brandName={`${user?.name}`}
            routes={filteredRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator></Configurator>
       </PerfectScrollbar>
        </>
      ):''}
      
      <SnackbarProvider>
      <Switch>
        {getRoutes(routes)}
        <Route path="*"  />
      </Switch>
      </SnackbarProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
}
