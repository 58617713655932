
import React from 'react';
import XrayData from 'layouts/UploadXray/xrayData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const UploadReport = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <XrayData/>
        </DashboardLayout>

    );
};

export default UploadReport;