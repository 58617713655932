import React, { useEffect, useState, useCallback } from "react";
import { forwardRef } from "react";
// import Avatar from 'react-avatar';
import Grid from "@material-ui/core/Grid";
import * as XLSX from "xlsx";
import MaterialTable from "material-table";
import Button from "@mui/material/Button";
import ReceiptIcon from "@mui/icons-material/Receipt";
import useIsMountedRef from "hooks/useIsMountedRef";
import { useSnackbar } from "notistack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import callApi from "Utils/callApi";
import console from "react-console";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Modal from "@mui/material/Modal";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { authUser } from "Utils/authUser";
import { Typography, Stack } from "@mui/material";
import { getFormattedDate } from "Utils/common-computes";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}
const _getDate = (createdDate) => {
  const date = new Date(createdDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based values (0-11)
  const day = date.getDate();
  return `${day}/${month}/${year}`;
};
const style = {
  position: "absolute",
  // top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid gray",
  boxShadow: 24,
  // p: 4,
};
const UserRates = () => {
  const isMountedRef = useIsMountedRef();
  // const [loggedUser , setLoggedUser] = useState({})
  let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const { enqueueSnackbar } = useSnackbar();
  const [clinic, setClinic] = useState({});
  const [openAddRollModal, setOpenAddRollModal] = React.useState(false);
  const [data, setData] = useState([]);
  const [clinicReportList, setClinicReportList] = useState([]);
  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  let startOfTheDay = new Date();
  let endOfTheDay = new Date();
  startOfTheDay.setHours(0, 0, 0, 0);
  endOfTheDay.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(startOfTheDay.getTime());
  const [endDate, setEndDate] = useState(endOfTheDay.getTime());

  const handleCloseAddRollModal = () => setOpenAddRollModal(false);
  let searchParameters = {};
  let user = authUser();
  if (user?.roleType == "superAdmin") {
    searchParameters = {
      roleType: "superAdmin",
    };
  }
  const checkBillSummery = (item) => {
    console.log({ item });
    setClinic(item);
    getFinalXrayList("Final", item.userId, "", "", (finalReports) => {
      console.log({ finalReports });
      setClinicReportList(finalReports);
      // setFinalReportCounter(finalReports.length);
    });
    setOpenAddRollModal(true);
  };
  function calculateAge(birthDate) {
    var currentDate = new Date();
    var birthDateObj = new Date(birthDate);
    var ageDiff = currentDate - birthDateObj;
    var ageDate = new Date(ageDiff);
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }

  var columns = [
    {
      title: "Date",
      field: "createdAt",
      render: (rowData) => {
        return (
          <MDTypography>{_getDate(rowData?.accountCreatedAt)}</MDTypography>
        );
      },
    },
    {
      title: "Clinic Serial",
      field: "_id",
      render: (rowData) => {
        return <MDTypography>{(rowData?._id).slice(0, 3)}</MDTypography>;
      },
    },
    { title: "Clinic", field: "name" },

    {
      title: "Total Study",
      field: "totalStudy",
    },
    {
      title: "Total Bill",
      field: "totalBill",
    },
    {
      title: "Service Status",
      field: "name",
      render: (rowData) => {
        return (
          <Typography component="h4" variant="div">
            <span style={{ fontSize: 15, color: "green" }}> Active</span>
          </Typography>
        );
      },
    },
    {
      title: "Bill Summery",
      field: "name",
      size: "200px",
      render: (rowData) => {
        return (
          <ReceiptIcon
            fontSize="large"
            color="action"
            onClick={() => checkBillSummery(rowData)}
          />
        );
      },
    },
  ];
  var columns2 = [
    {
      title: "Date",
      field: "Date",
      // render: (rowData) => {
      //   return (
      //     <MDTypography>
      //       {getFormattedDate(rowData?.createdDatetimeStamp)}
      //     </MDTypography>
      //   );
      // },
    },
    {
      title: "Patient Name",
      field: "patient",
    },
    {
      title: "Patient Phone",
      field: "phone",
    },
    {
      title: "Age/Gender",
      field: "gender",
      render: (rowData) => {
        return (
          <Typography component="h4" variant="div">
            <span style={{ fontSize: 15, color: "green" }}>
              {rowData.age} Years/
              {rowData?.gender}
            </span>
          </Typography>
        );
      },
    },
    {
      title: "Modality",
      field: "filmType",
    },
    {
      title: "Study",
      field: "study",
    },
    {
      title: "Priority",
      field: "priority",
    },
    {
      title: "Charge",
      field: "charge",
      // render: (rowData) => {
      //   if (rowData.charge) {
      //     return rowData.charge;
      //   }
      // },
    },
  ];

  const getClinicListWithReport = useCallback(() => {
    callApi
      .get("/clinic/all")
      .then((response) => {
        const clinicList = response.data.clinic;
        setData(clinicList);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [isMountedRef]);

  const getFinalXrayList = useCallback(
    (status, id, dateFrom = "", dateTo = "", cbfn) => {
      searchParameters.status = status;
      searchParameters.userId = id;
      searchParameters.startDate = dateFrom;
      searchParameters.endDate = dateTo;
      setLoading(true);
      console.log("searchParameters222222222", searchParameters);
      callApi
        .post("/xray/filter-final-report", searchParameters)
        .then((res) => {
          setLoading(false);
          let xrayFiles = res.data.data.report;
          cbfn(xrayFiles);
        })
        .catch((error) => {
          enqueueSnackbar(error?.message);
        });
    },
    [isMountedRef]
  );
  useEffect(() => {
    getClinicListWithReport();
  }, []);

  async function fetchData() {
    let loggedUser = await authUser();
    console.log(loggedUser);
    // setLoggedUser(loggedUser)
  }

  const startingDateToTimeStamp = (queryDate) => {
    const tempDate = queryDate.split("/");
    const convertedDate = new Date(tempDate).getTime();
    console.log("convertedDate", convertedDate);
    setStartDate(convertedDate ? convertedDate : null);
  };
  const endingDateToTimeStamp = (queryDate) => {
    const tempDate = queryDate.split("/");
    const convertedDate = new Date(tempDate).getTime() + 60 * 60 * 24 * 1000;
    console.log("convertedDate", convertedDate);
    setEndDate(convertedDate ? convertedDate : null);
  };

  const getListByDate = () => {
    searchParameters.startDate = startDate;
    searchParameters.endDate = endDate;
    console.log({ searchParameters });
    console.log({ clinic });
    getFinalXrayList(
      "Final",
      clinic.userId,
      startDate,
      endDate,
      (finalReports) => {
        console.log({ finalReports });
        setClinicReportList(finalReports);
        // setFinalReportCounter(finalReports.length);
      }
    );
  };
  const downloadExcel = () => {
    const totalXray = clinicReportList.filter(
      (report) => report.filmType === "Xray"
    );
    const totalMri = clinicReportList.filter(
      (report) => report.filmType === "MRI"
    );
    const totalCityScan = clinicReportList.filter(
      (report) => report.filmType === "CT-Scan"
    );
    const totalHematology = clinicReportList.filter(
      (report) => report.filmType === "Hematology"
    );

    const totalXrayBill = totalXray?.reduce(
      (total, currentItem) =>
        (total = total + parseInt(currentItem?.charge || 0)),
      0
    );
    const totalMriBill = totalMri?.reduce(
      (total, currentItem) =>
        (total = total + parseInt(currentItem?.charge || 0)),
      0
    );
    const totalCityScanBill = totalCityScan?.reduce(
      (total, currentItem) =>
        (total = total + parseInt(currentItem?.charge || 0)),
      0
    );
    const totalHematologyBill = totalHematology?.reduce(
      (total, currentItem) =>
        (total = total + parseInt(currentItem?.charge || 0)),
      0
    );
    const netTotal =
      totalXrayBill + totalHematologyBill + totalCityScanBill + totalMriBill;
    const netTotalStudy =
      totalXray.length +
      totalMri.length +
      totalCityScan.length +
      totalHematology.length;
    const SummeryData = [
      {
        Modal: "Xray",
        "TOTAL STUDY": totalXray.length,
        "TOTAL BILL": totalXrayBill,
      },
      {
        Modal: "MRI",
        "TOTAL STUDY": totalMri.length,
        "TOTAL BILL": totalMriBill,
      },
      {
        Modal: "City Scan",
        "TOTAL STUDY": totalCityScan.length,
        "TOTAL BILL": totalCityScanBill,
      },
      {
        Modal: "Hematology",
        "TOTAL STUDY": totalHematology.length,
        "TOTAL BILL": totalHematologyBill,
      },
      {
        Modal: "NET TOTAL :",
        "TOTAL STUDY": netTotalStudy,
        "TOTAL BILL": `${netTotal} BDT`,
      },
    ];
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert the table data to a worksheet
    const reportData = clinicReportList.map((item) => {
      delete item.tableData;
      return item;
    });

    const ws1 = XLSX.utils.json_to_sheet(reportData);
    const ws2 = XLSX.utils.json_to_sheet(SummeryData);

    const totalCell = "B7";
    ws2[totalCell] = { t: "s", v: "NET TOTAL: " };
    const totalCell2 = "C7";
    ws2[totalCell2] = { t: "s", v: netTotal };
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws1, "Report");
    XLSX.utils.book_append_sheet(wb, ws2, "Bill Summery");

    // Convert the workbook to a binary Excel file
    const excelBuffer = XLSX.write(wb, { type: "array", bookType: "xlsx" });

    // Create a Blob object from the buffer
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and simulate the click event
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Bill-Summery.xlsx";
    a.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="App" style={{ marginTop: "60px", color: "white" }}>
        <Grid container spacing={1}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card>
              <MaterialTable
                mt={10}
                title="Billing"
                columns={columns}
                data={data}
                options={{
                  headerStyle: {
                    size: "80px",
                    backgroundColor: "#1f283e",
                    color: "white",
                  },
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Modal
          open={openAddRollModal}
          onClose={handleCloseAddRollModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{ overflow: "auto" }}
        >
          <Box
            sx={style}
            style={{
              top: "50%",
              left: "50%",
              width: "100%",
              height: "80%",
              padding: 15,
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <Box style={{ color: "#5c4575",padding: "20px" }}>
              <Card variant="outlined">
                <CardContent
                  style={{
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  <Typography variant="h4">Billing Summery</Typography>
                </CardContent>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      sx={6}
                      md={6}
                      lg={6}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {clinic._id && (
                        <Typography sx={{ mb: 1.5 }}>
                          {" "}
                          Sl - {(clinic?._id).slice(0, 3)}
                        </Typography>
                      )}
                      <Typography
                        variant="h5"
                        sx={{ color: "#5c4575"}}
                        color="text.secondary"
                        gutterBottom
                      >
                        {clinic.name}
                      </Typography>
                    </Grid>
                    <Grid item sx={6} md={6} lg={6}  style={{
                      textAlign: "right",
                    }}>
                      <Typography variant="button">
                        Total Study : {clinic.totalStudy}
                      </Typography>
                      <br />
                      <Typography variant="button">
                        Total Bill : {clinic.totalBill}
                      </Typography>
                      <br />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            <Box style={{ color: "#5c4575",padding: "20px" }}>
                <Card>
                <CardContent>
                <Grid container spacing={3} style={{paddingTop: "20px" }} direction="row"
                justifyContent="flex-start"
                alignItems="center">
                  <Grid item md={4} lg={2}>
                    <TextField
                      id="date"
                      margin="normal"
                      label="Start Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                        onChange={(e) => startingDateToTimeStamp(e.target.value)}
                        fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} md={4} lg={2}>
                    <TextField
                      id="date"
                      margin="normal"
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                        onChange={(e) => endingDateToTimeStamp(e.target.value)}
                        fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} md={4} lg={2}>
                    <Button variant="contained" fullWidth  onClick={() => getListByDate()}>
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
                  <CardContent>
                    <MaterialTable
                      mt={10}
                      title=""
                      columns={columns2}
                      data={clinicReportList}
                    options={{
                      search: false,
                        headerStyle: {
                          size: "80px",
                          backgroundColor: "#1f283e",
                          color: "white",
                        },
                      }}
                      actions={[
                        {
                          icon: () => (
                            <Button
                              variant="outlined"
                              style={{
                                color: "#12B176",
                                borderBlockColor: "#12B176",
                              }}
                              onClick={downloadExcel}
                            >
                              Export
                            </Button>
                          ),
                          onClick: () => downloadExcel,
                          isFreeAction: true,
                        },
                      ]}
                    />
                  </CardContent>
                </Card>
            </Box>

            <Box style={{ marginTop: 45 }}>
              <Stack
                spacing={2}
                direction="row"
                style={{ justifyContent: "right" }}
              >
                <Button
                  variant="outlined"
                  style={{
                    color: "#12B176",
                    borderBlockColor: "#12B176",
                  }}
                  onClick={handleCloseAddRollModal}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default UserRates;
