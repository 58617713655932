import { useState, useEffect, useRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import { useCookies } from "react-cookie";
// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/xraybg.jpg";
import { useHistory } from "react-router-dom";
import MDButton from "components/MDButton";

import console from "react-console";
import { Avatar, Box, IconButton } from "@mui/material";
import callApi from "Utils/callApi";
import { useSnackbar } from "notistack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
function Header({ children }) {
  const hiddenFileInput = useRef(null);
  const hiddenSignatureFileInput = useRef(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [cookies, removeCookie] = useCookies({});
  const history = useHistory();
  const [name, setName] = useState("");
  const [loggedUser, setLoggedUser] = useState("");
  const [roleType, setGivenRole] = useState("");
  console.log(localStorage.getItem("loggedUser"));
  const [image, setImage] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [signatureImage, setSignatureImage] = useState("");
  const [signatureImageData, setSignatureImageData] = useState("");
  useEffect(() => {
    if (!localStorage.getItem("loggedUser")) {
      history.push("/authentication/sign-in");
    } else {
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      console.log({ loggedUser });
      setLoggedUser(loggedUser);
      setName(loggedUser.name);
      setGivenRole(loggedUser.roleType);
      setProfileImage(loggedUser.profileImageData);
      setSignatureImageData(loggedUser.signatureImageData);
    }
  }, [history, cookies]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = async (event, newValue) => {
    // await removeCookie("loggedUser", {})
    localStorage.removeItem("loggedUser");
    history.push("/authentication/sign-in");
  };
  const [imgUploading, setImgUploading] = useState(false);
  const [signatureImgUploading, setSignatureImgUploading] = useState(false);
  const [profileImage, setProfileImage] = useState(loggedUser?.profileImage);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleClickFileUpload = (event) => {
    hiddenSignatureFileInput.current.click();
  };
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [showSignatureUploadButton, setShowSignatureUploadButton] =
    useState(false);
  const handleFileChange = (event) => {
    setShowUploadButton(true);
    const file = event.target.files[0];
    setImage(file);
    // props.handleFile(fileUploaded);
    const reader = new FileReader();
    // file = e.target.files[0]

    // if(file.size > 1000 * 1000){
    //   return showWarning('Please provide a file less than 1mb in size')
    // }

    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log("User profile upload", reader.result);
      setProfileImage(reader.result);
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleSignatureFileChange = (event) => {
    setShowSignatureUploadButton(true);
    const file = event.target.files[0];
    setSignatureImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log("User profile upload", reader.result);
      setSignatureImageData(reader.result);
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const _callSetUserProfileImage = async () => {
    const formData = new FormData();
    formData.append("profileImage", image);
    formData.append("userId", loggedUser._id);
    formData.append("imageData", profileImage);

    console.log({ profileImage });
    console.log({ formData });
    // const data =[]
    try {
      callApi
        .put(`/user/upload/${loggedUser._id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("response", res);
          setImgUploading(false);
          enqueueSnackbar("Uploaded Successfully", {
            variant: "success",
            // action: <Button>See all</Button>
          });
          let user = JSON.parse(localStorage.getItem("loggedUser"));
          user.profileImage = profileImage;
          localStorage.setItem("loggedUser", JSON.stringify(user));
        });
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        // action: <Button>See all</Button>
      });
    }
  };
  const _callSetUserSignatureImage = async () => {
    const formData = new FormData();
    formData.append("signatureImage", signatureImage);
    formData.append("userId", loggedUser._id);
    formData.append("signatureImageData", signatureImageData);
    try {
      callApi
        .put(`/user/upload/signature/${loggedUser._id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("response", res);
          setSignatureImgUploading(false);
          enqueueSnackbar("Uploaded Successfully", {
            variant: "success",
            // action: <Button>See all</Button>
          });
          let user = JSON.parse(localStorage.getItem("loggedUser"));
          user.signatureImageData = signatureImageData;
          localStorage.setItem("loggedUser", JSON.stringify(user));
        });
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        // action: <Button>See all</Button>
      });
    }
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative", mb: 2 }}>
                <Avatar
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "0 1rem",
                  }}
                >
                  {profileImage && (
                    <img
                      src={profileImage || loggedUser?.profileImage}
                      alt="User Profile Image"
                      style={{ height: "100px", width: "100px" }}
                    />
                  )}
                </Avatar>
                <IconButton
                  sx={{ position: "absolute", right: "2px", bottom: "2px" }}
                  onClick={handleClick}
                >
                  <AddAPhotoIcon />
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </IconButton>
              </Box>
              {showUploadButton && (
                <MDButton
                  startIcon="upload"
                  onClick={_callSetUserProfileImage}
                  loadingButton={true}
                  loading={imgUploading}
                ></MDButton>
              )}
            </Box>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {name}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {roleType}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <MDButton
                onClick={handleSetTabValue}
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    exit
                  </Icon>
                }
              >
                Log Out
              </MDButton>
            </AppBar>
          </Grid>
        </Grid>
        {loggedUser.roleType === "doctor" && (
          <Grid container spacing={1} sx={{ marginTop: "5px" }}>
            <Grid
              item
              xs={6}
              md={6}
              lg={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              {signatureImageData || loggedUser?.signatureImageData ? (
                <MDTypography variant="h5" color="text" fontWeight="bold">
                  My Signature
                </MDTypography>
              ) : (
                <MDTypography variant="h6" color="text" fontWeight="regular">
                  Please Upload Your Signature
                </MDTypography>
              )}
            </Grid>
            <Grid item xs={6} md={6} lg={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ position: "relative", mb: 2 }}>
                  <Avatar
                    style={{
                      width: "100px",
                      height: "100px",
                      margin: "0 1rem",
                    }}
                  >
                    {signatureImageData && (
                      <img
                        src={
                          signatureImageData || loggedUser?.signatureImageData
                        }
                        alt="User Profile Image"
                        style={{ height: "100px", width: "100px" }}
                      />
                    )}
                  </Avatar>
                  <IconButton
                    sx={{ position: "absolute", right: "2px", bottom: "2px" }}
                    onClick={handleClickFileUpload}
                  >
                    <AddAPhotoIcon />
                    <input
                      type="file"
                      ref={hiddenSignatureFileInput}
                      onChange={handleSignatureFileChange}
                      style={{ display: "none" }}
                    />
                  </IconButton>
                </Box>
                {showSignatureUploadButton && (
                  <MDButton
                    startIcon="upload Signature"
                    onClick={_callSetUserSignatureImage}
                    loadingButton={true}
                    loading={signatureImgUploading}
                  ></MDButton>
                )}
              </Box>
            </Grid>
          </Grid>
        )}

        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
