import { Container } from '@material-ui/core';
import React from 'react';
import WalletTableList from 'layouts/Wallet/AddWallet/WalletTableList';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const ManageWallet = () => {
    return (
        <DashboardLayout>
        <DashboardNavbar />
        <WalletTableList></WalletTableList>

        </DashboardLayout>
    );
};

export default ManageWallet;