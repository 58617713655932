import React, { useState, useEffect, useCallback } from "react";
import { forwardRef } from "react";
// import Avatar from 'react-avatar';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import console from "react-console";
import MaterialTable from "material-table";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import callApi from "Utils/callApi";
import { useSnackbar } from "notistack";
import { useCookies } from "react-cookie";
import useIsMountedRef from "hooks/useIsMountedRef";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, Button, makeStyles } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import docIcon from "../../../assets/images/icons/docx-file.png";
import pdfIcon from "../../../assets/images/icons/pdf.png";
import vision from "../../../assets/images/icons/vision.png";
import { jsPDF } from "jspdf";
// Material Dashboard 2 React components
import Autocomplete from "@mui/material/Autocomplete";
import { Box, LinearProgress } from "@mui/material";
import { authUser } from "Utils/authUser";
import clsx from "clsx";
import Typography from "theme/typography";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { ShoppingCart, Notifications } from "@mui/icons-material";
import MailIcon from "@mui/icons-material/Mail";

const moment = require("moment");
let { AgeFromDateString } = require("age-calculator");

const useStyles = makeStyles(() => ({
  root: {},
}));
const Input = styled("input")({
  display: "none",
});

const formatted_date = (date_obj) => {
  var date = new Date(date_obj);
  var hours = date.getHours();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0) and noon (12)

  var formattedDate =
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    " " +
    hours +
    ":" +
    date.getMinutes() +
    " " +
    ampm;

  return formattedDate;
};

function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}
const ApprovedAndPendingXrayList = ({ className, ...rest }) => {
  const [data, setData] = useState([]); //table data
  const [allReportCounter, setAllReportCounter] = useState(0); //table data
  const [pendingReportCounter, setPendingReportCounter] = useState(0);
  const [draftReportCounter, setDraftReportCounter] = useState(0);
  const [finalReportCounter, setFinalReportCounter] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies({});
  const [loaderActive, setLoaderActive] = useState(false);
  const [xrayView, setXrayView] = useState(false);
  const [imageURL, setImageUrl] = useState("");

  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [xrayData, setXrayData] = useState("");
  let startOfTheDay = new Date();
  let endOfTheDay = new Date();
  startOfTheDay.setHours(0, 0, 0, 0);
  endOfTheDay.setHours(23, 59, 59, 999);
  const [startDate, setStartDate] = useState(startOfTheDay.getTime());
  const [endDate, setEndDate] = useState(endOfTheDay.getTime());

  const history = useHistory();
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState({});

  const [assistantDoctors, setDoctorAssistant] = useState([]);
  const [loggedUser, setLoggedUser] = useState({});
  const [xrayCounter, setXrayCounter] = useState(0);
  const [mriCounter, setMriCounter] = useState(0);
  const [ctCounter, setCtCounter] = useState(0);
  const [hemCounter, setHemCounter] = useState(0);
  const classes = useStyles();
  console.log("selectedDoctorselectedDoctor", selectedDoctor);
  const [cartItemCount, setCartItemCount] = useState(100000);
  useEffect(() => {
    async function fetchData() {
      let user = await authUser();
      setLoggedUser(user);

      callApi.get("/doctor/getData").then((response) => {
        const patientsData = response.data.users;
        const doctorsData = response.data.doctors;
        console.log({ doctorsData });
        const assistantDoctorsData = response.data.doctorAssistant;
        setDoctors(doctorsData);
        setDoctorAssistant(assistantDoctorsData);
        getDraftedXrayList("Final", (finalReports) => {
          console.log({ finalReports });
          setFinalReportCounter(finalReports.length);
        });
        getDraftedXrayList("Pending", (pendingReports) => {
          console.log({ pendingReports });
          setPendingReportCounter(pendingReports.length);
        });
        getDraftedXrayList("Draft", (draftReports) => {
          console.log({ draftReports });
          setDraftReportCounter(draftReports.length);
        });

        // let pendingReports =  getDraftedXrayList("Pending");
        // setPendingReportCounter(pendingReports.length)
        // let draftReports = getDraftedXrayList("Draft");
        // setDraftReportCounter(draftReports.length)
        getApprovedAndPendingXrayList(user, (allReports) => {
          setAllReportCounter(allReports.length);
        });
      });
    }
    fetchData();
  }, []);

  let columns = [
    { title: "id", field: "id", hidden: true },
    { title: "Patient Name", field: "patient" },
    { title: "Phone", field: "phone" },
    { title: "Study/Description", field: "study" },
    {
      title: "Upload Date",
      field: "createdDatetimeStamp",
      render: (rowData) => formatted_date(rowData.createdDatetimeStamp),
    },
    { title: "Modality", field: "remarks" },
    { title: "Download", field: "reportName" },

    {
      title: "View Report",
      field: "report",
      render: (rowData) => {
        if (rowData.status != "Draft" && rowData.status != "Pending") {
          return (
            <div style={{ display: "flex" }}>
              <div style={{ backhgroundColor: "blue" }}>{rowData.status}</div>

              <img
                style={{ width: "30px", height: "30px" }}
                src={docIcon}
                onClick={() => viewDocx(rowData)}
              ></img>
              <img
                style={{ width: "30px", height: "30px" }}
                src={pdfIcon}
                onClick={() => viewPdf(rowData)}
              ></img>
            </div>
          );
        } else if (rowData.status === "Pending") {
          return <div style={{}}> unseen </div>;
        } else {
          return (
            <div style={{ display: "flex" }}>
              <div style={{ backhground: "blue" }}>{rowData.status}</div>
            </div>
          );
        }
      },
    },

    /*  sssssssssssssssssssss */

    {
      title: "Status",
      field: "report",
      render: (rowData) => {
        if (rowData.status === "Pending") {
          return (
            <div style={{ display: "flex" }}>
              <div>
                {(rowData.repDoctor?.name && (
                  <p style={{ backgroundColor: "yellow" }}>Pending</p>
                )) || <p style={{ backgroundColor: "red" }}>unseen</p>}
              </div>
            </div>
          );
        } else if (
          rowData.status === "Final" ||
          rowData.status === "Draft" ||
          rowData.status === "finalwithcritical"
        ) {
          return (
            <div style={{ display: "flex" }}>
              <div style={{ backgroundColor: "green", borderRadius: "10%" }}>
                {" "}
                complete
              </div>
            </div>
          );
        } else if (rowData.repDoctor?.name === 1) {
          return (
            <div style={{ display: "flex" }}>
              <div style={{ backgroundColor: "green", borderRadius: "10%" }}>
                {" "}
                1
              </div>
            </div>
          );
        } else {
          return (
            <div style={{ display: "flex" }}>
              <div style={{ background: "blue", borderRadius: "10%" }}> </div>
            </div>
          );
        }
      },
    },

    {
      title: "Se./ Img",
      field: "viewDicom",
      render: (rowData) => (
        <div>
          <img
            style={{ width: "30px", height: "30px" }}
            src={vision}
            onClick={(e) => {
              if (rowData?.repDoctor?.name !== "") {
                viewDicom(rowData);
              } else {
                enqueueSnackbar("Please Select Doctor First", {
                  variant: "error",
                });
              }
            }}
          ></img>
        </div>
      ),
    },
  ];
  if (
    loggedUser?.roleType == "admin" ||
    loggedUser?.roleType == "superAdmin" ||
    loggedUser?.roleType == "clinic"
  ) {
    columns.push({
      title: "Selected Doctor",
      field: "doctors",
      render: (rowData) => {
        return (
          <>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Autocomplete
                  size="small"
                  disablePortal
                  id="doctor"
                  name={selectedDoctor?.name}
                  options={doctors}
                  key={selectedDoctor.userId}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  // sx={{ width: 300 }}
                  onChange={(e, value) => {
                    console.log("selected doctor", value);
                    setSelectedDoctor(value);

                    if (value) {
                      var updateData = {
                        repDoctor: value,
                        updatedBy: loggedUser?.roleType,
                      };
                      console.log({ updateData });
                      callApi
                        .put(`/xray/update/${rowData.id}`, { updateData })
                        .then((response) => {
                          enqueueSnackbar("Doctor Updated!", {
                            variant: "success",
                          });
                          getApprovedAndPendingXrayList(loggedUser);
                        })
                        .catch((err) => {
                          enqueueSnackbar("Some thing is wrong!", {
                            variant: "error",
                          });
                        });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ padding: "2px" }}
                      label="Select Doctor"
                      variant="outlined"
                      placeholder="Search Doctor"
                      value={selectedDoctor?.name}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <p style={{ fontSize: "13px", marginTop: "5px" }}>
                  Name : {rowData.repDoctor?.name}
                </p>
              </Grid>
            </Grid>
          </>
        );
      },
    });
  }
  const viewDicom = (data) => {
    localStorage.setItem("dicomfile", JSON.stringify(data));
    history.push("/dicom-viewer");
  };
  //   function objToString (obj) {
  //     var str = '';
  //     for (var p in obj) {
  //         if (Object.prototype.hasOwnProperty.call(obj, p)) {
  //             str += p + '::' + obj[p] + '\n';
  //         }
  //     }
  //     return str;
  // }
  const viewPdf = (data) => {
    const doc = new jsPDF();
    var patient_DOB = data.age;
    var patient_age = new AgeFromDateString(patient_DOB).age;

    var preHtml =
      "<html xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To PDF</title></head><body>";
    var postHtml = "</body></html>";
    var afterHtml = `<table style="width: 100%;">
    <tbody>
        <tr>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><strong style="font-size: 14px;">Patient Name:</strong></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.patient}</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Gender:</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.gender}</strong></span></td>
        </tr>
        <tr>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Patient Phone:</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.phone}</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Patient Age</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${patient_age}</strong></span></td>
        </tr>
        <tr>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Ref by:</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.refDoctor}</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Modality</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.remarks}</strong></span></td>
        </tr>
        <tr>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Uploaded by:</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.uploadedBy}</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Date&nbsp; &amp; Time</strong></span></td>
            <td style="width: 25%;border: 1px solid black;
            border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.uploadDate}</strong></span></td>
        </tr>
    </tbody>
</table>`;
    var doctorHtml = `<div style="text-align:left;margin-top:50.0pt;">
<div style="text-align:"left,">
<img src="${data?.repDoctor?.signatureData}" width="150" height="30" />
<p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
            <font size="3">${data?.repDoctor?.name}
            </font>
        </span></strong></p>
        <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none""><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
        <font size="3">${data?.repDoctor?.doctorType}
        </font>
    </span></p>
<p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none""><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
            <font size="3">${data?.repDoctor?.designation}
            </font>
        </span></p>
<p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none""><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
            <font size="3">${data?.repDoctor?.passedIns}
            </font>
        </span></p>
</div>
</div>`;
var disclaimer = 
`<div style="text-align:center;margin-top:50.0pt;">
<div style="text-align:"center,">
<p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
            <font size="3">Disclaimer:
            </font>
        </span></strong></p>

</div>
<div style="text-align:"center,">
    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                <font size="3">
                It is an online interpretation of medical imaging based on clinical data. All modern machines/procedures have their own limitation. If there is any clinical discrepancy, this investigation may be repeated or reassessed by other tests. Patients’ identification in online reporting is not established, so in no way can this report be utilized for any medico legal purpose. Any error in typing should be corrected immediately.
                </font>
            </span></strong></p>
    
    </div>
</div>`;
    var finalhHtml =
      preHtml + afterHtml + data.report.reportTemplate + doctorHtml + disclaimer + postHtml;
    // var html = htmlToPdfmake(finalhHtml);

    // const documentDefinition = { content: html };
    // pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // pdfMake.createPdf(documentDefinition).open();
    // doc.setFontSize(12);
    // doc.fromHTML(html, {
    //     callback: function (doc) {
    //         doc.save('sample.pdf');
    //     },

    //     width: 100, // <- here

    // });
    var printWindow = window.open("", "", "height=800,width=1000");

    printWindow.document.write(finalhHtml);
    printWindow.document.close();
    printWindow.print();
  };

  //   const viewDocx = (data) => {
  //     let reportData = data.report;
  //     console.log("xrat aaaaaaaaaaaaaaa", data);
  //     var htmlObject = document.createElement("div");
  //     htmlObject.innerHTML = reportData.reportTemplate;
  //     var patient_DOB = data.age;
  //     var patient_age = new AgeFromDateString(patient_DOB).age;
  //     var preHtml =
  //       "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  //     var postHtml = "</body></html>";
  //     var afterHtml = `<table style="width: 100%;">
  //     <tbody>
  //         <tr>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><strong style="font-size: 14px;">Patient Name:</strong></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.patient}</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Gender:</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.gender}</strong></span></td>
  //         </tr>
  //         <tr>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Patient Phone:</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.phone}</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Patient Age</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${patient_age}</strong></span></td>
  //         </tr>
  //         <tr>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Ref by:</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.refDoctor}</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Modality</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.remarks}</strong></span></td>
  //         </tr>
  //         <tr>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Uploaded by:</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.uploadedBy}</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Date&nbsp; &amp; Time</strong></span></td>
  //             <td style="width: 25%;border: 1px solid black;
  //             border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.uploadDate}</strong></span></td>
  //         </tr>
  //     </tbody>
  // </table>`;

  //     var doctorHtml = `<div style="text-align:right;margin-top:50.0pt;">
  //     <div style="text-align:"center,">
  //     <p><img src="${data?.repDoctor?.signatureData}" width="150" height="30" /></p>
  //     <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
  //                 <font size="3">${data?.repDoctor?.name}
  //                 </font>
  //             </span></strong></p>
  //             <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none""><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
  //             <font size="3">${data?.repDoctor?.doctorType}
  //             </font>
  //         </span></p>
  //     <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none""><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
  //                 <font size="3">${data?.repDoctor?.designation}
  //                 </font>
  //             </span></p>
  //     <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none""><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
  //                 <font size="3">${data?.repDoctor?.passedIns}
  //                 </font>
  //             </span></p>
  //     </div>
  // </div>`;
  //     var html = preHtml + afterHtml + reportData.reportTemplate + doctorHtml + postHtml;

  //     var blob = new Blob(["\ufeff", html], {
  //       type: "application/msword",
  //     });

  //     // Specify link url
  //     var url =
  //       "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  //     // Specify file name
  //     let filename = data.patient;
  //     filename = filename ? filename + ".doc" : "document.doc";

  //     // Create download link element
  //     var downloadLink = document.createElement("a");

  //     document.body.appendChild(downloadLink);

  //     // Create a link to the file
  //     downloadLink.href = url;

  //     // Setting the file name
  //     downloadLink.download = filename;

  //     //triggering the function
  //     downloadLink.click();

  //     document.body.removeChild(downloadLink);
  //   };

  function base64toBlob(base64Data) {
    const parts = base64Data.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const byteCharacters = atob(parts[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  const viewDocx = (data) => {
    let reportData = data.report;
    console.log("xrat aaaaaaaaaaaaaaa", data);
    var htmlObject = document.createElement("div");
    htmlObject.innerHTML = reportData.reportTemplate;
    var patient_DOB = data.age;
    var patient_age = new AgeFromDateString(patient_DOB).age;
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var afterHtml = `<table style="width: 100%;">
        <tbody>
            <tr>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><strong style="font-size: 14px;">Patient Name:</strong></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.patient}</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Gender:</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.gender}</strong></span></td>
            </tr>
            <tr>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Patient Phone:</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.phone}</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Patient Age</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${patient_age}</strong></span></td>
            </tr>
            <tr>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Ref by:</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.refDoctor}</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Modality</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.remarks}</strong></span></td>
            </tr>
            <tr>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Uploaded by:</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.uploadedBy}</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">Date&nbsp; &amp; Time</strong></span></td>
                <td style="width: 25%;border: 1px solid black;
                border-collapse: collapse;"><span style="font-size: 12px;"><strong style="font-size: 14px;">${data.uploadDate}</strong></span></td>
            </tr>
        </tbody>
    </table>`;

    // Convert base64 image data to a Blob
    const base64SignatureData = data?.repDoctor?.signatureData;
    const signatureBlob = base64toBlob(base64SignatureData);
    console.log({signatureBlob})

    // Create a URL for the Blob
    const signatureUrl = URL.createObjectURL(signatureBlob);
    console.log({signatureUrl})

    var doctorHtml = `
      <div style="text-align:left;margin-top:50.0pt;">
          <div style="text-align:"left,">
          <p><img src="${signatureUrl}" width="150" height="30" /></p>
          <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                      <font size="3">${data?.repDoctor?.name}
                      </font>
                  </span></strong></p>
                  <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none""><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                  <font size="3">${data?.repDoctor?.doctorType}
                  </font>
              </span></p>
          <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none""><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                      <font size="3">${data?.repDoctor?.designation}
                      </font>
                  </span></p>
          <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none""><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                      <font size="3">${data?.repDoctor?.passedIns}
                      </font>
                  </span></p>
          </div>
      </div>`;
      var disclaimer = 
      `<div style="text-align:center;margin-top:50.0pt;">
      <div style="text-align:"center,">
      <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                  <font size="3">Disclaimer:
                  </font>
              </span></strong></p>
      
      </div>
      <div style="text-align:"center,">
          <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-bottom:.0001pt;line-height:normal;mso-pagination:none; mso-layout-grid-align:none;text-autospace:none"><strong><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">
                      <font size="3">
                      It is an online interpretation of medical imaging based on clinical data. All modern machines/procedures have their own limitation. If there is any clinical discrepancy, this investigation may be repeated or reassessed by other tests. Patients’ identification in online reporting is not established, so in no way can this report be utilized for any medico legal purpose. Any error in typing should be corrected immediately.
                      </font>
                  </span></strong></p>
          
          </div>
      </div>`;
    var html =
      preHtml + afterHtml + reportData.reportTemplate + doctorHtml + disclaimer + postHtml;

    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    // Specify link url
    var url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

    // Specify file name
    let filename = data.patient;
    filename = filename ? filename + ".doc" : "document.doc";

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    // Create a link to the file
    downloadLink.href = url;

    // Setting the file name
    downloadLink.download = filename;

    //triggering the function
    downloadLink.click();

    // Clean up the created URL
    URL.revokeObjectURL(signatureUrl);

    document.body.removeChild(downloadLink);
  };

  const viewReport = (data) => {
    const config = {
      responseType: "blob",
    };
    setLoaderActive(true);

    callApi
      .post("/report/view", { dicomFilename: data.xray }, config)
      .then((response) => {
        setLoaderActive(false);
        setXrayData(data);
        const url = window.URL.createObjectURL(response.data);
        console.log(url);
        setImageUrl(url);
        var printContent = document.getElementById("printPreview");
        var WinPrint = window.open("", "", "width=900,height=650");
        WinPrint.document.write(printContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
      });
  };
  let searchParameters = {};
  let user = authUser();

  if (
    user?.roleType !== "admin" &&
    user?.roleType !== "superAdmin" &&
    user?.roleType !== "doctor"
  ) {
    searchParameters = {
      roleType: "clinic",
      userId: user?.userId,
    };
  } else if (user?.roleType !== "admin" && user?.roleType !== "superAdmin") {
    searchParameters = {
      roleType: "doctor",
      userId: user?.userId,
    };
  } else {
    searchParameters = {};
  }

  const startingDateToTimeStamp = (queryDate) => {
    const tempDate = queryDate.split("/");
    const convertedDate = new Date(tempDate).getTime();
    console.log("convertedDate", convertedDate);
    setStartDate(convertedDate ? convertedDate : null);
  };
  const endingDateToTimeStamp = (queryDate) => {
    const tempDate = queryDate.split("/");
    const convertedDate = new Date(tempDate).getTime() + 60 * 60 * 24 * 1000;
    console.log("convertedDate", convertedDate);
    setEndDate(convertedDate ? convertedDate : null);
  };

  const getApprovedAndPendingXrayList = (user) => {
    searchParameters.startDate = startDate;
    searchParameters.endDate = endDate;
    searchParameters.filmType = filmType;
    searchParameters.priority = priority;
    searchParameters.userId = user?.userId;
    setLoading(true);
    callApi
      .post("/xray/approved-and-pending-xray-list", searchParameters)
      .then((res) => {
        setLoading(false);
        let xrayFiles = res.data.data.report;
        let xrayCounter = res.data.data.xrayCounter;
        let mriCounter = res.data.data.mriCounter;
        let ctCounter = res.data.data.ctCounter;
        let hemCounter = res.data.data.hemCounter;
        setXrayCounter(xrayCounter);
        setMriCounter(mriCounter);
        setCtCounter(ctCounter);
        setHemCounter(hemCounter);
        setData(xrayFiles);
        setAllReportCounter(xrayFiles.length);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
      });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = [];
    if (newData.patient === "") {
      errorList.push("Please enter patient name");
    }
    if (newData.phone === "") {
      errorList.push("Please enter Phone");
    }
    editUser(errorList, newData, oldData, resolve);

    if (errorList.length < 1) {
      callApi
        .put("/xray/update-info/" + newData.id, newData)
        .then((res) => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          setData([...dataUpdate]);
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch((error) => {
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };
  const editUser = (errorList, newData, oldData, resolve) => {
    if (errorList.length < 1) {
      callApi
        .put("/user/edit/" + newData.patientId, newData)
        .then((res) => {
          enqueueSnackbar("User Upadeted Successfully", {
            variant: "success",
          });
        })
        .catch((error) => {
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowAdd = (newData, resolve) => {
    let errorList = [];
    if (newData.first_name === undefined) {
      errorList.push("Please enter first name");
    }
    if (newData.last_name === undefined) {
      errorList.push("Please enter last name");
    }
    if (newData.email === undefined || validateEmail(newData.email) === false) {
      errorList.push("Please enter a valid email");
    }

    if (errorList.length < 1) {
      //no error
      callApi
        .post("/xray/new", newData)
        .then((res) => {
          let dataToAdd = [...data];
          dataToAdd.push(newData);
          setData(dataToAdd);
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch((error) => {
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    callApi
      .delete("/xray/delete/" + oldData.id)
      .then((res) => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve();
      })
      .catch((error) => {
        setErrorMessages(["Delete failed! Server error"]);
        setIserror(true);
        resolve();
      });
  };

  const [open, setOpen] = useState(false);
  const [row, setRows] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const updateStatus = (event) => {
    setAnchorEl(null);
    setOpen(false);
    const selectedXrayRow = row;
    const { myValue } = event.currentTarget.dataset;

    if (selectedXrayRow.status === "Final" && myValue === "Final") {
      enqueueSnackbar("Report is already  Final!  ", {
        variant: "Error",
      });
      return;
    }
    /*     if (selectedXrayRow.status === "Pending" && myValue === "Pending") {
      enqueueSnackbar("Report is already  Pending!   ", {
        variant: "Error",
      });
      return;
    } */

    if (selectedXrayRow.status === "Pending" && myValue === "Pending") {
      enqueueSnackbar("Report is already  Pending!   ", {
        variant: "Error",
      });
      return;
    }
    if (selectedXrayRow.status === "Draft" && myValue === "Draft") {
      enqueueSnackbar("Report is already  Draft! ", {
        variant: "Error",
      });
      return;
    }
    let updateData;
    if (myValue === "Final") {
      updateData = {
        approvedBy: {
          adminName: loggedUser?.name,
          adminEmail: loggedUser?.email,
        },
        status: "Final",
        approvedDate: Date.now(),
        lastModifiedDate: new Date().getTime(),
      };
    }
    if (myValue === "Draft") {
      updateData = {
        status: "Draft",
        lastModifiedDate: new Date().getTime(),
      };
    }
    if (myValue === "Pending") {
      updateData = {
        status: "Pending",
        lastModifiedDate: new Date().getTime(),
      };
    }

    callApi
      .put(`/xray/update/${row.id}`, { updateData })
      .then((response) => {
        enqueueSnackbar("Xray Approved! Reload Please!", {
          variant: "success",
        });
        getApprovedAndPendingXrayList(loggedUser);
      })
      .catch((err) => {
        enqueueSnackbar("Some thing is wrong!", {
          variant: "error",
        });
      });
  };

  const [filmType, setFilmType] = useState("");
  const [priority, setPriority] = useState("");
  const filmTypes = ["Xray", "MRI", "CT-Scan", "Hematology"];

  const getApprovedAndPendingXrayListByDate = () => {
    searchParameters.startDate = startDate;
    searchParameters.endDate = endDate;
    searchParameters.filmType = filmType;
    searchParameters.priority = priority;

    if (searchParameters.startDate > searchParameters.endDate) {
      enqueueSnackbar("Please Select Valid Date!", {
        variant: "error",
      });
      return;
    }
    callApi
      .post("/xray-list/date", searchParameters)
      .then((res) => {
        let xrayFiles = res.data.report;

        let filterXrayList = [];
        for (let i = 0; i < xrayFiles.length; i++) {
          let filteredPendingAndApprovedData = {
            id: xrayFiles[i]._id,
            uploadDate: formatted_date(xrayFiles[i].createdAt),
            uploadedBy: xrayFiles[i].createdBy.name,
            patient: xrayFiles[i].patientInfo.name,
            repDoctor: xrayFiles[i].repDoctor,
            gender: xrayFiles[i].patientInfo.gender,
            phone: xrayFiles[i].patientInfo.phone,
            remarks: xrayFiles[i].remarks,
            study: xrayFiles[i].report.reportStudy.value,
            xray: xrayFiles[i].fileNameOnServer,
            approveDate: xrayFiles[i].approvedDate,
            status: xrayFiles[i].status,
            reportName: xrayFiles[i].report.reportFileNameOnServer,
            report: xrayFiles[i].report,
            patientId: xrayFiles[i].patientInfo._id,
            filmType: xrayFiles[i].filmType,
            priority: xrayFiles[i].priority,
          };
          filterXrayList.push(filteredPendingAndApprovedData);
        }
        setData(filterXrayList ? filterXrayList : "");
      })
      .catch((error) => {
        enqueueSnackbar(error?.message);
      });
  };
  const getDraftedXrayList = useCallback(
    (status, cbfn) => {
      searchParameters.status = status;
      setLoading(true);
      console.log({ searchParameters });
      callApi
        .post("/xray/filter-by-status", searchParameters)
        .then((res) => {
          setLoading(false);

          let xrayFiles = res.data.data.report;
          console.log({ xrayFiles });
          let xrayCounter = res.data.data.xrayCounter;
          let mriCounter = res.data.data.mriCounter;
          let ctCounter = res.data.data.ctCounter;
          let hemCounter = res.data.data.hemCounter;
          setXrayCounter(xrayCounter);
          setMriCounter(mriCounter);
          setCtCounter(ctCounter);
          setHemCounter(hemCounter);
          const updatedList = xrayFiles.map((xray) =>
            formatted_date(xray.createdDatetimeStamp)
          );
          console.log({ updatedList });
          setData(xrayFiles);
          cbfn(xrayFiles);
          // return xrayFiles
        })
        .catch((error) => {
          enqueueSnackbar(error?.message);
        });
    },
    [isMountedRef]
  );
  const styles = {
    badge: {
      position: "absolute",
      top: -10,
      left: -10,
    },
  };

  return (
    <Box
      style={{ marginTop: "60px" }}
      className={clsx(classes.root, className)}
    >
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Box sx={{ background: "white" }}>
            <div>
              {iserror && (
                <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>

            <Box
              sx={{
                p: 2,
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Badge
                  badgeContent={allReportCounter}
                  color="secondary"
                  showZero
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    data-my-value={"all"}
                    onClick={() => {
                      getApprovedAndPendingXrayList(
                        loggedUser,
                        (allReports) => {
                          setAllReportCounter(allReports.length);
                        }
                      );
                    }}
                  >
                    All
                  </Button>
                </Badge>
                <Badge
                  badgeContent={pendingReportCounter}
                  color="secondary"
                  showZero
                >
                  <Button
                    variant="contained"
                    color="error"
                    data-my-value={"Pending"}
                    onClick={(e) => {
                      getDraftedXrayList("Pending", (pendingReports) => {
                        setPendingReportCounter(pendingReports.length);
                      });
                    }}
                  >
                    Pending
                  </Button>
                </Badge>
                <Badge
                  badgeContent={draftReportCounter}
                  color="secondary"
                  showZero
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    data-my-value={"Draft"}
                    onClick={(e) => {
                      getDraftedXrayList("Draft", (draftedReports) => {
                        setDraftReportCounter(draftedReports.length);
                      });
                    }}
                  >
                    Draft
                  </Button>
                </Badge>
                <Badge
                  badgeContent={finalReportCounter}
                  color="secondary"
                  showZero
                >
                  <Button
                    variant="contained"
                    color="primary"
                    data-my-value={"Final"}
                    onClick={(e) => {
                      getDraftedXrayList("Final", (finalReports) => {
                        setFinalReportCounter(finalReports.length);
                      });
                    }}
                  >
                    Final
                  </Button>
                </Badge>
                <Grid item xs={2}>
                  <TextField
                    id="date"
                    margin="normal"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(e) => startingDateToTimeStamp(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="date"
                    margin="normal"
                    label="End Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(e) => endingDateToTimeStamp(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    size="small"
                    id="doctor"
                    name={filmType}
                    options={filmTypes}
                    key="filmType"
                    getOptionLabel={(option) => option}
                    // sx={{ width: 300 }}
                    onChange={(e, value) => {
                      setFilmType(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ padding: "2px" }}
                        label="Select Film Type"
                        variant="outlined"
                        placeholder="Select"
                        value={filmType}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="doctor"
                    name={priority}
                    options={["General", "Emergency"]}
                    key="priority"
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => {
                      setPriority(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ padding: "2px" }}
                        label="Select Priority"
                        variant="outlined"
                        placeholder="Select"
                        value={priority}
                      />
                    )}
                  />
                </Grid>
                <Button
                  variant="contained"
                  onClick={() =>
                    getApprovedAndPendingXrayListByDate(loggedUser)
                  }
                >
                  Search
                </Button>
              </Stack>
            </Box>

            <Grid container xs={12} style={{ padding: 10 }}>
              <Grid item xs={3}>
                Total Xray : {xrayCounter}
              </Grid>
              <Grid item xs={3}>
                Total MRI : {mriCounter}
              </Grid>
              <Grid item xs={3}>
                Total CT Scan : {ctCounter}
              </Grid>
              <Grid item xs={3}>
                Total Hematology : {hemCounter}
              </Grid>
            </Grid>
            {loading && (
              <Stack
                direction="row"
                // spacing={2}
                alignItems="center"
                style={{ overflow: "hidden" }}
              >
                <Box sx={{ width: "100%" }}>
                  <LinearProgress color="inherit" />
                </Box>
              </Stack>
            )}
            <Box>
              <MaterialTable
                mt={90}
                title=""
                columns={columns}
                data={data}
                // icons={tableIcons}
                options={{
                  headerStyle: {
                    size: "80px",
                    background: "black",
                    color: "white",
                  },
                }}
                editable={
                  loggedUser?.roleType == "superAdmin" ||
                  loggedUser?.roleType == "admin"
                    ? {
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve) => {
                            handleRowUpdate(newData, oldData, resolve);
                            // approveXray(newData, oldData,resolve)
                          }),
                      }
                    : ""
                }
                actions={[
                  loggedUser?.roleType !== "patient"
                    ? {
                        icon: MoreVertIcon,
                        tooltip: "More",
                        onClick: (event, rowData) => {
                          handleClick(event, rowData);
                          setRows(rowData);
                        },
                      }
                    : "",
                ]}
              />
            </Box>
            <Menu
              id="more-menu"
              anchorEl={anchorEl}
              keepMounted={true}
              open={open}
              onClose={handleClose}
            >
              <MenuItem data-my-value={"Draft"} onClick={updateStatus}>
                Add to Draft
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApprovedAndPendingXrayList;
