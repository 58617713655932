import React, { useState, useEffect,useCallback } from 'react';
 
import { forwardRef } from 'react';
// import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid'
 
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios'
import Alert from '@material-ui/lab/Alert';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { useSnackbar } from "notistack";
 
import callApi from "Utils/callApi";
 
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
 
 
 
 
function validateEmail(email){
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}
const DoctorListTable = () => {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
    var columns = [
        {title: "id", field: "id", hidden: true},
        {title: "Name", field: "name"},
        {title: "Email", field: "email"},
      { title: "Phone", field: "mobile" },
        {title :"Address", field: "address"}
      ]
  const [data, setData] = useState([]); //table data

     
      //for error handling
      const [iserror, setIserror] = useState(false)
      const [errorMessages, setErrorMessages] = useState([])
     
      // useEffect(() => {
      //   callApi.get("/doctor/all")
      //     .then((response) =>
      //      { const doctorList = response.data.doctor;
      //       console.log(doctorList)
      //       setData(doctorList);
      //     }
      //     )
      //        .catch(error=>{
      //            console.log("Error", error)
      //        })
      // }, [])
      const getDoctorList = useCallback(() => {
        callApi.get("/doctor/all")
        .then((response) =>
         { const doctorList = response.data.doctor;
          console.log(doctorList)
          setData(doctorList);
        }
        )
           .catch(error=>{
               console.log("Error", error)
           })
      }, [isMountedRef])
    
      useEffect(() => {
        getDoctorList();
      }, [getDoctorList]);
      
      const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []
        if(newData.name === ""){
          errorList.push("Please Enter Name")
        }
        if(newData.phone === ""){
          errorList.push("Please Enter Phone")
        }
        if(newData.address === ""){
          errorList.push("Please Enter Adress")
        }
        if(newData.email === "" || validateEmail(newData.email) === false){
          errorList.push("Please enter a valid email")
        }
     
        if(errorList.length < 1){
          callApi.put("/doctor/update/"+newData._id, newData)
          .then(res => {
            // const dataUpdate = [...data];
            // const index = oldData.tableData.id;
            // dataUpdate[index] = newData;
            // setData([...dataUpdate]);
            resolve()
            getDoctorList()
            resolve()
            enqueueSnackbar("Doctor Updated Successfully", {
              variant: "success",
              // action: <Button>See all</Button>
            });
            setIserror(false)
            setErrorMessages([])
          })
          .catch(error => {
            setErrorMessages(["Update failed! Server error"])
            setIserror(true)
            resolve()
             
          })
        }else{
          setErrorMessages(errorList)
          setIserror(true)
          resolve()
     
        }
         
      }
     
      const handleRowAdd = (newData, resolve) => {
        //validation
        let errorList = []
        if(newData.first_name === undefined){
          errorList.push("Please enter first name")
        }
        if(newData.last_name === undefined){
          errorList.push("Please enter last name")
        }
        if(newData.email === undefined || validateEmail(newData.email) === false){
          errorList.push("Please enter a valid email")
        }
     
        if(errorList.length < 1){ //no error
          callApi.post("/doctor/new", newData)
          .then(res => {
            let dataToAdd = [...data];
            dataToAdd.push(newData);
            setData(dataToAdd);
            resolve()
            setErrorMessages([])
            setIserror(false)
          })
          .catch(error => {
            setErrorMessages(["Cannot add data. Server error!"])
            setIserror(true)
            resolve()
          })
        }else{
          setErrorMessages(errorList)
          setIserror(true)
          resolve()
        }
      }
     
      const handleRowDelete = (oldData, resolve) => {
        callApi.delete("/doctor/delete/"+oldData._id)
          .then(res => {
            // const dataDelete = [...data];
            // const index = oldData.tableData.id;
            // dataDelete.splice(index, 1);
            resolve()
            getDoctorList()
            
            enqueueSnackbar("Doctor Deleted Successfully", {
              variant: "success",
              // action: <Button>See all</Button>
            });
          })
          .catch(error => {
            setErrorMessages(["Delete failed! Server error"])
            setIserror(true)
            resolve()
          })
      }
    return (
        <div className="App" style={{marginTop:"60px"}}>
        <h2  style={{ textAlign:"center"}}>
           Manage Doctor
         </h2>
       <Grid container spacing={1}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
          <div>
            {iserror && 
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            }       
          </div>
            <MaterialTable mt={90}
              title="Doctor List"
              columns={columns}
              data={data}
              icons={tableIcons}
              options={{
                headerStyle:{size:'80px'}
                }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                      handleRowUpdate(newData, oldData, resolve);
                       
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve) => {
                    handleRowAdd(newData, resolve)
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    handleRowDelete(oldData, resolve)
                  }),
              }}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
    </div>
    );
};

export default DoctorListTable;
