import { Container } from '@mui/material';
import React from 'react';
import DoctorListTable from "layouts/Doctor/ManageDoctor/DoctorListTable"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
const ManageDoctor = () => {
    return (
        <DashboardLayout>
        <DashboardNavbar />
        <Container maxWidth = {false}>
            <DoctorListTable></DoctorListTable>
        </Container>
        </DashboardLayout>
    );
};

export default ManageDoctor;