import React from 'react';
import AdminListTable from './AdminListTable';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


// const columns = [
//   { field: 'id', headerName: 'ID', width: 130 },
//   {
//     field: 'name',
//     headerName: 'Name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//   },
//     { field: 'phone', headerName: 'Phone number', width: 160 },
//     {
//       field: 'email',
//       headerName: 'Email Address',
//       type: 'text',
//       width: 160,
//     },

//   ];
  
  // const rows = [
  //   { id: 1, name:'Rashed', phone : "01624216000",  email: "rashed@12gmail.com" },
  //   { id: 2, name:'Sawon', phone: "01624216000", email:"sawon@12gmail.com" },
  //   { id: 3, name:'Saif', phone: "01624216000", email:"saif@ymail.com" },
  //   { id: 4, name:'Emran', phone: "01624216000", email: "emran@gmail.com"},
  //   { id: 5, name:'Rafiq', phone: "01624216000", email: null },
  //   { id: 6, name:'Imran', phone: "01624216000",email:  "imran@gmail.com"},
 
  // ];

const ManageAdmin = () => {
    return (
      <DashboardLayout>
      <DashboardNavbar />
      
        <AdminListTable></AdminListTable>
   
      </DashboardLayout>
    );
};

export default ManageAdmin;