/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import Organization from "layouts/authentication/select-organization";

import SignUp from "layouts/authentication/sign-up";
import ApprovedXray from "layouts/Xray/ApprovedXray/ApprovedXray.js";
import DicomV from "dicom";
import AddNewAdmin from "layouts/Admin/AddNewAdmin/AddNewAdmin";
import ManageAdmin from "layouts/Admin/ManageAdmin/ManageAdmin";
import AddNewClinic from "layouts/Clinic/AddNewClinic/AddNewClinic";
import ManageClinic from "layouts/Clinic/ManageClinic/ManageClinic";
import AddNewDoctor from "layouts/Doctor/AddNewDoctor/AddNewDoctor";
import ManageDoctor from "layouts/Doctor/ManageDoctor/ManageDoctor";
// import Login from "layouts/Login/Login";
import ClinicWalletReportFilter from "layouts/Reports/ClinicWalletRechargeHistory/ClinicWalletReportFilter";
import ApprovedXrayReportFilter from "layouts/Reports/XrayReportHistory/ApprovedXrayReportFilter";
import UploadReport from "layouts/UploadReport/UploadReport";
import XrayReport from "layouts/XrayReports/XrayReports";
import ClinicBilling from "layouts/ClinicBilling/Billing";
import UploadXray from "layouts/UploadXray/UploadReport";

// import Profile from "layouts/User/Profile";
import AddWallet from "layouts/Wallet/AddWallet/AddWallet";
import ManageWallet from "layouts/Wallet/AddWallet/ManageWallet";
import UserRates from "layouts/Wallet/AddWallet/UserRates";
import WithdrawRequestDoctor from "layouts/WalletRewardWithdraw/WithdrawRequestDoctor";
import WithdrawRequestDoctorTableList from "layouts/WalletRewardWithdraw/WithdrawRequestDoctorTableList";
import ApprovedWalletWithdrawRequest from "layouts/WalletRewardWithdraw/ApprovedWalletWithdrawRequest";
import Wallet from "layouts/billing";

import PendingXray from "layouts/Xray/PendingXray/PendingXray";

// @mui icons
import Icon from "@mui/material/Icon";
import ClinicWallettRechargeList from "layouts/Reports/ClinicWalletRechargeHistory/ClinicWallettRechargeList";
import CreateOrganization from "layouts/authentication/create-organization";
import ApprovedAndPendingXray from "layouts/Xray/ApprovedAndPendingXray/ApprovedAndPendingXray";
import ApprovedAndPendingReport from "layouts/XrayReports/ApprovedAndPendingReport/ApprovedAndPendingReport";
import WalletManager from "layouts/Wallet/AddWallet/WalletManger";
import Billing from "layouts/Wallet/AddWallet/Billing";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "My Wallet",
    key: "mywallet",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/mywallet",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
      {
        name: "doctor",
      },
      {
        name: "patient",
      },
      {
        name: "clinic",
      },
      {
        name: "doctorAssistant",
      },
    ],
    component: <Wallet />,
  },
  {
    type: "collapse",
    name: "Recharge Wallet",
    key: "addwallet",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/addwallet",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
    component: <AddWallet />,
  },
  {
    type: "collapse",
    name: "Finance",
    key: "billing",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/billing",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "User Rates",
    key: "user-rates",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/user-rates",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
      
    ],
    component: <UserRates />,
  },
  {
    type: "collapse",
    name: "Wallet",
    key: "wallet",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/wallet",
    roles: [
      {
        name: "superAdmin",
      },
    ],
    component: <ManageWallet />,
  },
  {
    type: "collapse",
    name: "Wallet Manager",
    key: "wallet-manager",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/wallet-manager",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name:"admin"
      }
    ],
    component: <WalletManager />,
  },
  {
    type: "collapse",
    name: "Transaction Logs",
    key: "transaction-logs",
    icon: <Icon fontSize="small">summarize</Icon>,
    route: "/transaction-logs",
    roles: [
      {
        name: "superAdmin",
      },
    ],
    component: <ClinicWallettRechargeList />,
  },
  {
    type: "divider",
    name: "dashboardDivider",
    key: "dashboardDivider",
    route: "/wallet",
    roles: [
      {
        name: "superAdmin",
      },
    ],
  },
  {
    type: "title",
    title: "Admin Panel",
    key: "Admin Panel",
    roles: [
      {
        name: "superAdmin",
      },
    ],
  },
  {
    type: "collapse",
    name: "Create Admin",
    key: "create-admin",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/create-admin",
    roles: [
      {
        name: "superAdmin",
      },
    ],
    component: <AddNewAdmin />,
  },
  {
    type: "collapse",
    name: "Manage Admin",
    key: "manage-admin",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/manage-admin",
    roles: [
      {
        name: "superAdmin",
      },
    ],
    component: <ManageAdmin />,
  },
  {
    type: "divider",
    name: "adminDivider",
    key: "adminDivider",
    roles: [
      {
        name: "superAdmin",
      },
    ],
  },
  {
    type: "title",
    title: "Clinic Panel",
    key: "clinicPanel",
    icon: <Icon fontSize="small"></Icon>,
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
  },
  {
    type: "collapse",
    name: "Create Clinic",
    key: "create-clinic",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/create-clinic",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
    component: <AddNewClinic />,
  },
  {
    type: "collapse",
    name: "Manage Clinic",
    key: "manage-clinic",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/manage-clinic",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
    component: <ManageClinic />,
  },

  {
    type: "divider",
    name: "clinicDivider",
    key: "clinicDivider",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
  },
  {
    type: "title",
    title: "Doctor Panel",
    key: "doctorPanel",
    icon: <Icon fontSize="small"></Icon>,
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
  },
  {
    type: "collapse",
    name: "Create Doctor",
    key: "create-doctor",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/create-doctor",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
    component: <AddNewDoctor />,
  },
  {
    type: "collapse",
    name: "Manage Doctor",
    key: "manage-doctor",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/manage-doctor",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
    component: <ManageDoctor />,
  },
  {
    type: "divider",
    name: "doctorDivider",
    key: "doctorDivider",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
  },
  {
    type: "title",
    title: "X-ray Panel",
    key: "xrayPanel",
    icon: <Icon fontSize="small"></Icon>,
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
      {
        name: "patient",
      },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "Approved/Pending Reports",
  //   key: "approved-pending-reports",
  //   icon: <Icon fontSize="small">list</Icon>,
  //   route: "/approved-pending-reports",
  //   roles:[
  //     {
  //       name: 'patient'
  //     },
  //     {
  //       name: 'admin'
  //     },
  //     {
  //       name: 'superAdmin'
  //     },
  //     {
  //       name: 'clinic'
  //     },
  //     {
  //       name: 'doctor'
  //     },

  //   ],
  //   component: <ApprovedAndPendingReport/>,
  // },


  {
    type: "collapse",
    name: "Xray list",
    key: "approved-and-pending-xray",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/approved-and-pending-xray",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
      {
        name: "patient",
      },
      {
        name: "doctor",
      },
      {
        name: "clinic",
      },
    ],
    component: <ApprovedAndPendingXray />,
  },
  {
    type: "collapse",
    name: "Dicom Viewer",
    key: "dicom-viewer",
    icon: <Icon fontSize="small">visibility</Icon>,
    roles: [
      {
        name: "doctor",
      },
    ],
    route: "/dicom-viewer",
    component: <DicomV />,
  },
  {
    type: "divider",
    key: "xrayDivider",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
  },
  {
    type: "title",
    title: "Withdraw Panel",
    key: "withdraw panel",
    icon: <Icon fontSize="small"></Icon>,
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
  },
  {
    type: "collapse",
    name: "Withdraw Request",
    key: "withdraw-request",
    icon: <Icon fontSize="small">euro</Icon>,
    route: "/withdraw-request",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "doctor",
      },
    ],
    component: <WithdrawRequestDoctor />,
  },
  {
    type: "collapse",
    name: "Approved Withdraw",
    key: "approved-withdraw-request",
    icon: <Icon fontSize="small">check</Icon>,
    route: "/approved-withdraw-request",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
    component: <ApprovedWalletWithdrawRequest />,
  },
  {
    type: "collapse",
    name: "Pending Withdraw",
    key: "pending-withdraw-request",
    icon: <Icon fontSize="small">autorenew</Icon>,
    route: "/pending-withdraw-request",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "doctor",
      },
    ],
    component: <WithdrawRequestDoctorTableList />,
  },
  {
    type: "divider",
    key: "dividerupload",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
  },
  {
    type: "title",
    title: "Upload",
    key: "uploadReport",
    icon: <Icon fontSize="small"></Icon>,
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
    ],
  },
  {
    type: "collapse",
    name: "Upload Xray",
    key: "upload-xray",
    icon: <Icon fontSize="small">upload</Icon>,
    route: "/upload-xray",
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "clinic",
      },
    ],
    component: <UploadXray />,
  },
  // {
  //   type: "collapse",
  //   name: "Upload Xray Report",
  //   key: "Upload-Xray-Report",
  //   icon: <Icon fontSize="small">upload</Icon>,
  //   roles: [
  //     {
  //       name: "superAdmin",
  //     },
  //     {
  //       name: "clinic",
  //     },
  //     {
  //       name: "doctor",
  //     },
  //   ],
  //   route: "/Upload-Xray-Report",
  //   component: <UploadReport />,
  // },

    {
    type: "collapse",
    name: "Billing Report",
    key: "Billing-Report",
    icon: <Icon fontSize="small">upload</Icon>,
    roles: [
      {
        name: "clinic",
      },
    ],
    route: "/billing-report",
    component: <ClinicBilling />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    roles: [
      {
        name: "superAdmin",
      },
      {
        name: "admin",
      },
      {
        name: "clinic",
      },
      {
        name: "doctor",
      },
      {
        name: "patient",
      },
      {
        name: "doctorAssistant",
      },
    ],
    route: "/profile",
    component: <Profile />,
  },

  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Select Organization",
    key: "select-organization",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/select-organization",
    component: <Organization />,
  },
  {
    type: "collapse",
    name: "Select Organization",
    key: "create-organization",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/create-organization",
    component: <CreateOrganization />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
